import { Page, View } from '@react-pdf/renderer';
import React from 'react';

import {
  ContentOptions,
  DownloadableProposalInvestmentRecommendation as InvestmentRecommendation,
  DownloadableProposalInvestmentRecommendationContent as InvestmentRecommendationContent,
  DownloadableProposalRiskPlayback as RiskPlayback,
  DownloadableProposalRiskPlaybackContent as RiskPlaybackContent,
  PdfComponentStyle as Style,
  PdfDivider as Divider,
  PdfFooter as Footer,
  PdfHeader as Header,
  RiskBand,
  StagedModelPortfolioData,
} from '@sigfig/digital-wealth-core';

import { FooterGraphics } from '../../components/FooterGraphics';

export interface RiskPlaybackAndInvestmentRecommendationContent {
  footerLogo: string;
  footerText: string;
  investmentRecommendation: InvestmentRecommendationContent;
  logo?: string;
  pageName: string;
  riskPlayback: RiskPlaybackContent;
}

interface Props {
  content: RiskPlaybackAndInvestmentRecommendationContent;
  contentOptions: ContentOptions;
  modelPortfolioData: StagedModelPortfolioData;
  pdfStyles: Style;
  riskBandColors: string[];
  riskBandNew: number;
  riskBands: RiskBand[];
  stockBondLabel: string;
}

export const RiskPlaybackAndInvestmentRecommendation: React.FC<Props> = ({
  content,
  contentOptions,
  riskBandColors,
  riskBandNew,
  riskBands,
  modelPortfolioData,
  stockBondLabel,
  pdfStyles,
}) => {
  const { investmentRecommendation, logo, footerText, riskPlayback } = content;

  return (
    <>
      <Page size="LETTER" wrap={false}>
        <Header logoUrl={logo} pageName={content.pageName} pdfStyles={pdfStyles} />
        <View
          style={{
            width: '100%',
            textAlign: 'left',
            paddingTop: '20px',
            paddingLeft: '80px',
            paddingRight: '80px',
          }}
        >
          <RiskPlayback
            content={riskPlayback}
            pdfStyles={pdfStyles}
            riskBandColors={riskBandColors}
            riskBandNew={riskBandNew}
            riskBands={riskBands}
          />
          <Divider pdfStyles={pdfStyles} />
          <InvestmentRecommendation
            content={investmentRecommendation}
            contentOptions={contentOptions}
            modelPortfolioData={modelPortfolioData}
            pdfStyles={pdfStyles}
            stockBondLabel={stockBondLabel}
          />
        </View>
        <Footer pdfStyles={pdfStyles} text={footerText} />
        <FooterGraphics logo={content.footerLogo} pdfStyles={pdfStyles} />
      </Page>
    </>
  );
};
