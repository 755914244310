import { ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

import {
  AccountAction,
  AccountActions,
  AccountState,
  FinancialAccountType,
  getActiveAccountStates,
  getFundableAccountStates,
  hasBooleanAttribute,
  isSavingsOptimizerAccount,
  ManagedProductType,
} from '@sigfig/digital-wealth-core';

import { AccountActionRoutes } from '../../App';
import { routes } from '../../App/routes';

const TLHIneligibleAccountTypes = [
  FinancialAccountType.SEP_IRA,
  FinancialAccountType.ROTH_IRA,
  FinancialAccountType.ROLLOVER_IRA,
  FinancialAccountType.INHERITED_TRADITIONAL_IRA,
  FinancialAccountType.INHERITED_ROTH_IRA,
  FinancialAccountType.TRADITIONAL_IRA,
  FinancialAccountType._401K,
  FinancialAccountType.TRUST,
];

const isTLHIneligibleAccountType = (accountType: FinancialAccountType): boolean =>
  TLHIneligibleAccountTypes.includes(accountType);

const preSigningAccountStates = [
  AccountState.OnboardingIncomplete,
  AccountState.OnboardingWaitingForDocs,
  AccountState.DocsReady,
  AccountState.DocsAndAccountReady,
];

const postSigningAccountAction = (
  allowForPendingClosureState = true,
  allowForSaveAndGrowAccounts = true,
  allowForDocsSignedState = true,
  allowForDocsErrorState = true,
): AccountAction => {
  const invalidStates = [
    ...preSigningAccountStates,
    ...(allowForPendingClosureState ? [] : [AccountState.PendingClosed]),
    ...(allowForDocsSignedState ? [] : [AccountState.DocsSigned]),
    ...(allowForDocsErrorState ? [] : [AccountState.DocsError]),
  ];
  return {
    type: 'standard',
    valid: ({ state, attributes }) =>
      !!state &&
      !invalidStates.includes(state) &&
      (allowForSaveAndGrowAccounts || isSavingsOptimizerAccount(attributes)),
  };
};

export const useAccountActions = (
  route: AccountActionRoutes,
  accountSetupModal?: { openModal: () => void },
): ComponentProps<typeof AccountActions>['config'] => {
  const navigate = useNavigate();
  const activeAccountStates = getActiveAccountStates();
  // To add support for Pending Model Portfolio Change
  const activeAccountStatesWithPendingPUW = [...activeAccountStates, AccountState.PendingModelPortfolioChange];
  const fundableAccountStates = getFundableAccountStates();
  switch (route) {
    case AccountActionRoutes.ACCOUNT_DETAILS:
      return {
        editAccount: {
          type: 'standard',
          valid: ({ state, attributes }) =>
            (!attributes || hasBooleanAttribute('IS_COPILOT', attributes)) &&
            !!state &&
            activeAccountStatesWithPendingPUW.includes(state),
        },
        raiseCash: {
          type: 'standard',
          valid: ({ state }) => !!state && activeAccountStatesWithPendingPUW.includes(state),
        },
        addRestrictions: {
          type: 'standard',
          valid: ({ state }) => !!state && [...activeAccountStatesWithPendingPUW].includes(state),
        },
        toggleTlh: {
          type: 'standard',
          valid: ({ state, type, managedProductType }) =>
            !!state &&
            !!type &&
            !isTLHIneligibleAccountType(type) &&
            managedProductType === ManagedProductType.DIGITAL_ADVICE_PRO &&
            [...activeAccountStatesWithPendingPUW].includes(state),
        },
        closeAccount: {
          type: 'standard',
          valid: ({ state }) =>
            !!state &&
            [...activeAccountStatesWithPendingPUW, ...fundableAccountStates].includes(state) &&
            state !== AccountState.PendingClosed,
        },
        quarterlyPerformance: {
          type: 'custom',
          callback: args => navigate(`/account-summary/${args?.partyId}/performance/${args?.managedProductId}`),
          valid: ({ state, attributes }) =>
            (!attributes || hasBooleanAttribute('IS_COPILOT', attributes)) &&
            !!state &&
            [...activeAccountStatesWithPendingPUW].includes(state),
        },
        seeDetails: {
          type: 'custom',
          callback: args => navigate(`/account-summary/${args?.partyId}/details/${args?.managedProductId}`),
        },
        viewTransfers: postSigningAccountAction(),
        other: {
          type: 'standard',
          valid: ({ state, attributes }) =>
            (!attributes || hasBooleanAttribute('IS_COPILOT', attributes)) &&
            !!state &&
            ![
              AccountState.PendingClosed,
              AccountState.DocsSigned,
              AccountState.DocsSubmitted,
              AccountState.FundingPending,
              AccountState.OnboardingWaitingForDocs,
            ].includes(state),
        },
        // TODO: This cast shouldn't be necessary, but the editor isn't able to resolve the type of this prop correctly (VSCode)
      } as ComponentProps<typeof AccountActions>['config'];
    case AccountActionRoutes.ACCOUNT_SUMMARY:
      return {
        editAccount: {
          type: 'standard',
          valid: ({ state, attributes }) =>
            (!attributes || hasBooleanAttribute('IS_COPILOT', attributes)) &&
            !!state &&
            activeAccountStatesWithPendingPUW.includes(state),
        },
        raiseCash: {
          type: 'standard',
          valid: ({ state }) => !!state && [...activeAccountStatesWithPendingPUW].includes(state),
        },
        addRestrictions: {
          type: 'standard',
          valid: ({ state }) => !!state && [...activeAccountStatesWithPendingPUW].includes(state),
        },
        closeAccount: {
          type: 'standard',
          valid: ({ state }) =>
            !!state &&
            [...activeAccountStatesWithPendingPUW, ...fundableAccountStates].includes(state) &&
            state !== AccountState.PendingClosed,
        },
        generateDocuments: {
          type: 'custom',
          callback: args => navigate(routes.docusign(args?.partyId ?? '', args?.managedProductId ?? '')),
          valid: ({ state }) => !!state && AccountState.OnboardingWaitingForDocs === state,
        },
        quarterlyPerformance: {
          type: 'custom',
          callback: args => navigate(`/account-summary/${args?.partyId}/performance/${args?.managedProductId}`),
          valid: ({ state, attributes }) =>
            (!attributes || hasBooleanAttribute('IS_COPILOT', attributes)) &&
            !!state &&
            [...activeAccountStatesWithPendingPUW].includes(state),
        },
        seeDetails: {
          type: 'custom',
          callback: args => navigate(`/account-summary/${args?.partyId}/details/${args?.managedProductId}`),
        },
        postOpenNewAccount: {
          type: 'custom',
          callback: () => accountSetupModal?.openModal(),
        },
        continuePendingAccount: {
          type: 'custom',
          callback: args => navigate(`/onboarding/${args?.partyId}`),
          valid: ({ state }) => state === AccountState.OnboardingIncomplete,
        },
        finishPendingAccount: {
          // TODO: Handle Case for FA.
          type: 'custom',
          callback: args => navigate(`/docusign/${args?.partyId}/${args?.managedProductId}`),
          valid: ({ state }) => !!state && [AccountState.DocsWaitingForPrimaryFinancialAdvisor].includes(state),
        },
        resendDocusign: {
          type: 'custom',
          callback: args => navigate(`/docusign/${args?.partyId}/${args?.managedProductId}`),
          valid: ({ state }) =>
            !!state &&
            [
              AccountState.DocsAndAccountReady,
              AccountState.DocsReady,
              AccountState.DocsWaitingForOtherClient,
              AccountState.DocsWaitingForPrimaryClient,
            ].includes(state),
        },
        toggleTlh: {
          type: 'standard',
          valid: ({ state, type, managedProductType }) =>
            !!state &&
            !!type &&
            !isTLHIneligibleAccountType(type) &&
            managedProductType === ManagedProductType.DIGITAL_ADVICE_PRO &&
            [...activeAccountStatesWithPendingPUW].includes(state),
        },
        other: {
          type: 'standard',
          valid: ({ state, attributes }) =>
            (!attributes || hasBooleanAttribute('IS_COPILOT', attributes)) &&
            !!state &&
            ![
              AccountState.PendingClosed,
              AccountState.DocsSigned,
              AccountState.DocsSubmitted,
              AccountState.FundingPending,
              AccountState.OnboardingWaitingForDocs,
            ].includes(state),
        },
        viewTransfers: postSigningAccountAction(),
      } as ComponentProps<typeof AccountActions>['config'];
    default:
      return {};
  }
};
