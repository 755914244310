import { Page, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { PdfComponentStyle as Style, PdfHeader as Header } from '@sigfig/digital-wealth-core';

import { FooterGraphics } from '../../components/FooterGraphics';

export interface AppendixContent {
  footerLogo: string;
  footerText: string;
  logo?: string;
  pageName: string;
  showFooter?: boolean;
  showHeader?: boolean;
  title: string;
}

export interface Props {
  content: AppendixContent;
  pdfStyles: Style;
}

export const Appendix: React.FC<Props> = ({ content, pdfStyles }) => {
  const { pageName, title, logo, showFooter, showHeader } = content;

  return (
    <Page size="LETTER" wrap={false}>
      {/* check needed for pageName otherwise the library internally crashes for empty strings */}
      {showHeader && <Header logoUrl={logo} pageName={pageName.length ? pageName : undefined} pdfStyles={pdfStyles} />}
      <View
        style={{
          width: '100%',
          textAlign: 'center',
          paddingTop: '300px',
          paddingLeft: '80px',
          paddingRight: '80px',
        }}
      >
        <Text
          style={{
            color: pdfStyles.primaryMainColor.color,
            fontSize: 28,
            marginTop: '10px',
          }}
        >
          {title}
        </Text>
      </View>
      {showFooter && (
        <>
          <FooterGraphics logo={content.footerLogo} pdfStyles={pdfStyles} />
        </>
      )}
    </Page>
  );
};
