import { useCallback, useEffect, useState } from 'react';

import {
  ComponentHookResult,
  ContentOptions,
  DropdownItem,
  ManagedProductType,
  PortfolioSelectionData,
  useCoreConfig,
  useGetAllModelPortfoliosContentAcrossPages,
} from '@sigfig/digital-wealth-core';

import { FlowType, SelectedModelPortfolioInput, SleeveType } from '../../../__generated__/symphonyTypes';
import { useGetPortfolioSelectionContent } from '../contentstack';
import { GetPortfolioSelectionContent_all_Portfolio_Selection_items_strategy_mode_options } from '../contentstack/__generated__/GetPortfolioSelectionContent';
import {
  isBooleanAttribute,
  QuestionnaireCollectedDataSingleOption,
  useGetPortfolioSelectionData,
  useGetPortfolioSelectionModelPortfolios,
  useSaveProductSelection,
} from '../symphony';
import { Filter } from '../types';
import { filterAttributeMapping } from '../utils';

export interface GetPortfolioSelectionVariables {
  brokerageAlias: string;
  contentOptions: ContentOptions;
  flowType?: FlowType;
  managedProductId: string;
  managedProductType: ManagedProductType;
  onNext: () => void;
  partyId: string;
}

export interface ModelPortfolioSleeve {
  modelPortfolioInternalId: number;
  modelPortfolioSeriesId: number;
  percentage?: SymphonyDecimal | null;
}

export const useGetPortfolioSelection = ({
  brokerageAlias,
  contentOptions,
  flowType,
  managedProductId,
  managedProductType,
  onNext,
  partyId,
}: GetPortfolioSelectionVariables): ComponentHookResult<PortfolioSelectionData> => {
  const [state, setState] = useState<ComponentHookResult<PortfolioSelectionData>>({
    loading: true,
    error: undefined,
    data: undefined,
  });

  const {
    components: {
      sfPortfolioSelection: { filterPortfoliosByMadlibsTaxStatus },
    },
  } = useCoreConfig();

  const [apolloError, setApolloError] = useState<Error | undefined>();
  const [saveProductSelection] = useSaveProductSelection();

  const {
    data: portfolioSelectionData,
    error: portfolioSelectionDataError,
    loading: portfolioSelectionDataLoading,
  } = useGetPortfolioSelectionData({
    variables: { managedProductId, partyId },
    fetchPolicy: 'no-cache',
  });

  const calculatedRecommendations = portfolioSelectionData?.managedProduct?.calculatedRecommendations;
  const managedProductIsTaxSheltered = portfolioSelectionData?.managedProduct?.isTaxSheltered ?? undefined;
  const accountTypeResponse =
    filterPortfoliosByMadlibsTaxStatus &&
    (portfolioSelectionData?.managedProduct?.questionnaires[0]?.collectedDataGroup.collectedData ?? []).find(
      (data): data is QuestionnaireCollectedDataSingleOption =>
        data.__typename === 'QuestionnaireCollectedSingleOption' &&
        data.dataPointKey === filterPortfoliosByMadlibsTaxStatus.dataPointKey,
    );
  const isMadlibsResponseTaxSheltered =
    filterPortfoliosByMadlibsTaxStatus && accountTypeResponse?.value
      ? filterPortfoliosByMadlibsTaxStatus.taxShelteredOptions.includes(accountTypeResponse.value)
      : undefined;
  const isTaxSheltered = managedProductIsTaxSheltered ?? isMadlibsResponseTaxSheltered;

  const {
    data: portfolioSelectionModelPortfolios,
    error: portfolioSelectionModelPortfoliosError,
    loading: portfolioSelectionModelPortfoliosLoading,
  } = useGetPortfolioSelectionModelPortfolios({
    variables: { managedProductType, brokerageAlias, isTaxSheltered },
    skip: !portfolioSelectionData,
  });

  const {
    data: portfolioSelectionContent,
    error: portfolioSelectionContentError,
    loading: portfolioSelectionContentLoading,
  } = useGetPortfolioSelectionContent({
    variables: contentOptions,
  });

  const {
    data: modelPortfoliosContentData,
    loading: modelPortfoliosContentLoading,
    error: modelPortfoliosContentError,
  } = useGetAllModelPortfoliosContentAcrossPages({
    contentOptions,
  });

  const handleSelect = useCallback(
    async (selectedModelPortfolios: SelectedModelPortfolioInput[], source: FlowType, planUpdateWorkflowId?: string) => {
      try {
        await saveProductSelection({
          variables: {
            managedProductId,
            selectedModelPortfolios,
            source,
            planUpdateWorkflowId,
          },
        });
        onNext();
      } catch (e) {
        if (e instanceof Error) {
          setApolloError(e);
        }
      }
    },
    [managedProductId, onNext, saveProductSelection, setApolloError],
  );

  useEffect(() => {
    if (
      portfolioSelectionDataLoading ||
      portfolioSelectionContentLoading ||
      modelPortfoliosContentLoading ||
      portfolioSelectionModelPortfoliosLoading
    ) {
      return;
    }

    const error =
      apolloError ||
      portfolioSelectionDataError ||
      portfolioSelectionContentError ||
      modelPortfoliosContentError ||
      portfolioSelectionModelPortfoliosError;
    if (error) {
      console.error(`[PortfolioSelection] - An error occurred fetching portfolio selection data, error: ${error}`);
      setState({
        loading: false,
        error,
      });
      return;
    }

    // Temporarily hard code some values until we figure out where we are getting them from
    // and how the onboarding flow will work for DA-PRO (DA2-4701 for reference).
    const portfolios =
      portfolioSelectionModelPortfolios?.modelPortfoliosV2.modelPortfolios?.map(portfolio => {
        const modelPortfolioContent = modelPortfoliosContentData?.all_model_portfolio_data?.items?.find(
          modelPortfolioContentItem =>
            modelPortfolioContentItem?.series_name === portfolio.seriesBaseName &&
            modelPortfolioContentItem.internal_id === portfolio.internalId?.toString(),
        );
        return {
          assetAllocations: portfolio.guidance?.diversification?.assets.allocations ?? [],
          description: modelPortfolioContent?.model_portfolio_description ?? '',
          factSheetLink: portfolio.strategyBrochureUrl ?? '',
          internalId: portfolio.internalId ?? 0,
          isTaxSheltered: portfolio.isTaxSheltered,
          accountMinimumBalanceForAccountOpening: portfolio.accountMinimumBalanceForAccountOpening ?? undefined,
          accountMinimumBalanceForRebalance: portfolio.accountMinimumBalanceForRebalance ?? undefined,
          minimumBalanceForAccountOpening: portfolio.minimumBalanceForAccountOpening ?? undefined,
          minimumBalanceForRebalance: portfolio.minimumBalanceForRebalance ?? undefined,
          productName: modelPortfolioContent?.model_portfolio_name ?? portfolio.name ?? '',
          provider: portfolio.provider ?? undefined,
          riskRange: portfolio.riskRange ?? undefined,
          seriesBaseName: portfolio.seriesBaseName ?? '',
          seriesId: portfolio.seriesId ?? 0,
          stockBondDiversification: {
            bondPercentage: portfolio.guidance?.diversification?.assets.stockBondDiversification?.bondPercentage ?? '',
            stockPercentage:
              portfolio.guidance?.diversification?.assets.stockBondDiversification?.stockPercentage ?? '',
          },
          tags: portfolio.tags?.filter(isBooleanAttribute) ?? [],
        };
      }) ?? [];

    if (!portfolioSelectionContent) {
      return setState({
        loading: false,
        error,
      });
    }

    const content = portfolioSelectionContent.all_Portfolio_Selection?.items?.[0];
    const portfolioFilters: Filter[] =
      content?.portfolio_filters?.map(group => ({
        attribute: filterAttributeMapping[group?.filter_group?.filter_type ?? ''],
        label: group?.filter_group?.label ?? '',
        items:
          group?.filter_group?.items?.map(item => ({
            label: item?.item?.label ?? '',
            value: item?.item?.tag ?? '',
          })) ?? [],
      })) ?? [];
    setState({
      data: {
        calculatedRecommendations: calculatedRecommendations ?? undefined,
        content: {
          back: content?.back_button_text ?? '',
          strategyUnavailableText: content?.strategy_unavailable_text ?? '',
          cardHeader: {
            action: content?.table?.action ?? '',
            assetAllocation: content?.table?.asset_allocation ?? '',
            minimumInvestmentAmount: content?.table?.minimum_investment ?? '',
            factSheet: content?.table?.fact_sheet ?? '',
            productName: content?.table?.product_name ?? '',
          },
          cardLabels: {
            assetAllocationLabel: content?.table?.view_asset_allocations ?? '',
            factSheetLabel: content?.table?.view_fact_sheet ?? '',
            selectLabel: content?.table?.select_button_text ?? '',
          },
          header: {
            headerIcon: content?.imageConnection?.edges?.[0]?.node?.url ?? '',
            title: content?.heading ?? '',
            investmentStrategyLabel: content?.investment_strategy_label ?? '',
            riskToleranceEditLinkText: content?.risk_tolerance_edit_link_text ?? '',
            riskToleranceLabel: content?.risk_tolerance_label ?? '',
          },
          higherMinimumWarningMessage:
            content?.labels?.find(label => label?.key === 'higher_minimum_warning_msg')?.value ??
            'higher_minimum_warning_msg',
          investmentAmount: {
            disabledText: content?.investment_amount?.disabled_text ?? '',
            editButtonText: content?.investment_amount?.edit_button_text ?? '',
            noAmountText: content?.investment_amount?.empty_amount_text ?? '',
            label: content?.investment_amount?.label ?? '',
            tooltipContent: content?.investment_amount?.tooltip_content ?? '',
          },
          investmentAmountModal: {
            accountBalanceLabel:
              content?.labels?.find(label => label?.key === 'account_balance_label')?.value ?? 'account_balance_label',
            cancelButtonText: content?.investment_amount_modal?.cancel_button_text ?? '',
            existingCustomerInfoText: content?.investment_amount_modal?.existing_customer_info_text ?? '',
            infoText: content?.investment_amount_modal?.info_text ?? '',
            inputCurrency: content?.investment_amount_modal?.input_currency ?? '',
            inputLabel: content?.investment_amount_modal?.input_label ?? '',
            inputPlaceholder: content?.investment_amount_modal?.input_placeholder ?? '',
            resetValueButtonText: content?.investment_amount_modal?.reset_value_button_text ?? '',
            saveButtonText: content?.investment_amount_modal?.save_button_text ?? '',
            title: content?.investment_amount_modal?.title ?? '',
          },
          investmentAmountWarningModal: {
            cancelButtonText: content?.investment_amount_warning_modal?.cancel_button_text ?? '',
            infoText: content?.investment_amount_warning_modal?.info_text ?? '',
            submitButtonText: content?.investment_amount_warning_modal?.submit_button_text ?? '',
            title: content?.investment_amount_warning_modal?.title ?? '',
          },
          keepCurrentPortfolio: {
            assetAllocationLabel:
              content?.keep_current_portfolio?.find(el => el?.key === 'assetAllocationLabel')?.value ?? '',
            viewAssetAllocation:
              content?.keep_current_portfolio?.find(el => el?.key === 'viewAssetAllocation')?.value ?? '',
            keepCurrentPortfolioCta:
              content?.keep_current_portfolio?.find(el => el?.key === 'keepCurrentPortfolioCta')?.value ?? '',
            currentPortfolioLabel:
              content?.keep_current_portfolio?.find(el => el?.key === 'currentPortfolioLabel')?.value ?? '',
            message: content?.keep_current_portfolio?.find(el => el?.key === 'message')?.value ?? '',
          },
          minimumInvestmentAmountInfoText: content?.minimum_investment_info_text ?? '',
          nullStateMessage: content?.null_state_text ?? '',
          strategyModeOptions: getStrategyModeOptions(content?.strategy_mode_options ?? []),
        },
        modelPortfolios: portfolios,
        portfolioFiltersProps: {
          portfolioFilters,
          portfolioFiltersHeader: 'Filters',
        },
        riskScoreUser: portfolioSelectionData?.managedProduct?.planUpdateWorkflows[0]?.riskPreference,
        selectModelPortfolioSleeves: (modelPortfolioSleeves: ModelPortfolioSleeve[]) => {
          handleSelect(
            modelPortfolioSleeves.map(sleeve => {
              return {
                modelPortfolioInternalId: sleeve.modelPortfolioInternalId,
                modelPortfolioSeriesId: sleeve.modelPortfolioSeriesId,
                percentage: sleeve.percentage,
                sleeveType: SleeveType.INVESTMENT,
              };
            }),
            flowType ?? FlowType.ONBOARDING,
          );
        },
      },
      loading: false,
    });
  }, [
    apolloError,
    calculatedRecommendations,
    flowType,
    handleSelect,
    portfolioSelectionContent,
    modelPortfoliosContentData,
    portfolioSelectionData?.managedProduct,
    portfolioSelectionDataError,
    portfolioSelectionDataLoading,
    portfolioSelectionContentError,
    portfolioSelectionContentLoading,
    portfolioSelectionModelPortfolios,
    portfolioSelectionModelPortfoliosError,
    portfolioSelectionModelPortfoliosLoading,
    modelPortfoliosContentError,
    modelPortfoliosContentLoading,
  ]);

  return state;
};

export const getStrategyModeOptions = (
  strategyModeOptions?: (GetPortfolioSelectionContent_all_Portfolio_Selection_items_strategy_mode_options | null)[],
): DropdownItem[] => {
  return (strategyModeOptions ?? []).map(strategyMode => ({
    label: strategyMode?.label ?? '',
    value: strategyMode?.value ?? '',
  }));
};
