import queryString from 'query-string';
import React, { FC, lazy, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  AssetAllocationTableColumns,
  AssetClassTier,
  Container,
  FlowType,
  LegalDocumentStatus,
  PortfolioSelectionProps,
  RetakeRTQStates,
} from '@sigfig/digital-wealth-core';

import { Header } from '../../components/Header';
import config from '../../config';
import { PortfolioSelectionWrapper } from '../../containers/PortfolioSelection';
import { useApp } from '../../contexts/App';
import { AccountActionRoutes, getBaseUrl, isInIframe } from '..';
import { questionnaireProps, retakeRtqStatesOrder } from '../utils';

interface RetakeRtqProps extends RouteComponentProps<{ managedProductId: string; partyId: string }> {
  faPartyId: string;
}

const RetakeRTQ = lazy(() => import('../../components/RetakeRTQWrapper'));

export const RetakeRTQWrapper: FC<RetakeRtqProps> = ({
  faPartyId,
  history,
  match: {
    params: { managedProductId, partyId },
  },
}) => {
  const { contentOptions, program } = useApp();

  const PortfolioSelectionComponent = useCallback(
    (flowType: FlowType): React.FC<PortfolioSelectionProps> => props => (
      <PortfolioSelectionWrapper flowType={flowType} isRce {...props} actorPartyId={faPartyId} />
    ),
    [faPartyId],
  );

  const { source } = queryString.parse(location?.search ?? { source: AccountActionRoutes.ACCOUNT_SUMMARY });

  const destinationPath =
    source === AccountActionRoutes.ACCOUNT_DETAILS
      ? `/account-summary/${partyId}/details/${managedProductId}`
      : `/account-summary/${partyId}`;

  const docusignBaseUrl = `${getBaseUrl()}/am/master`;
  const docusignReturnToUrl = `${getBaseUrl()}/docusignComplete/${partyId}/questionnaire/${managedProductId}`;

  if (isInIframe() && window.top) {
    window.top.location.href = window.location.href;
    return null;
  }

  const { status, userType, state } = queryString.parse(location?.search ?? '');

  const inititalState =
    state === RetakeRTQStates.FA_DOCUSIGN
      ? RetakeRTQStates.FA_DOCUSIGN
      : userType
      ? status === LegalDocumentStatus.SUCCEEDED
        ? RetakeRTQStates.PORTFOLIO_UPDATED
        : RetakeRTQStates.FA_DOCUSIGN_DECLINED
      : undefined;

  return (
    <>
      <Header
        contentOptions={contentOptions}
        onBack={() => history.push(destinationPath)}
        showBackToAccountDetails={source === AccountActionRoutes.ACCOUNT_DETAILS}
        showBackToAccountSummary={source === AccountActionRoutes.ACCOUNT_SUMMARY}
      />
      <Container maxWidth="lg">
        <RetakeRTQ
          assetClassTier={AssetClassTier.ENCLOSING}
          contentOptions={contentOptions}
          docusignParams={{
            baseUrl: docusignBaseUrl,
            faPartyId,
            returnToUrl: docusignReturnToUrl,
          }}
          initialState={inititalState}
          isDocusignRequired
          managedProductId={managedProductId}
          onBack={() => history.push(destinationPath)}
          onClientDocusignBackButtonClick={() => history.push(`/account-summary/${partyId}`)}
          onDocusignIframeReady={() => {
            // post a message every minute for 20 mins so that keep alive call is made periodically
            // users usually take longer to read documents, so this is needed when Docusign iFrame is shown
            const iFrameMessageInterval = setInterval(() => {
              window.postMessage(`iFrameMessage`, docusignBaseUrl);
            }, 60000);
            setTimeout(() => clearInterval(iFrameMessageInterval), 1200000);
          }}
          onLastStage={(feedbackMessageType?: string) => {
            if (feedbackMessageType) {
              history.push(`${destinationPath}?feedbackMessageType=${feedbackMessageType}`);
            } else {
              history.push(destinationPath);
            }
          }}
          onRetakeRTQCompletedCTA={() => {
            history.push(`/account-summary/${partyId}`);
          }}
          overrideRetakeRTQComponents={{
            [RetakeRTQStates.PORTFOLIO_SELECTION]: PortfolioSelectionComponent(FlowType.RETAKE),
          }}
          partyId={partyId}
          portfolioComparisonProps={{
            columns: [AssetAllocationTableColumns.AssetClass, AssetAllocationTableColumns.Allocation],
          }}
          questionnaireProps={{ ...questionnaireProps }}
          retakeRtqStatesOrder={retakeRtqStatesOrder}
          viewerPartyId={faPartyId}
        />
      </Container>
    </>
  );
};
