import { format } from 'date-fns';

import { replaceVariable } from '@sigfig/digital-wealth-core';

import { Content } from '.';
import { GetDownloadableProposal_all_downloadableproposal_items } from './contentstack/__generated__/GetDownloadableProposal';

export const getBalanceDisplayValue = (value: number): string => {
  if (value > 0 && value % 1000000 === 0) {
    return `$${value / 1000000}M`;
  } else if (value > 0 && value % 1000 === 0) {
    return `$${value / 1000}K`;
  } else {
    return `${value}`;
  }
};

export const getDownloadableProposalContent = (
  clientName: string,
  financialAdvisor: { advisorName: string; email: string; phoneNumber: string },
  data?: GetDownloadableProposal_all_downloadableproposal_items,
): Content => {
  const { advisorName, phoneNumber, email } = financialAdvisor;
  const commonText = {
    footerText: replaceVariable(data?.footer ?? '', [
      {
        value: clientName,
        variable: 'clientName',
      },
      {
        value: advisorName,
        variable: 'advisorName',
      },
      {
        value: `${format(new Date(), 'MMM dd, yyyy')} at ${format(new Date(), 'HH:mm')}`,
        variable: 'dateTime',
      },
    ]),
  };

  return {
    appendix: {
      footerLogo: data?.alternate_logoConnection?.edges?.[0]?.node?.url ?? '',
      pageName: data?.appendix?.pagename ?? '',
      title: data?.appendix?.title ?? '',
      showFooter: !!data?.appendix?.show_footer,
      showHeader: !!data?.appendix?.show_header,
      ...commonText,
    },
    cover: {
      logo: data?.logoConnection?.edges?.[0]?.node?.url ?? '',
      title: data?.cover?.title ?? '',
      titleSuperscript: data?.cover?.title_superscript ?? '',
      subtitle: data?.cover?.subtitle ?? '',
      email: replaceVariable(data?.cover?.email ?? '', [{ value: email, variable: 'email' }]),
      emailImage: data?.cover?.email_imageConnection?.edges?.[0]?.node?.url ?? '',
      phone: replaceVariable(data?.cover?.phone ?? '', [{ value: phoneNumber, variable: 'phoneNumber' }]),
      phoneImage: data?.cover?.phone_imageConnection?.edges?.[0]?.node?.url ?? '',
      ...commonText,
    },
    detailedInvestmentBreakdown: {
      footerLogo: data?.alternate_logoConnection?.edges?.[0]?.node?.url ?? '',
      allocationDetails: {
        assetAllocationColumns: {
          allocation: data?.detailed_investment_breakdown?.asset_allocation_columns?.allocation ?? '',
          asset: data?.detailed_investment_breakdown?.asset_allocation_columns?.asset ?? '',
          assetClass: data?.detailed_investment_breakdown?.asset_allocation_columns?.asset_class ?? '',
        },
        assetAllocationTitle: data?.detailed_investment_breakdown?.asset_allocation_title ?? '',
        donutTargetAllocationLabel: data?.detailed_investment_breakdown?.donut_label ?? '',
        subtitle: data?.detailed_investment_breakdown?.subtitle?.map(item => item ?? '') ?? [],
        title: data?.detailed_investment_breakdown?.title ?? '',
      },
      compositeModelPortfolioName: data?.detailed_investment_breakdown?.composite_model_portfolio_name ?? '',
      pageName: data?.detailed_investment_breakdown?.pagename ?? '',
      ...commonText,
    },
    feesAndDisclosures: {
      footerLogo: data?.alternate_logoConnection?.edges?.[0]?.node?.url ?? '',
      pageName: data?.fees_and_disclosures?.pagename ?? '',
      feeContent: {
        balanceRangeLabel: data?.fees_and_disclosures?.fee_schedule?.balance_range_label ?? '',
        disclaimer: data?.fees_and_disclosures?.fee_schedule?.disclaimer ?? '',
        feeLabel: data?.fees_and_disclosures?.fee_schedule?.fee_label ?? '',
        sectionTitle: data?.fees_and_disclosures?.fees?.title ?? '',
        title: data?.fees_and_disclosures?.fee_schedule?.title ?? '',
      },
      disclosure: {
        disclosureList: data?.fees_and_disclosures?.disclosure?.disclosure_list?.map(item => item ?? '') ?? [],
        title: data?.fees_and_disclosures?.disclosure?.title ?? '',
        importantLinkText: data?.fees_and_disclosures?.disclosure?.important_link_text ?? '',
        servicesText: data?.fees_and_disclosures?.disclosure?.services_text ?? '',
        importantLinks:
          data?.fees_and_disclosures?.disclosure?.important_links?.map(item => ({
            link: item?.link ?? '',
            text: item?.text ?? '',
          })) ?? [],
        servicesList: data?.fees_and_disclosures?.disclosure?.services_list?.map(item => item ?? '') ?? [],
      },
      ...commonText,
    },
    riskPlaybackAndInvestmentRecommendation: {
      footerLogo: data?.alternate_logoConnection?.edges?.[0]?.node?.url ?? '',
      pageName: data?.riskplayback_and_investmentrecommendation?.pagename ?? '',
      riskPlayback: {
        title: data?.riskplayback_and_investmentrecommendation?.risk_playback?.title ?? '',
        subtitle: data?.riskplayback_and_investmentrecommendation?.risk_playback?.subtitle ?? '',
        descriptionLabel: data?.riskplayback_and_investmentrecommendation?.risk_playback?.description_label ?? '',
        riskToleranceTitle: data?.riskplayback_and_investmentrecommendation?.risk_playback?.risk_tolerance_title ?? '',
      },
      investmentRecommendation: {
        title: data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.title ?? '',
        subtitle: data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.subtitle ?? '',
        descriptionLabel:
          data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.description_label ?? '',
        compositePortfolioName:
          data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.composite_portfolio_name ?? '',
        blendLabel: data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.blend_label ?? '',
        percentageLabel:
          data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.percentage_label ?? '',
        minimumInvestmentLabel:
          data?.riskplayback_and_investmentrecommendation?.investment_recommendation?.minimum_investment_label ?? '',
      },
      ...commonText,
    },
    tableOfContents: {
      footerLogo: data?.alternate_logoConnection?.edges?.[0]?.node?.url ?? '',
      pageName: data?.table_of_contents?.pagename ?? '',
      title: data?.table_of_contents?.title ?? '',
      pages: data?.table_of_contents?.pages?.map(item => ({ page: item?.page ?? '', index: item?.index ?? '' })) ?? [],
      ...commonText,
    },
    whyDigitalAdvicePro: {
      footerLogo: data?.alternate_logoConnection?.edges?.[0]?.node?.url ?? '',
      pageName: data?.why_digital_advice_pro?.pagename ?? '',
      title: data?.why_digital_advice_pro?.title ?? '',
      benefitTitle: data?.why_digital_advice_pro?.benefit_title ?? '',
      benefits:
        data?.why_digital_advice_pro?.benefits?.map(item => ({ title: item?.title ?? '', text: item?.text ?? '' })) ??
        [],
      info: data?.why_digital_advice_pro?.info?.map(item => item ?? '') ?? [],
      ...commonText,
    },
  };
};
