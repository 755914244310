import { OnboardingStates, QuestionnaireProps, QuestionnaireType, RetakeRTQStates } from '@sigfig/digital-wealth-core';

export const questionnaireProps: Partial<QuestionnaireProps> = {
  combineSoftBlocks: true,
  isCopilot: true,
  showPortfolioBands: false,
  questionnaireType: QuestionnaireType.ONBOARDING_V2,
  riskScoreLowerHardLimit: 1,
};

export const onboardingStatesOrder: OnboardingStates[] = [
  OnboardingStates.MADLIBS,
  OnboardingStates.RTQ,
  OnboardingStates.RTQ_RESULT,
  OnboardingStates.PORTFOLIO_SELECTION,
  OnboardingStates.PLAN,
  OnboardingStates.FUNDING,
  OnboardingStates.PAPERWORK,
  OnboardingStates.PLAYBACK,
];

export const retakeRtqStatesOrder: RetakeRTQStates[] = [
  RetakeRTQStates.RTQ,
  RetakeRTQStates.RTQ_RESULT,
  RetakeRTQStates.PORTFOLIO_SELECTION,
  RetakeRTQStates.COMPARISON,
  RetakeRTQStates.CLIENT_DOCUSIGN,
  RetakeRTQStates.FA_DOCUSIGN,
];
