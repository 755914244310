import { History } from 'history';
import React, { FC, lazy } from 'react';

import { useApp } from '../../contexts/App';

import { useFADashboardParams } from './utils';

const FADashboard = lazy(() => import('../../containers/FADashboard'));

interface FaDashboardWrapperProps {
  history: History;
  partyIdFA: string;
  showSearchComponent: boolean;
}

export const FADashboardWrapper: FC<FaDashboardWrapperProps> = ({ history, partyIdFA, showSearchComponent }) => {
  const { contentOptions } = useApp();
  const { onParamsUpdate, params } = useFADashboardParams(partyIdFA ?? '');

  return (
    <FADashboard
      contentOptions={contentOptions}
      onClientClick={(id: string) => history.push(`/account-summary/${id}`)}
      onParamsUpdate={onParamsUpdate}
      params={params}
      partyIdFA={partyIdFA}
      showSearchComponent={showSearchComponent}
      viewerPartyId={partyIdFA}
    />
  );
};
