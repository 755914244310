import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import { GetDownloadableProposal, GetDownloadableProposalVariables } from './__generated__/GetDownloadableProposal';

const queries = loader('./query.gql');
export const useGetDownloadableProposalContent = (
  options?: QueryHookOptions<GetDownloadableProposal, GetDownloadableProposalVariables>,
): QueryResult<GetDownloadableProposal, GetDownloadableProposalVariables> => {
  return useContentstackQuery(queries, options);
};
