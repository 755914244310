import { useEffect, useState } from 'react';

import { ComponentHookResult, ContentOptions } from '@sigfig/digital-wealth-core';

import {
  InvestmentSwitchRadioOptions,
  RecommendationRelateSwitchRadioOptions,
  useGetInvestmentRationaleContent,
} from '../contentstack';
import { AssetsPrefillData, useGetInvestmentProductDetails, useGetPlanDetails } from '../symphony';

export interface GetInvestmentRationaleVariables {
  contentOptions: ContentOptions;
  managedProductId: string;
  partyId: string;
}

export interface GetInvestmentRationaleData {
  content: InvestmentRationale;
  prefillData: {
    assetsToSell: AssetsPrefillData[];
    isInvestmentSwitch?: string | null;
    isPartOfInvestmentStrategy?: string | null;
    planId: string;
    planUpdateWorkflowId: string;
    reason: string;
    recommendationRationale: string;
    sourceAccountNumber: string;
  };
}

export interface Items {
  label: string;
  value: string;
}
export interface AssetsFieldName {
  amount: string;
  charge: string;
  deathBenefitBase: string;
  incomeBenefitBase: string;
  productName: string;
}
export interface AssetsDetails extends AssetsFieldName {
  addAssets: string;
  label: string;
  maxiumumNumberOfAssets: number;
  removeAssets: string;
}
export interface Validations {
  accountNumber: string;
  amount: string;
  charge: string;
  investmentSwitch: string;
  productName: string;
  recommendationRationale: string;
  recommendationRelateSwitch: string;
  salesPurchase: string;
}

export interface InvestmentRationale {
  account: {
    accountNumber: string;
    investmentSold: string;
  };
  assetDetails: AssetsDetails;
  ctas: {
    primary: string;
    save: string;
    secondary: string;
  };
  currencySymbol: string;
  footerNotes: string;
  heading: string;
  imageUrl: string;
  investmentSwitch: {
    label: string;
    options: Items[];
    title: string;
    tooltip: string;
  };
  recommendationRationale: string;
  recommendationRelateSwitch: {
    label: string;
    options: Items[];
  };
  salePurchase: string;
  validations: Validations;
}

export const useGetInvestionRationaleData = ({
  contentOptions,
  managedProductId,
  partyId,
}: GetInvestmentRationaleVariables): ComponentHookResult<GetInvestmentRationaleData> => {
  const [state, setState] = useState<ComponentHookResult<GetInvestmentRationaleData>>({
    loading: true,
    error: undefined,
    data: undefined,
  });
  const { data: content, loading: contentLoading, error: contentError } = useGetInvestmentRationaleContent({
    variables: contentOptions,
  });

  const {
    data: inversementProductDetails,
    error: investmentProductError,
    loading: investmentProductLoading,
  } = useGetInvestmentProductDetails({
    variables: { managedProductId },
  });

  const { data: planData, error: planDetailsError, loading: planDetailsloading } = useGetPlanDetails({
    variables: { managedProductId },
  });

  const loadingState = planDetailsloading || contentLoading || investmentProductLoading;
  const errorState = planDetailsError || investmentProductError || contentError;
  const getOptions = (options: (InvestmentSwitchRadioOptions | RecommendationRelateSwitchRadioOptions)[]): Items[] => {
    return options.map(option => {
      return {
        value: option?.value ?? '',
        label: option?.label ?? '',
      };
    });
  };

  const convertBooleanToStringOrNull = (value?: boolean | null): string | null =>
    value === undefined || value === null ? null : String(value);

  useEffect(() => {
    const contentData = content?.all_citizens_investment_rationale?.items?.[0];
    const asssetsDetails = contentData?.asset_details;
    const validations = contentData?.validations;
    const investmentProductSwitchDetails = inversementProductDetails?.managedProduct?.investmentProductSwitch;
    const planDetails = planData?.managedProduct;
    const planUpdateWorkflowsDetails = planDetails?.planUpdateWorkflows?.[0];

    setState({
      data: {
        content: {
          heading: contentData?.heading ?? '',
          imageUrl: contentData?.imageConnection?.edges?.[0]?.node?.url ?? '',
          assetDetails: {
            label: asssetsDetails?.label ?? '',
            productName: asssetsDetails?.product_name ?? '',
            amount: asssetsDetails?.amount ?? '',
            charge: asssetsDetails?.charge ?? '',
            deathBenefitBase: asssetsDetails?.death_benefit_base ?? '',
            incomeBenefitBase: asssetsDetails?.income_benefit_base ?? '',
            maxiumumNumberOfAssets: Number(asssetsDetails?.maxiumum_number_of_assets) ?? 0,
            addAssets: asssetsDetails?.ctas?.add ?? '',
            removeAssets: asssetsDetails?.ctas?.remove ?? '',
          },
          currencySymbol: contentData?.currency_symbol ?? '',
          footerNotes: contentData?.footer_notes ?? '',
          recommendationRelateSwitch: {
            label: contentData?.recommendation_relate_switch?.label ?? '',
            options: getOptions(contentData?.recommendation_relate_switch?.radio_options ?? []),
          },
          investmentSwitch: {
            label: contentData?.investment_switch?.label ?? '',
            options: getOptions(contentData?.investment_switch?.radio_options ?? []),
            tooltip: contentData?.investment_switch?.tooltip ?? '',
            title: contentData?.investment_switch?.title ?? '',
          },
          account: {
            accountNumber: contentData?.account_details?.account_number ?? '',
            investmentSold: contentData?.account_details?.investment_sold_label ?? '',
          },
          recommendationRationale: contentData?.recommendation_rationale_label ?? '',
          salePurchase: contentData?.sale_and_purchase_reason_label ?? '',
          validations: {
            recommendationRelateSwitch: validations?.recommendation_relate_switch ?? '',
            recommendationRationale: validations?.recommendation_rationale ?? '',
            investmentSwitch: validations?.investment_switch ?? '',
            accountNumber: validations?.account_number ?? '',
            productName: validations?.product_name ?? '',
            amount: validations?.amount ?? '',
            charge: validations?.charge ?? '',
            salesPurchase: validations?.sale_and_purchase_reason ?? '',
          },
          ctas: {
            primary: contentData?.ctas?.primary ?? '',
            secondary: contentData?.ctas?.secondary ?? '',
            save: contentData?.ctas?.save_label ?? '',
          },
        },
        prefillData: {
          planId: planDetails?.planId ?? '',
          planUpdateWorkflowId: planUpdateWorkflowsDetails?.id ?? '',
          isPartOfInvestmentStrategy: convertBooleanToStringOrNull(
            planUpdateWorkflowsDetails?.isPartOfInvestmentStrategy,
          ),
          recommendationRationale: planUpdateWorkflowsDetails?.recommendationRationale ?? '',
          isInvestmentSwitch: convertBooleanToStringOrNull(investmentProductSwitchDetails?.isInvestmentSwitch),
          sourceAccountNumber: investmentProductSwitchDetails?.sourceAccountNumber ?? '',
          reason: investmentProductSwitchDetails?.reason ?? '',
          assetsToSell: investmentProductSwitchDetails?.assetsToSell ?? [],
        },
      },
      loading: loadingState,
      error: errorState,
    });
  }, [content, loadingState, errorState, planData, inversementProductDetails]);

  return state;
};
