import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HelpIcon from '@mui/icons-material/Help';
import ListIcon from '@mui/icons-material/List';
import Menu from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Divider from '@mui/material/Divider';
import { History } from 'history';
import queryString from 'query-string';
import React, { useState } from 'react';

import {
  Box,
  Button,
  ContentOptions,
  Drawer,
  FADashboardTabs,
  Grid,
  Link,
  RteContent,
  Tooltip,
  Typography,
  useGetClientInfo,
  useGetHeaderContent,
  useIsMediumScreen,
} from '@sigfig/digital-wealth-core';

import { DocusignUserType } from '../../App';

import { Logo } from './logo';

interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  history?: History;
  onBack?: () => void;
  partyIdClient?: string;
  partyIdFA?: string;
  showBackToAccountDetails?: boolean;
  showBackToAccountSummary?: boolean;
  showBackToViewClientList?: boolean;
  showSaveAndExit?: boolean;
  showSearchButton?: boolean;
  showSubHeader?: boolean;
  showViewClientList?: boolean;
  userType?: string;
}

export const Header: React.FC<Props> = ({
  contentOptions,
  dataQa = 'header',
  history,
  partyIdClient,
  partyIdFA,
  showBackToAccountSummary = false,
  showBackToAccountDetails = false,
  showBackToViewClientList = false,
  showSaveAndExit = false,
  showSearchButton = false,
  showSubHeader = false,
  showViewClientList = false,
  onBack,
  userType,
}) => {
  const isMobile = useIsMediumScreen();
  const [open, setOpen] = useState(false);
  const { data: headerComponentData } = useGetHeaderContent({
    variables: contentOptions,
  });
  const { data: clientNameData, loading: clientNameLoading, error: clientNameError } = useGetClientInfo({
    skip: !partyIdClient,
    variables: { partyId: partyIdClient ?? '' },
  });
  const partyPerson = clientNameData?.client?.party?.partyPerson;
  const clientName = partyPerson?.displayName
    ? partyPerson?.displayName
    : partyPerson?.givenName && partyPerson?.familyName
    ? `${partyPerson?.givenName} ${partyPerson?.familyName}`
    : undefined;

  const content = headerComponentData?.all_header?.items?.[0];

  const getMenuActions = () => (
    <Grid
      alignItems={isMobile ? 'left' : 'center'}
      container
      flexDirection={isMobile ? 'column' : 'row'}
      height="100%"
      item
      justifyContent="right"
      xs={showSaveAndExit ? 3 : 12}
    >
      {showViewClientList && (
        <Grid item>
          <Link
            href={`/fa-dashboard/${partyIdFA}`}
            sx={{
              mr: 3,
              px: { md: 1 },
              textDecoration: 'none',
            }}
          >
            <Button startIcon={<ListIcon />} sx={{ pr: { md: 2 }, color: 'text.primary' }}>
              <Typography component="span" variant="subtitle2">
                {content?.view_client_list_link_text}
              </Typography>
            </Button>
          </Link>
        </Grid>
      )}
      {showSearchButton && (
        <Grid item>
          <Link
            onClick={() => {
              const { tab } = queryString.parse(window.location.search ?? '');
              history?.replace(`/fa-dashboard/search?redirectTo=${tab ?? FADashboardTabs.ActiveClientsTab}`);
            }}
            sx={{
              mr: 3,
              px: { md: 1 },
              textDecoration: 'none',
            }}
          >
            <Button startIcon={<SearchIcon />} sx={{ pr: { md: 2 }, color: 'text.primary' }}>
              <Typography variant="body2">{content?.search_link_text}</Typography>
            </Button>
          </Link>
        </Grid>
      )}
      {content?.help_tooltip_content && (
        <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {!isMobile && (showViewClientList || showSearchButton) && (
            <Divider orientation="vertical" sx={{ mr: 4, height: 32 }} />
          )}
          <Tooltip
            tooltipContent={
              <>
                <Typography sx={{ fontWeight: 700 }} variant="inherit">
                  {content?.help_tooltip_header}
                </Typography>
                <RteContent data={content?.help_tooltip_content} />
              </>
            }
          >
            <Button startIcon={<HelpIcon />} sx={{ color: 'text.primary' }}>
              <Typography component="span" mr={4} variant="subtitle2">
                {content?.help_button_text}
              </Typography>
            </Button>
          </Tooltip>
        </Grid>
      )}
      {showSaveAndExit && (
        <Grid item sx={{ mr: 2 }}>
          <Button onClick={onBack} sx={{ color: 'primary.main', border: '1px solid' }}>
            <Typography variant="body1">{content?.save_exit_text}</Typography>
          </Button>
        </Grid>
      )}
      {!showSaveAndExit && userType?.toUpperCase() !== DocusignUserType.Client.toUpperCase() && (
        <Grid
          item
          sx={{
            background: 'linear-gradient(0deg, rgba(35, 101, 246, 0.08), rgba(35, 101, 246, 0.08)), #FFFFFF',
            color: 'primary.main',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            px: 4,
          }}
        >
          <SupervisedUserCircleIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="subtitle2">{content?.advisor_text}</Typography>
        </Grid>
      )}
    </Grid>
  );

  const headerStyle = {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  };

  return (
    <>
      <Grid container data-qa={dataQa} sx={{ ...headerStyle, height: '80px' }}>
        <Grid item pl={4} xs={9}>
          <Logo />
        </Grid>
        {isMobile && (
          <Grid data-qa={`${dataQa}-actions`} item textAlign="right" xs={3}>
            <Button
              aria-label="Menu actions"
              data-qa={`${dataQa}-menu-mobile`}
              onClick={() => setOpen(true)}
              sx={{ color: 'text.primary' }}
            >
              <Menu />
            </Button>
            <Drawer
              actions={<Box sx={{ display: 'flex', flexDirection: 'column' }}>{getMenuActions()}</Box>}
              onClose={() => setOpen(false)}
              open={open}
            />
          </Grid>
        )}

        {!isMobile && getMenuActions()}
      </Grid>
      {(showBackToAccountSummary || showBackToAccountDetails || showBackToViewClientList) && onBack && (
        <Grid
          data-qa={dataQa}
          item
          sx={{
            // TODO: Remove bottom border when the account details page has grey background in the top section
            borderBottom: showBackToAccountSummary ? '1px solid rgba(0, 0, 0, 0.12)' : '',
            px: 3,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            height: '45px',
          }}
        >
          <Box sx={{ display: 'flex', width: 1 }}>
            <Button
              onClick={onBack}
              startIcon={<ChevronLeftIcon fontSize="small" sx={{ color: 'primary.main' }} />}
              sx={{ pr: 2 }}
            >
              <Typography sx={{ color: 'text.primary', fontWeight: 400 }} variant="body2">
                {showBackToViewClientList
                  ? content?.back_to_fa_dashboard_button_text
                  : showBackToAccountSummary
                  ? content?.back_to_account_summary_button_text
                  : content?.back_to_account_details_button_text}
              </Typography>
            </Button>
          </Box>
        </Grid>
      )}
      {showSubHeader && !clientNameLoading && !clientNameError && clientName && (
        <Grid
          data-qa={`${dataQa}-subheader`}
          item
          sx={{
            py: 2,
            backgroundColor: 'action.background',
            color: 'primary.main',
          }}
          xs={12}
        >
          <RteContent
            config={{ clientName }}
            data={content?.subheader_text ?? ''}
            sx={{ textAlign: 'center' }}
            variantMapping={{ h6: 'div' }}
          />
        </Grid>
      )}
    </>
  );
};
