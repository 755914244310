import React, { useContext } from 'react';

import { ContentOptions, Locale, ManagedProductType, Partner, Product } from '@sigfig/digital-wealth-core';

export interface AppContext {
  accountPrefix: string;
  contentOptions: ContentOptions;
  program: ManagedProductType;
}

const defaultAppContext: AppContext = {
  accountPrefix: 'SZG', // SZG the only prefix
  contentOptions: {
    locale: Locale.en_us,
    partner: Partner.Citizens,
    product: Product.DigitalWealthPro,
  },
  program: ManagedProductType.DIGITAL_ADVICE_PRO,
};

const AppContext = React.createContext(defaultAppContext);

export const AppProvider: React.FC = ({ children }) => {
  return (
    <AppContext.Provider
      value={{
        ...defaultAppContext,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = (): AppContext => {
  return useContext(AppContext);
};
