import React, { useEffect, useState } from 'react';

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  ContentOptions,
  Divider,
  DropdownItem,
  Grid,
  Link,
  LoadingButton,
  Modal,
  RteContent,
  TextField,
  Typography,
} from '@sigfig/digital-wealth-core';

import { defaultPartnerConfigs } from '../../config';

import { useGetClientExternalIdentifiers } from './symphony';

export interface AccountDataItem {
  primaryLabel: string;
  secondaryLabel: string;
}

type BranchCodeContent = {
  label: string;
  placeholder: string;
};

export interface BranchCodeItem {
  codes: DropdownItem<string>[];
  content: BranchCodeContent;
  setSelectedBranchCode: (value: string) => void;
}

export interface AccountSetupModalProps {
  content: {
    accountPrefixLabel: string;
    branchCode: BranchCodeContent;
    disclaimer: string;
    primaryButtonCta: string;
    repCodeLabel: string;
    secondaryButtonCta: string;
    title: string;
  };
  contentOptions: ContentOptions;
  data: {
    accountPrefix: AccountDataItem;
    branchCodes: DropdownItem<string>[];
  };
  onClose: () => void;
  onNext: (branchCode: string) => void;
  open: boolean;
  partyIdFA: string;
}

const AccountItem: React.FC<AccountDataItem> = ({ primaryLabel, secondaryLabel }) => {
  return (
    <>
      <Grid display="flex" item justifyContent="flex-end" xs={12}>
        <Typography>{primaryLabel}</Typography>
      </Grid>
      <Grid display="flex" item justifyContent="flex-end" xs={12}>
        <Typography>{secondaryLabel}</Typography>
      </Grid>
    </>
  );
};

const BranchCodes: React.FC<BranchCodeItem> = ({ codes, content, setSelectedBranchCode }) => {
  const [branchCode, setBranchCode] = useState('');
  useEffect(() => {
    setSelectedBranchCode(branchCode);
  }, [branchCode, setSelectedBranchCode]);

  return (
    <>
      <Grid item xs={6}>
        <Typography>{content.label}</Typography>
      </Grid>
      <Grid display="flex" item justifyContent="flex-end" xs={6}>
        <Autocomplete
          filterOptions={(options, state) => {
            if (!state.inputValue) {
              return options.slice(0, 5);
            }
            return options
              .filter(
                el =>
                  el.label.toString().toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  el.value.toLowerCase().includes(state.inputValue.toLowerCase()),
              )
              .slice(0, 5);
          }}
          getOptionLabel={option => `${option.value} - ${option.label}`}
          onChange={(_e, value) => setBranchCode(value?.value ?? '')}
          options={codes}
          renderInput={params => (
            <TextField
              placeholder={content.placeholder}
              {...params}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          renderOption={(props, option: DropdownItem<string>) => (
            <Box {...props} component="li" key={option.value}>
              {option.value} - {option.label}
            </Box>
          )}
          sx={{ width: '400px' }}
          value={codes.find(c => c.value === branchCode) ?? null}
        />
      </Grid>
    </>
  );
};

export const AccountSetupModal: React.FC<AccountSetupModalProps> = ({
  content,
  contentOptions,
  data,
  open,
  onClose,
  onNext,
  partyIdFA,
}) => {
  const [branchCode, setBranchCode] = useState('');
  const { data: partyData, loading: partyDataLoading, error: partyDataError } = useGetClientExternalIdentifiers({
    variables: { partyId: partyIdFA },
  });

  const partyPerson = partyData?.client?.party?.partyPerson;
  const faName = `${partyPerson?.givenName} ${partyPerson?.familyName}`;
  const repCodeValue = partyData?.client?.party?.externalSystemIdentifiers?.find(
    i => i.name === defaultPartnerConfigs.featureFlags.repCodeIdentifierName,
  )?.value;

  return (
    <Modal
      actions={
        <>
          <RteContent
            anchorComponent={props => <Link {...props} rel="noopener" target="_blank" underline="hover" />}
            data={content.disclaimer}
          />
          <Button onClick={onClose} variant="outlined">
            {content.secondaryButtonCta}
          </Button>
          <LoadingButton
            disabled={!branchCode}
            loading={false}
            onClick={() => onNext(branchCode)}
            sx={{ whiteSpace: { md: 'nowrap' } }}
            variant="contained"
          >
            {content.primaryButtonCta}
          </LoadingButton>
        </>
      }
      content={
        <>
          {partyDataLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress disableShrink />
            </Box>
          ) : partyDataError ? (
            <Alert contentOptions={contentOptions} error={partyDataError} severity="error" />
          ) : (
            <Grid container spacing={5}>
              <Grid container item xs={12}>
                <BranchCodes
                  codes={data.branchCodes}
                  content={content.branchCode}
                  setSelectedBranchCode={setBranchCode}
                />
                <Divider orientation="horizontal" sx={{ mt: '10px', width: '100%' }} />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography>{content.repCodeLabel}</Typography>
                </Grid>
                <Grid display="grid" item justifyContent="flex-end" xs={6}>
                  <AccountItem primaryLabel={repCodeValue ?? ''} secondaryLabel={faName ?? ''} />
                </Grid>
                <Divider orientation="horizontal" sx={{ mt: '10px', width: '100%' }} />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography>{content.accountPrefixLabel}</Typography>
                </Grid>
                <Grid display="grid" item justifyContent="flex-end" xs={6}>
                  <AccountItem
                    primaryLabel={data.accountPrefix.primaryLabel}
                    secondaryLabel={data.accountPrefix.secondaryLabel}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      }
      maxWidth="md"
      onClose={onClose}
      open={open}
      title={content.title}
    />
  );
};
