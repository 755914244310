import { Page, Text, View } from '@react-pdf/renderer';
import React from 'react';

import {
  PdfComponentStyle as Style,
  PdfFooter as Footer,
  PdfHeader as Header,
  PdfOutlinedTick as OutlinedTick,
} from '@sigfig/digital-wealth-core';

import { FooterGraphics } from '../../components/FooterGraphics';

export interface Benefits {
  text: string;
  title: string;
}

export interface WhyDigitalAdviceProContent {
  benefitTitle: string;
  benefits: Benefits[];
  footerLogo: string;
  footerText: string;
  info: string[];
  logo?: string;
  pageName: string;
  title: string;
}

interface Props {
  content: WhyDigitalAdviceProContent;
  pdfStyles: Style;
}

export const WhyDigitalAdvicePro: React.FC<Props> = ({ content, pdfStyles }) => {
  const commonTextStyle = {
    color: pdfStyles.textPrimary.color,
    fontSize: 10,
    marginTop: '10px',
    marginBottom: '10px',
  };

  return (
    <>
      <Page size="LETTER" wrap={false}>
        <Header logoUrl={content.logo} pageName={content.pageName} pdfStyles={pdfStyles} />
        <View
          style={{
            width: '100%',
            textAlign: 'left',
            paddingTop: '48px',
            paddingLeft: '80px',
            paddingRight: '80px',
          }}
        >
          <Text
            style={{
              color: pdfStyles.primaryMainColor.color,
              fontSize: 14,
              marginBottom: '12px',
              marginLeft: '24px',
            }}
          >
            {content.title}
          </Text>
          <View style={{ paddingRight: '40px' }}>
            {content.info.map(item => {
              return (
                <View
                  key={item}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    textAlign: 'justify',
                  }}
                >
                  <OutlinedTick
                    outlineColor={pdfStyles.border.borderColor}
                    style={{ position: 'relative', top: '4' }}
                    tickColor={pdfStyles.primaryDarkColor.color}
                  />
                  <Text style={commonTextStyle}>{item}</Text>
                </View>
              );
            })}
          </View>
          <View style={{ marginLeft: '24px' }}>
            <Text style={{ ...commonTextStyle, marginTop: '32px', ...pdfStyles.whyDigitalAdviceProBold }}>
              {content.benefitTitle}
            </Text>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {content.benefits.map(item => {
                return (
                  <View
                    key={item.title}
                    style={{
                      ...pdfStyles.border,
                      width: '48%',
                      padding: '12px',
                      height: '150px',
                      marginBottom: '12px',
                      textAlign: 'justify',
                    }}
                  >
                    <OutlinedTick
                      outlineColor={pdfStyles.border.borderColor}
                      style={{ position: 'relative', left: '-8' }}
                      tickColor={pdfStyles.primaryDarkColor.color}
                    />
                    <Text style={{ ...commonTextStyle, ...pdfStyles.whyDigitalAdviceProBold }}>{item.title}</Text>
                    <Text style={commonTextStyle}>{item.text}</Text>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
        <Footer pdfStyles={pdfStyles} text={content.footerText} />
        <FooterGraphics logo={content.footerLogo} pdfStyles={pdfStyles} />
      </Page>
    </>
  );
};
