import { useLocation } from 'react-router-dom';
import { createSearchParams, generatePath, matchRoutes, RouteObject } from 'react-router-dom-v5-compat';

export const generateAbsolutePath: typeof generatePath = (...args) => `/${generatePath(...args)}`;

export const getSearchParams = (paramValuePair?: Record<string, string | undefined>): string => {
  const paramsWithValue = Object.entries(paramValuePair ?? {})
    .filter(([_, v]) => !!v)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  return Object.keys(paramsWithValue).length ? `?${createSearchParams(paramsWithValue)}` : '';
};

/**
 * @returns - The current page that the user is currently on within the application if it exists.
 */
export const useCurrentPage = <T extends string, TEnumValue extends string>(
  routes: { [key in T]: TEnumValue },
): TEnumValue | undefined => {
  const location = useLocation();
  const routeList: RouteObject[] = Object.values<TEnumValue>(routes).map(p => ({ path: p }));
  const matchingRoutes = matchRoutes(routeList, location);
  if (!matchingRoutes) {
    return;
  }

  return Object.values<TEnumValue>(routes).find(p => matchingRoutes.find(r => p === r.route.path));
};
