import React, { FC, lazy } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  AccountWidgetsVariants,
  FINANCIAL_ACCOUNT_STATUSES_TO_INCLUDE,
  FinancialAccountStatus,
} from '@sigfig/digital-wealth-core';

import { useApp } from '../../contexts/App';
import { useAccountActions } from '../../utils/account';
import { AccountActionRoutes } from '..';

const Widgets = lazy(() => import('../../containers/Widgets'));

export const WidgetsWrapper: FC<RouteComponentProps<{ partyId: string }>> = ({
  history,
  match: {
    params: { partyId },
  },
}) => {
  const { contentOptions } = useApp();
  const financialAccountsToInclude = FINANCIAL_ACCOUNT_STATUSES_TO_INCLUDE.filter(
    status => status !== FinancialAccountStatus.ACTIVE,
  );
  return (
    <Widgets
      accountListProps={{
        accountDetailsToShow: {
          showAccountType: false,
          showOwnershipType: false,
          showPendingBars: true,
          showYtdReturn: false,
          showModelPortfolioInfo: false,
        },
        accountInfoVariant: 'standard',
        actions: useAccountActions(AccountActionRoutes.WIDGETS),
        variant: AccountWidgetsVariants.Separated,
      }}
      contentOptions={contentOptions}
      financialAccountStatusesToInclude={financialAccountsToInclude}
      nullStateActions={[
        {
          action: 'onboarding',
          route: `/onboarding/${partyId}`,
        },
      ]}
      nullStateKey="introducingDigitalAdvice"
      partyId={partyId}
    />
  );
};
