/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BillingRateTargetType {
  ADVISOR = "ADVISOR",
  PLATFORM = "PLATFORM",
  PROVIDER = "PROVIDER",
  UNKNOWN = "UNKNOWN",
}

export enum FlowType {
  ONBOARDING = "ONBOARDING",
  RETAKE = "RETAKE",
}

export enum InterestFrequency {
  LONG_TERM = "LONG_TERM",
  SHORT_TERM = "SHORT_TERM",
}

export enum ManagedProductType {
  DIGITAL_ADVICE = "DIGITAL_ADVICE",
  DIGITAL_ADVICE_PRO = "DIGITAL_ADVICE_PRO",
}

export enum PlanOption {
  VIA_CALL = "VIA_CALL",
  VIA_FORM = "VIA_FORM",
}

export enum PlanUpdateWorkflowStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  DOCS_PREPARED = "DOCS_PREPARED",
  DOCS_SIGNED = "DOCS_SIGNED",
  PENDING = "PENDING",
  SIGNATURE_FAILED = "SIGNATURE_FAILED",
}

export enum QuestionnaireFilterTypes {
  LATEST = "LATEST",
  ONBOARDING_COMPLETED = "ONBOARDING_COMPLETED",
  RTQ_COMPLETED = "RTQ_COMPLETED",
}

export enum RateUnitType {
  DAY = "DAY",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum RolloverType {
  SOLICITED = "SOLICITED",
  UNSOLICITED = "UNSOLICITED",
}

export enum SleeveType {
  CASH = "CASH",
  INVESTMENT = "INVESTMENT",
}

export interface InvestmentProductSwitchAssetInput {
  amount?: SymphonyDecimal | null;
  annuityDeathBenefitBase?: SymphonyDecimal | null;
  annuityIncomeBenefitBase?: SymphonyDecimal | null;
  annuityMinimumGuaranteedRate?: SymphonyDecimal | null;
  annuityRidersAnnualCost?: SymphonyDecimal | null;
  investmentHeldForMonths?: number | null;
  investmentHeldForYears?: number | null;
  name?: string | null;
  salesCharge?: SymphonyDecimal | null;
  surrenderCharges?: SymphonyDecimal | null;
}

export interface LiveRateInput {
  certificateNumber?: string | null;
  customerInitiated: boolean;
  gicType: string;
  interestBasis: number;
  interestFrequency: InterestFrequency;
  isCompoundRate?: string | null;
  issuer: string;
  maturityDate: SymphonyDateTime;
  pricingRequestId: number;
  rates: LiveRateRatesInput;
  securityId: string;
  strikeDate?: SymphonyDateTime | null;
  term: LiveRateTermsInput;
}

export interface LiveRateRatesInput {
  interimRate?: SymphonyDecimal | null;
  maximumRate?: SymphonyDecimal | null;
  targetRate: SymphonyDecimal;
}

export interface LiveRateTermsInput {
  displayPeriod: number;
  displayUnit: RateUnitType;
  end?: number | null;
  start?: number | null;
  unit?: RateUnitType | null;
}

/**
 * How many records to skip and the total records to return
 */
export interface PaginationInput {
  limit: number;
  offset: number;
}

/**
 * The parameters used to select a product using a sleeve
 */
export interface SelectedModelPortfolioInput {
  liveRates?: LiveRateInput[] | null;
  modelPortfolioInternalId: number;
  modelPortfolioSeriesId: number;
  percentage?: SymphonyDecimal | null;
  sleeveType: SleeveType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
