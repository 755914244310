import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  LoadingButton,
  Locale,
  Partner,
  Product,
  RadioGroup,
  RteContent,
  Skeleton,
  Textarea,
  TextField,
  Tooltip,
  Typography,
} from '@sigfig/digital-wealth-core';

import { Assets, getInputError } from './AssetDetails';
import { useGetInvestionRationaleData } from './hooks/useGetInvestmentRationale';
import { useSaveInvestmentProductSwitch, useUpdatePlanUpdateWorkflow } from './symphony';

export interface Props {
  managedProductId: string;
  onBack?: () => void;
  onNext?: () => void;
  partyId: string;
}
export interface FormData {
  [dataPointKey: string]: any;
}

const contentOptions = { locale: Locale.en_us, partner: Partner.Citizens, product: Product.DigitalWealthPro };

export const InvestmentRationale: React.FC<Props> = ({ managedProductId, onBack, onNext, partyId }) => {
  const { data: investionRationaleData, loading, error } = useGetInvestionRationaleData({
    contentOptions,
    managedProductId,
    partyId,
  });
  const dataQa = 'investment-rationale';
  const prefillData = investionRationaleData?.prefillData;
  const contentData = investionRationaleData?.content;
  const assetContent = contentData?.assetDetails;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [isInvestmentSwitch, setIsInvestmentSwitch] = useState<boolean | null>(null);
  const [isRecommenendRationaleSwitch, setIsRecommenendRationaleSwitch] = useState<boolean | null>(null);
  const [saveInvestmentProductSuccess, setSaveInvestmentProductSuccess] = useState(false);
  const [savePlanUpdateWorkFlowSuccess, setSavePlanUpdateWorkFlowSuccess] = useState(false);
  const [saveInvestmentProductSwitch] = useSaveInvestmentProductSwitch();
  const [updatePlanUpdateWorkflow] = useUpdatePlanUpdateWorkflow();
  const formHooks = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const updateIds = useCallback(() => {
    const assetDetails = prefillData?.assetsToSell;
    const newAssetIdList: number[] = [];
    if (assetDetails) {
      if (assetDetails.length === 0) {
        newAssetIdList.push(1);
        setlastAssetId(1);
      } else {
        assetDetails.forEach((asset, index) => {
          newAssetIdList.push(index + 1);
        });
        setlastAssetId(assetDetails.length);
      }
    }
    setAssetsIdList(newAssetIdList);
    if (prefillData?.isInvestmentSwitch) {
      setIsInvestmentSwitch(prefillData.isInvestmentSwitch === 'true');
    }
    if (prefillData?.isPartOfInvestmentStrategy) {
      setIsRecommenendRationaleSwitch(prefillData.isPartOfInvestmentStrategy === 'true');
    }
  }, [prefillData]);

  useEffect(() => {
    const { investmentSwitch } = getValues();
    if (!investmentSwitch) {
      updateIds();
    }
  }, [prefillData, updateIds]);

  const {
    handleSubmit,
    control,
    errors: fieldsErrors,
    formState: { isSubmitted: formIsSubmitted },
    getValues,
  } = formHooks;

  const [assetsIdList, setAssetsIdList] = useState<number[]>([]);
  const [lastAssetId, setlastAssetId] = useState<number>(0);

  const fieldNames = {
    recommendationRelateSwitch: 'recommendationRelateSwitch',
    recommendationRationale: 'recommendationRationale',
    investmentSwitch: 'investmentSwitch',
    investmentSold: 'investmentSold',
    salePurchase: 'salePurchase',
    assetDetails: {
      productName: 'productName',
      amount: 'amount',
      charge: 'charge',
      deathBenefitBase: 'deathBenefitBase',
      incomeBenefitBase: 'incomeBenefitBase',
    },
  };

  const onUnsuccessful = () => {
    setIsSubmitted(false);
  };

  const checkForEmptyString = (item: any) => {
    return item !== '' ? item : null;
  };

  const getAssetsDetails = (formData: FormData) =>
    assetsIdList.map(assetId => ({
      amount: checkForEmptyString(formData[`${fieldNames.assetDetails.amount}-${assetId}`]),
      annuityDeathBenefitBase: checkForEmptyString(formData[`${fieldNames.assetDetails.deathBenefitBase}-${assetId}`]),
      annuityIncomeBenefitBase: checkForEmptyString(
        formData[`${fieldNames.assetDetails.incomeBenefitBase}-${assetId}`],
      ),
      salesCharge: checkForEmptyString(formData[`${fieldNames.assetDetails.charge}-${assetId}`]),
      name: formData[`${fieldNames.assetDetails.productName}-${assetId}`],
    }));

  const saveInvestmentSwitchData = async (formData: any): Promise<any> => {
    try {
      const saveInvestmentSwitch = await saveInvestmentProductSwitch({
        variables: {
          managedProductId,
          isInvestmentSwitch: formData[fieldNames.investmentSwitch] === 'true',
          reason: formData[fieldNames.salePurchase],
          sourceAccountNumber: formData[fieldNames.investmentSold],
          assetsToSell: formData[fieldNames.investmentSwitch] === 'true' ? getAssetsDetails(formData) : [],
        },
      });
      if (saveInvestmentSwitch.data?.saveInvestmentProductSwitch) {
        setSaveInvestmentProductSuccess(true);
      } else {
        setErrorSaving(Error('Error saving'));
      }
    } catch (err) {
      setErrorSaving(err as Error);
    }
  };
  const savePlanUpdateWorkFlowDetails = async (formData: any): Promise<any> => {
    try {
      if (prefillData) {
        const updatePlanWorkFLow = await updatePlanUpdateWorkflow({
          variables: {
            planId: prefillData.planId,
            planUpdateWorkflowId: prefillData.planUpdateWorkflowId,
            isPartOfInvestmentStrategy: formData[fieldNames.recommendationRelateSwitch] === 'true',
            recommendationRationale: formData[fieldNames.recommendationRationale] ?? '',
          },
        });
        if (updatePlanWorkFLow.data?.updatePlanUpdateWorkflow) {
          setSavePlanUpdateWorkFlowSuccess(true);
        } else {
          setErrorSaving(Error('Error saving'));
        }
      }
    } catch (err) {
      setErrorSaving(err as Error);
    }
  };

  const handleSuccessfulSubmit = async (formData: any): Promise<any> => {
    try {
      saveInvestmentSwitchData(formData);
      savePlanUpdateWorkFlowDetails(formData);
    } catch (err) {
      setErrorSaving(err as Error);
    }
  };

  useEffect(() => {
    if (errorSaving) {
      setIsSubmitted(false);
      setIsSaving(false);
    } else {
      if (saveInvestmentProductSuccess && savePlanUpdateWorkFlowSuccess) {
        if (isSubmitted) {
          setIsSubmitted(false);
          onNext?.();
        } else {
          if (isSaving) {
            setIsSaving(false);
          }
        }
      }
    }
  }, [isSaving, isSubmitted, saveInvestmentProductSuccess, savePlanUpdateWorkFlowSuccess, onNext, errorSaving]);

  const onRemove = (removeIndex: number) => {
    assetsIdList.splice(removeIndex, 1);
    setAssetsIdList([...assetsIdList]);
  };
  const onAdd = () => {
    assetsIdList.push(lastAssetId + 1);
    setlastAssetId(lastAssetId + 1);
    setAssetsIdList([...assetsIdList]);
  };
  const getTextAreaBox = (
    componentName: string,
    label: string,
    maxLength: number,
    isRequired?: boolean,
    validationMessage?: string,
    defaultvalue?: string,
  ) => {
    return (
      <>
        <Typography data-qa={`${dataQa}-${componentName}-label`} sx={{ mb: 2 }} variant="h6">
          {label}
        </Typography>
        <Controller
          control={control}
          defaultValue={defaultvalue}
          name={componentName}
          render={({ onChange, value, name }) => (
            <Textarea
              error={formIsSubmitted && isRequired ? fieldsErrors[componentName] : false}
              maxLength={maxLength}
              name={name}
              onChange={onChange}
              value={value}
            />
          )}
          rules={isRequired ? { required: true } : {}}
        />
        {formIsSubmitted && isRequired && getInputError(fieldsErrors[componentName], validationMessage)}
        <Divider sx={{ my: 4 }} />
      </>
    );
  };

  return (
    <Box>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : error ? (
        <Alert contentOptions={contentOptions} error={error} severity="error" />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {contentData?.imageUrl && (
            <Box sx={{ mb: 2, mt: 4 }}>
              <img alt="" src={contentData.imageUrl} />
            </Box>
          )}
          <Typography data-qa={`${dataQa}-heading`} variant="h3">
            {contentData?.heading}
          </Typography>
          <Box sx={{ width: 1, mt: 2 }}>
            <Box sx={{ mx: 10 }}>
              <Divider sx={{ my: 4 }} />
              <Typography data-qa={`${dataQa}-recommendate-relate`} sx={{ mb: 2 }} variant="h6">
                {contentData?.recommendationRelateSwitch.label}
              </Typography>
              <Controller
                control={control}
                defaultValue={prefillData?.isPartOfInvestmentStrategy}
                name={fieldNames.recommendationRelateSwitch}
                render={({ onChange, value, name }) => (
                  <RadioGroup
                    disabled={false}
                    error={formIsSubmitted ? fieldsErrors[fieldNames.recommendationRelateSwitch] : false}
                    items={contentData?.recommendationRelateSwitch.options ?? []}
                    name={name}
                    onChange={e => {
                      setIsRecommenendRationaleSwitch(e === 'true');
                      onChange(e);
                    }}
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
              {formIsSubmitted &&
                getInputError(
                  fieldsErrors[fieldNames.recommendationRelateSwitch],
                  contentData?.validations.recommendationRelateSwitch,
                )}
              <Divider sx={{ my: 4 }} />
              {getTextAreaBox(
                fieldNames.recommendationRationale,
                contentData?.recommendationRationale ?? '',
                700,
                isRecommenendRationaleSwitch !== false,
                contentData?.validations.recommendationRationale,
                prefillData?.recommendationRationale ?? '',
              )}
              <Typography data-qa={`${dataQa}-investment-switch-title`} sx={{ mb: 2 }} variant="h6">
                {contentData?.investmentSwitch.title}
              </Typography>
              <Typography data-qa={`${dataQa}-investment-switch`} variant="body2">
                {contentData?.investmentSwitch.label}
                {contentData?.investmentSwitch?.tooltip && (
                  <Tooltip
                    placement="top"
                    tooltipContent={<RteContent data={contentData.investmentSwitch.tooltip} />}
                    useGenericInfoIcon
                  />
                )}
              </Typography>
              <Controller
                control={control}
                defaultValue={prefillData?.isInvestmentSwitch}
                name={fieldNames.investmentSwitch}
                render={({ onChange, value, name }) => (
                  <RadioGroup
                    disabled={false}
                    error={formIsSubmitted ? fieldsErrors[fieldNames.investmentSwitch] : false}
                    items={contentData?.investmentSwitch.options ?? []}
                    name={name}
                    onChange={e => {
                      setIsInvestmentSwitch(e === 'true');
                      onChange(e);
                    }}
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
              {formIsSubmitted &&
                getInputError(fieldsErrors[fieldNames.investmentSwitch], contentData?.validations.investmentSwitch)}
              <Divider sx={{ my: 4 }} />
              {isInvestmentSwitch && (
                <>
                  <Typography data-qa={`${dataQa}-investment-sold`} sx={{ mb: 2 }} variant="h6">
                    {contentData?.account.investmentSold}
                  </Typography>
                  <Box sx={{ width: 0.4 }}>
                    <Controller
                      control={control}
                      defaultValue={prefillData?.sourceAccountNumber ?? ''}
                      name={fieldNames.investmentSold}
                      render={({ onChange, value, name, ref }) => (
                        <TextField
                          error={formIsSubmitted ? fieldsErrors[fieldNames.investmentSold] : false}
                          fullWidth
                          inputRef={ref}
                          label={contentData?.account.accountNumber}
                          name={name}
                          onChange={onChange}
                          type="text"
                          value={value}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </Box>
                  {formIsSubmitted &&
                    getInputError(fieldsErrors[fieldNames.investmentSold], contentData?.validations.accountNumber)}
                  <Divider sx={{ my: 4 }} />
                  <Typography data-qa={`${dataQa}-assets-label`} sx={{ mb: 2 }} variant="h6">
                    {assetContent?.label}
                  </Typography>
                  {!!assetsIdList &&
                    assetsIdList.map((assetId, index) => (
                      <Box key={assetId} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Assets
                          assetUniqueId={assetId}
                          assetsContent={assetContent}
                          contentOptions={contentOptions}
                          currencySymbol={contentData?.currencySymbol ?? ''}
                          fieldNames={fieldNames.assetDetails}
                          formHooks={formHooks}
                          index={assetId}
                          key={assetId}
                          prefillData={
                            prefillData?.assetsToSell && prefillData?.assetsToSell.length
                              ? prefillData?.assetsToSell[index]
                              : undefined
                          }
                          validationMessages={contentData?.validations ?? undefined}
                        />
                        {assetsIdList.length > 1 &&
                          (assetsIdList.length - 1 !== index ||
                            assetsIdList.length === (assetContent?.maxiumumNumberOfAssets ?? 0)) && (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button onClick={() => onRemove(index)} size="medium" sx={{ mt: 0.5 }}>
                                {assetContent?.removeAssets}
                              </Button>
                            </Box>
                          )}
                      </Box>
                    ))}

                  {assetsIdList.length < (assetContent?.maxiumumNumberOfAssets ?? 0) && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                      <Button onClick={() => onAdd()} size="medium" sx={{ display: 'flex', justifyContent: 'left' }}>
                        {assetContent?.addAssets ?? '+ Add an asset'}
                      </Button>
                      {assetsIdList.length > 1 && (
                        <Button onClick={() => onRemove(assetsIdList.length - 1)} size="medium">
                          {assetContent?.removeAssets ?? 'Remove'}
                        </Button>
                      )}
                    </Box>
                  )}
                  <Divider sx={{ mt: 4 }} />
                  <RteContent
                    data={contentData?.footerNotes ?? ''}
                    sx={{ backgroundColor: 'background.default', py: 4, pr: 1 }}
                  />
                  <Divider sx={{ mb: 4 }} />
                  {getTextAreaBox(
                    fieldNames.salePurchase,
                    contentData?.salePurchase ?? '',
                    350,
                    true,
                    contentData?.validations.salesPurchase,
                    prefillData?.reason ?? '',
                  )}
                </>
              )}
            </Box>
          </Box>
          {errorSaving && (
            <Box flexDirection="column" sx={{ pt: 2 }}>
              <Alert contentOptions={contentOptions} error={errorSaving} severity="error">
                There was an error saving the Investment Rationale form.
              </Alert>
            </Box>
          )}
          <Grid container direction="row" justifyContent="center" pb={1} pt={2} spacing={2}>
            <Grid item md="auto" xs={12}>
              <Button
                onClick={() => {
                  onBack?.();
                }}
                size="medium"
                variant="outlined"
              >
                {contentData?.ctas.secondary}
              </Button>
            </Grid>
            <Grid item md="auto" xs={12}>
              <LoadingButton
                disabled={isSubmitted}
                loading={isSaving}
                onClick={() => {
                  setIsSaving(true);
                  handleSuccessfulSubmit(getValues());
                }}
                size="medium"
                variant="outlined"
              >
                {contentData?.ctas.save}
              </LoadingButton>
            </Grid>
            <Grid item md="auto" xs={12}>
              <LoadingButton
                disabled={isSaving}
                loading={isSubmitted}
                onClick={() => {
                  setIsSubmitted(true);
                  handleSubmit(handleSuccessfulSubmit, onUnsuccessful)();
                }}
                size="medium"
                variant="contained"
              >
                {contentData?.ctas.primary}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
