import queryString from 'query-string';
import React, { FC, lazy, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  AlertAndLoading,
  AssetClassTier,
  ManagedProductType,
  PerformanceMethodTypes,
  RetakeRTQStates,
  useModalState,
} from '@sigfig/digital-wealth-core';

import { AccountSetupModal } from '../../components/AccountSetupModal';
import { useGetAccountSetupModalData } from '../../components/AccountSetupModal/hooks/useGetAccountSetupModalData';
import { useApp } from '../../contexts/App';
import { useAccountActions } from '../../utils/account';
import { AccountActionRoutes } from '..';

interface AccountSummaryWrapperProps extends RouteComponentProps<{ partyId: string }> {
  partyId: string;
  partyIdFA: string;
}

const AccountSummary = lazy(() => import('../../containers/AccountSummary'));

export const AccountSummaryWrapper: FC<AccountSummaryWrapperProps> = ({ history, partyId, partyIdFA }) => {
  const { contentOptions } = useApp();
  const {
    open: isAccountSetupModalOpen,
    openModal: openAccountSetupModal,
    onClose: onAccountSetupModalClose,
  } = useModalState();

  const {
    data: accountSetupModalData,
    loading: accountSetupModalLoading,
    error: accountSetupModalError,
  } = useGetAccountSetupModalData(contentOptions);

  const actionToInvoke = useMemo(() => {
    const { actionName, accountNumber } = queryString.parse(location.search ?? '');
    return actionName && !Array.isArray(actionName) && accountNumber && !Array.isArray(accountNumber)
      ? { actionName, accountNumber }
      : undefined;
  }, [location.search]);

  const accountActions = useAccountActions(AccountActionRoutes.ACCOUNT_SUMMARY, {
    openModal: openAccountSetupModal,
  });

  if (accountSetupModalLoading || accountSetupModalError) {
    return (
      <AlertAndLoading
        ariaLabel="Loading account setup data"
        contentOptions={contentOptions}
        error={accountSetupModalError}
        loading={accountSetupModalLoading}
      />
    );
  }

  if (!accountSetupModalData) {
    return null;
  }

  const handleRetakeQuestionnaire = (managedProductId: string) => {
    history.push(`/rce/${partyId}/questionnaire/${managedProductId}?source=${AccountActionRoutes.ACCOUNT_SUMMARY}`);
  };

  const handleRedirectToSignDocuments = (managedProductId: string) => {
    history.push(`/rce/${partyId}/questionnaire/${managedProductId}?state=${RetakeRTQStates.FA_DOCUSIGN}`);
  };

  const handleAccountSetupModalNext = (branchCode: string) => {
    history.push(`/onboarding/${partyId}/madlibs`, { branchCode });
  };

  const getAssetClassTier = (managedProductType: ManagedProductType) =>
    managedProductType === ManagedProductType.DIGITAL_ADVICE_PRO ? AssetClassTier.ENCLOSING : AssetClassTier.MODEL;

  return (
    <>
      <AccountSummary
        accountDetailsToShow={{
          showOwnershipType: false,
          showModelPortfolioInfo: true,
          showAccountType: true,
          showLabels: true,
          showPendingBars: true,
          showYtdReturn: true,
        }}
        accountProps={{
          partyIdFA,
        }}
        actionToInvoke={actionToInvoke}
        actions={accountActions}
        assetClassTier={getAssetClassTier}
        contentOptions={contentOptions}
        ctasOverrides={{
          raiseCash: 'Raise Cash',
        }}
        featureFlags={{
          defaultContributionAmount: '100',
          ignoreInsufficientFunds: true,
          syncExternalBankAccounts: true,
        }}
        hiddenSections={['clientInfo', 'marketing', 'supportQuestions']}
        onRetakeQuestionnaire={handleRetakeQuestionnaire}
        partyId={partyId}
        performanceMethod={PerformanceMethodTypes.FROM_END_OF_DAY_VALUES}
        redirectToSignDocuments={handleRedirectToSignDocuments}
        viewerPartyId={partyIdFA}
      />
      <AccountSetupModal
        content={accountSetupModalData.content}
        contentOptions={contentOptions}
        data={accountSetupModalData.data}
        onClose={onAccountSetupModalClose}
        onNext={handleAccountSetupModalNext}
        open={isAccountSetupModalOpen}
        partyIdFA={partyIdFA}
      />
    </>
  );
};
