import {
  ComponentTypes,
  FinancialAccountType,
  PaperworkData,
  PaperworkPage,
  PaperworkType,
  TrustProfileType,
} from '@sigfig/digital-wealth-core';

import { InvestmentRationale } from '../components/InvestmentRationale';

const InheritedIRAAccountTypes = [
  FinancialAccountType.INHERITED_IRA,
  FinancialAccountType.INHERITED_ROTH_IRA,
  FinancialAccountType.INHERITED_TRADITIONAL_IRA,
];
// TODO: Expose QuestionOrderSteps and remove any
const getCoApplicantStepsFirstSection = (accountType: FinancialAccountType, paperworkType?: PaperworkType): any => {
  const orderSteps = [
    {
      questionKey: 'question:first_name',
      dataPointKey: 'data_point:first_name:string',
      symphonyMapping: 'party.partyPerson.givenName',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:middle_name',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'invalidCharactersRegex',
          value: /[^a-zA-Z -]/g,
        },
        {
          name: 'regex',
          value: /^[A-Za-z -]+$/,
        },
        {
          name: 'maxLength',
          value: 25,
        },
      ],
    },
    {
      questionKey: 'question:middle_name',
      dataPointKey: 'data_point:middle_name:string',
      symphonyMapping: 'party.partyPerson.middleName',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:last_name',
        },
      ],
      validations: [
        {
          name: 'invalidCharactersRegex',
          value: /[^a-zA-Z ]/g,
        },
        {
          name: 'regex',
          value: /^[A-Za-z ]+$/,
        },
        {
          name: 'maxLength',
          value: 10,
        },
      ],
    },
    {
      questionKey: 'question:last_name',
      dataPointKey: 'data_point:last_name:string',
      symphonyMapping: 'party.partyPerson.familyName',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:email',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'invalidCharactersRegex',
          value: /[^a-zA-Z -]/g,
        },
        {
          name: 'regex',
          value: /^[A-Za-z -]+$/,
        },
        {
          name: 'maxLength',
          value: 25,
        },
      ],
    },
    {
      questionKey: 'question:email',
      dataPointKey: 'data_point:email:email',
      symphonyMapping: 'party.partyContacts.contact:email',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:confirm_email',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'maxLength',
          value: 80,
        },
      ],
    },
    {
      questionKey: 'question:confirm_email',
      dataPointKey: 'data_point:confirm_email:email',
      symphonyMapping: 'party.partyContacts.contact:email',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:email_consent',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'maxLength',
          value: 80,
        },
      ],
    },
    {
      questionKey: 'question:email_consent',
      dataPointKey: 'info:email_consent:string',
      symphonyMapping: '',
      componentType: ComponentTypes.Info,
      rules: [
        {
          next: 'question:phone_number',
        },
      ],
    },
    {
      questionKey: 'question:phone_number',
      dataPointKey: 'data_point:phone_number:number',
      symphonyMapping: 'party.partyContacts.contact:mobile',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:birth_date',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'length',
          value: 10,
        },
        {
          name: 'maxLength',
          value: 10,
        },
      ],
    },
    {
      questionKey: 'question:birth_date',
      dataPointKey: 'data_point:birth_date:date',
      symphonyMapping: 'party.partyPerson.birthDate',
      componentType: ComponentTypes.Date,
      adjacent: 'question:ssn',
      rules: [
        {
          next: 'question:ssn',
        },
      ],
      validations: [
        {
          name: 'future_disabled',
          value: true,
        },
        {
          name: 'required',
          value: true,
        },
        {
          name: 'minor_age',
          value: true,
        },
      ],
    },
    {
      questionKey: 'question:ssn',
      dataPointKey: 'data_point:ssn:ssn',
      symphonyMapping: 'party.identifiers.identifierValue:ssn',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next:
            accountType === FinancialAccountType.TRUST
              ? 'question:street_address'
              : 'question:same_home_mailing_address',
        },
      ],
      formats: [
        {
          name: 'masked',
          value: {
            charsToShow: 4,
            maskChar: '*',
          },
        },
      ],
      validations: [
        {
          name: 'disabled',
          value: true,
        },
        {
          name: 'required',
          value: true,
        },
        {
          name: 'length',
          value: 9,
        },
        {
          name: 'maxLength',
          value: 9,
        },
      ],
    },
    {
      questionKey: 'question:street_address',
      dataPointKey: 'data_point:street_address:string',
      symphonyMapping: 'party.addresses.addressLine1:home',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:street_line_2',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'maxLength',
          value: 32,
        },
      ],
    },
    {
      questionKey: 'question:street_line_2',
      dataPointKey: 'data_point:street_line_2:string',
      symphonyMapping: 'party.addresses.addressLine2:home',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:city',
        },
      ],
      validations: [
        {
          name: 'maxLength',
          value: 32,
        },
      ],
    },
    {
      questionKey: 'question:city',
      dataPointKey: 'data_point:city:string',
      symphonyMapping: 'party.addresses.countrySecondarySubdivision:home',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:state',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'maxLength',
          value: 30,
        },
      ],
    },
    {
      questionKey: 'question:state',
      cmsKey: 'states',
      dataPointKey: 'data_point:state:single_option',
      symphonyMapping: 'party.addresses.countryPrimarySubdivision:home',
      componentType: ComponentTypes.Dropdown,
      adjacent: 'question:zip_code',
      rules: [
        {
          next: 'question:zip_code',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'restrictedStates',
          value: paperworkType === PaperworkType.PRIMARY,
        },
      ],
    },
    {
      questionKey: 'question:zip_code',
      dataPointKey: 'data_point:zip_code:number',
      symphonyMapping: 'party.addresses.postalCode:home',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:different_mailing_address',
        },
      ],
      validations: [
        {
          name: 'validZipCode',
          value: 'data_point:state:single_option',
        },
        {
          name: 'required',
          value: true,
        },
        {
          name: 'length',
          value: 5,
        },
        {
          name: 'maxLength',
          value: 5,
        },
      ],
    },
    {
      questionKey: 'question:different_mailing_address',
      dataPointKey: 'data_point:different_mailing_address:boolean',
      symphonyMapping: 'isMailingAddressSameAsHomeAddress',
      componentType: ComponentTypes.Checkbox,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: 'data_point:different_mailing_address:boolean',
              value: true,
            },
          ],
          next: 'question:mailing_street_address',
        },
        {
          next: accountType === FinancialAccountType.JOINT ? null : 'question:marital_status',
        },
      ],
    },
    {
      questionKey: 'question:mailing_street_address',
      dataPointKey: 'data_point:mailing_street_address:string',
      symphonyMapping: 'party.addresses.addressLine1:mailing',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:mailing_street_line_2',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'maxLength',
          value: 50,
        },
      ],
    },
    {
      questionKey: 'question:mailing_street_line_2',
      dataPointKey: 'data_point:mailing_street_line_2:string',
      symphonyMapping: 'party.addresses.addressLine2:mailing',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:mailing_city',
        },
      ],
      validations: [
        {
          name: 'maxLength',
          value: 50,
        },
      ],
    },
    {
      questionKey: 'question:mailing_city',
      dataPointKey: 'data_point:mailing_city:string',
      symphonyMapping: 'party.addresses.countrySecondarySubdivision:mailing',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:mailing_state',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'maxLength',
          value: 30,
        },
      ],
    },
    {
      questionKey: 'question:mailing_state',
      cmsKey: 'states',
      dataPointKey: 'data_point:mailing_state:single_option',
      symphonyMapping: 'party.addresses.countryPrimarySubdivision:mailing',
      componentType: ComponentTypes.Dropdown,
      adjacent: 'question:mailing_zip_code',
      rules: [
        {
          next: 'question:mailing_zip_code',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
      ],
    },
    {
      questionKey: 'question:mailing_zip_code',
      dataPointKey: 'data_point:mailing_zip_code:number',
      symphonyMapping: 'party.addresses.postalCode:mailing',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: null,
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
        {
          name: 'length',
          value: 5,
        },
        {
          name: 'maxLength',
          value: 5,
        },
        {
          name: 'validZipCode',
          value: 'data_point:mailing_state:single_option',
        },
      ],
    },
    {
      questionKey: 'question:marital_status',
      dataPointKey: 'data_point:marital_status:single_option',
      symphonyMapping: 'party.partyPerson.maritalStatus',
      componentType: ComponentTypes.Dropdown,
      rules: [
        {
          next: 'question:number_of_dependents',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
      ],
    },
    {
      questionKey: 'question:number_of_dependents',
      dataPointKey: 'data_point:number_of_dependents:number',
      symphonyMapping: 'wealthInformation.numberOfDependents',
      componentType: ComponentTypes.Input,
      rules: [
        {
          next: 'question:resident_type',
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
      ],
    },
    {
      questionKey: 'question:resident_type',
      dataPointKey: 'data_point:resident_type:single_option',
      symphonyMapping: 'party.partyPerson.residentType',
      defaultValue: '1',
      componentType: ComponentTypes.Dropdown,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: 'data_point:resident_type:single_option',
              value: '2',
            },
          ],
          next: 'question:citizenship',
        },
        {
          next: null,
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
      ],
    },
    {
      questionKey: 'question:citizenship',
      cmsKey: 'countries',
      dataPointKey: 'data_point:citizenship:single_option',
      symphonyMapping: 'party.partyPerson.citizenship',
      componentType: ComponentTypes.Dropdown,
      rules: [
        {
          next: null,
        },
      ],
      validations: [
        {
          name: 'required',
          value: true,
        },
      ],
    },
  ];
  if (accountType === FinancialAccountType.JOINT) {
    orderSteps.push({
      questionKey: 'question:same_home_mailing_address',
      dataPointKey: 'data_point:same_home_mailing_address:boolean',
      symphonyMapping: 'isHomeAddressDerivedFromPrimary',
      defaultValue: true,
      componentType: ComponentTypes.Checkbox,
      rules: [
        {
          conditions: [
            {
              type: 'EQUAL',
              dataPointKey: 'data_point:same_home_mailing_address:boolean',
              value: false,
            },
          ],
          next: 'question:street_address',
        },
        {
          next: 'question:marital_status',
        },
      ],
    } as any);
  }
  return orderSteps;
};

const getAdditionalPaperwork = (
  index: number,
  accountType: FinancialAccountType,
  heading: string,
  paperworkType: PaperworkType,
): PaperworkPage => ({
  accountType,
  paperworkType,
  index,
  trustProfileType: accountType === FinancialAccountType.TRUST ? TrustProfileType.TRUSTEE : undefined,
  sections: [
    {
      index: 0,
      number: 1,
      heading,
      questions: {
        order: {
          start: 'question:first_name',
          orderSteps: getCoApplicantStepsFirstSection(accountType, paperworkType),
        },
      },
    },
    {
      index: 1,
      number: 2,
      heading: 'id_employment',
      questions: {
        order: {
          start: 'question:identification',
          orderSteps: [
            {
              questionKey: 'question:identification',
              dataPointKey: 'label:identification:string',
              symphonyMapping: '',
              componentType: ComponentTypes.Label,
              rules: [
                {
                  next: 'question:identification_type',
                },
              ],
            },
            {
              questionKey: 'question:identification_type',
              dataPointKey: 'data-point:identification_type:single_option',
              symphonyMapping: 'party.identifiers.type',
              componentType: ComponentTypes.Dropdown,
              defaultValue: 'PASSPORT',
              doNotApplyDefaultValue: true,
              rules: [
                {
                  conditions: [
                    {
                      type: 'EQUAL',
                      dataPointKey: 'data-point:identification_type:single_option',
                      value: 'DRIVING_LICENSE',
                    },
                  ],
                  next: 'question:driving_license_state',
                },
                {
                  conditions: [
                    {
                      type: 'EQUAL',
                      dataPointKey: 'data-point:identification_type:single_option',
                      value: 'PASSPORT',
                    },
                  ],
                  next: 'question:passport_country',
                },
                {
                  next: 'question:employment',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:driving_license_state',
              cmsKey: 'states',
              dataPointKey: 'data-point:driving_license_state:single_option',
              symphonyMapping: 'party.identifiers.identifierJurisdiction:driving_license',
              componentType: ComponentTypes.Dropdown,
              rules: [
                {
                  next: 'question:driving_license_identification_number',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:driving_license_identification_number',
              dataPointKey: 'data_point:driving_license_identification_number:string',
              symphonyMapping: 'party.identifiers.identifierValue:driving_license',
              componentType: ComponentTypes.Input,
              rules: [
                {
                  next: 'question:driving_license_issue_date',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
                {
                  name: 'alphaNumericOnly',
                  value: true,
                },
                {
                  name: 'maxLength',
                  value: 30,
                },
              ],
              formats: [
                {
                  name: 'capitalize',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:driving_license_issue_date',
              dataPointKey: 'data_point:driving_license_issue_date:date',
              symphonyMapping: 'party.identifiers.identifierIssuance:driving_license',
              adjacent: 'question:driving_license_expiry_date',
              componentType: ComponentTypes.Date,
              rules: [
                {
                  next: 'question:driving_license_expiry_date',
                },
              ],
              validations: [
                {
                  name: 'future_disabled',
                  value: true,
                },
                {
                  name: 'required',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:driving_license_expiry_date',
              dataPointKey: 'data_point:driving_license_expiry_date:date',
              symphonyMapping: 'party.identifiers.identifierExpiry:driving_license',
              componentType: ComponentTypes.Date,
              rules: [
                {
                  next: null,
                },
              ],
              validations: [
                {
                  name: 'past_disabled',
                  value: true,
                },
                {
                  name: 'required',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:passport_country',
              cmsKey: 'countries',
              dataPointKey: 'data-point:passport_country:single_option',
              symphonyMapping: 'party.identifiers.identifierCountry:passport',
              componentType: ComponentTypes.Dropdown,
              rules: [
                {
                  next: 'question:passport_identification_number',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:passport_identification_number',
              dataPointKey: 'data_point:passport_identification_number:string',
              symphonyMapping: 'party.identifiers.identifierValue:passport',
              componentType: ComponentTypes.Input,
              rules: [
                {
                  next: 'question:passport_issue_date',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
                {
                  name: 'alphaNumericOnly',
                  value: true,
                },
                {
                  name: 'maxLength',
                  value: 9,
                },
              ],
              formats: [
                {
                  name: 'capitalize',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:passport_issue_date',
              dataPointKey: 'data_point:passport_issue_date:date',
              symphonyMapping: 'party.identifiers.identifierIssuance:passport',
              componentType: ComponentTypes.Date,
              adjacent: 'question:passport_expiry_date',
              rules: [
                {
                  next: 'question:passport_expiry_date',
                },
              ],
              validations: [
                {
                  name: 'future_disabled',
                  value: true,
                },
                {
                  name: 'required',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:passport_expiry_date',
              dataPointKey: 'data_point:passport_expiry_date:date',
              symphonyMapping: 'party.identifiers.identifierExpiry:passport',
              componentType: ComponentTypes.Date,
              rules: [
                {
                  next: null,
                },
              ],
              validations: [
                {
                  name: 'past_disabled',
                  value: true,
                },
                {
                  name: 'required',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:employment',
              dataPointKey: 'label:employment:string',
              symphonyMapping: '',
              componentType: ComponentTypes.Label,
              rules: [
                {
                  next: 'question:employment_status',
                },
              ],
            },
            {
              questionKey: 'question:employment_status',
              dataPointKey: 'data_point:employment_status:single_option',
              symphonyMapping: 'party.partyPerson.employmentStatus',
              componentType: ComponentTypes.Dropdown,
              rules: [
                {
                  conditions: [
                    {
                      type: 'EQUAL',
                      dataPointKey: 'data_point:employment_status:single_option',
                      value: 'EMPLOYED',
                    },
                  ],
                  next: 'question:company_name',
                },
                {
                  conditions: [
                    {
                      type: 'EQUAL',
                      dataPointKey: 'data_point:employment_status:single_option',
                      value: 'SELF_EMPLOYED',
                    },
                  ],
                  next: 'question:job_title_self_employed',
                },
                {
                  next: 'question:section_justification',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:company_name',
              dataPointKey: 'data_point:company_name:string',
              symphonyMapping: 'party.partyCompany.organizationName',
              componentType: ComponentTypes.Input,
              rules: [
                {
                  next: 'question:job_title',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
                {
                  name: 'maxLength',
                  value: 32,
                },
              ],
            },
            {
              questionKey: 'question:job_title',
              dataPointKey: 'data_point:job_title:string',
              symphonyMapping: 'party.partyCompany.designation',
              componentType: ComponentTypes.Input,
              rules: [
                {
                  next: 'question:company_street_address',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
                {
                  name: 'maxLength',
                  value: 32,
                },
              ],
            },
            {
              questionKey: 'question:company_street_address',
              dataPointKey: 'data_point:company_street_address:string',
              symphonyMapping: 'party.addresses.addressLine1:office',
              componentType: ComponentTypes.Input,
              rules: [
                {
                  next: 'question:company_street_line_2',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
                {
                  name: 'maxLength',
                  value: 32,
                },
              ],
            },
            {
              questionKey: 'question:company_street_line_2',
              dataPointKey: 'data_point:company_street_line_2:string',
              symphonyMapping: 'party.addresses.addressLine2:office',
              componentType: ComponentTypes.Input,
              rules: [
                {
                  next: 'question:company_city',
                },
              ],
              validations: [
                {
                  name: 'maxLength',
                  value: 32,
                },
              ],
            },
            {
              questionKey: 'question:company_city',
              dataPointKey: 'data_point:company_city:string',
              symphonyMapping: 'party.addresses.countrySecondarySubdivision:office',
              componentType: ComponentTypes.Input,
              rules: [
                {
                  next: 'question:company_state',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
                {
                  name: 'maxLength',
                  value: 23,
                },
              ],
            },
            {
              questionKey: 'question:company_state',
              cmsKey: 'states',
              dataPointKey: 'data_point:company_state:single_option',
              symphonyMapping: 'party.addresses.countryPrimarySubdivision:office',
              componentType: ComponentTypes.Dropdown,
              adjacent: 'question:company_zip_code',
              rules: [
                {
                  next: 'question:company_zip_code',
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:company_zip_code',
              dataPointKey: 'data_point:company_zip_code:number',
              symphonyMapping: 'party.addresses.postalCode:office',
              componentType: ComponentTypes.Input,
              rules: [
                {
                  next: null,
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
                {
                  name: 'length',
                  value: 5,
                },
                {
                  name: 'maxLength',
                  value: 5,
                },
                {
                  name: 'validZipCode',
                  value: 'data_point:company_state:single_option',
                },
              ],
            },
            {
              questionKey: 'question:job_title_self_employed',
              dataPointKey: 'data_point:job_title_self_employed:string',
              symphonyMapping: 'party.partyCompany.designation',
              componentType: ComponentTypes.Input,
              rules: [
                {
                  next: null,
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
                {
                  name: 'maxLength',
                  value: 32,
                },
              ],
            },
            {
              questionKey: 'question:section_justification',
              dataPointKey: 'info:section_justification:string',
              symphonyMapping: '',
              componentType: ComponentTypes.Info,
              rules: [
                {
                  next: null,
                },
              ],
            },
          ],
        },
      },
    },
    {
      index: 2,
      number: 3,
      heading: 'regulatory',
      questions: {
        order: {
          start: 'question:regulatory',
          orderSteps: [
            {
              questionKey: 'question:regulatory',
              dataPointKey: 'custom:regulatory',
              symphonyMapping: '',
              componentType: ComponentTypes.Regulatory,
              rules: [
                {
                  next: 'question:csi_label',
                },
              ],
            },
            {
              questionKey: 'question:csi_label',
              dataPointKey: 'label:csi_label:string',
              symphonyMapping: '',
              componentType: ComponentTypes.Label,
              rules: [
                {
                  next: 'question:csi',
                },
              ],
            },
            {
              questionKey: 'question:csi',
              dataPointKey: 'data_point:csi:boolean',
              symphonyMapping: 'regulatoryInformation.hasPartnerEmployeeConnection',
              componentType: ComponentTypes.Checkbox,
              defaultValue: false,
              rules: [
                {
                  next: 'question:investment_restrictions',
                },
              ],
            },
            {
              questionKey: 'question:investment_restrictions',
              dataPointKey: 'custom:investment_regulations',
              symphonyMapping: '',
              componentType: ComponentTypes.InvestmentRestrictions,
              rules: [
                {
                  next: 'question:trust_contact_label',
                },
              ],
            },
            {
              questionKey: 'question:trust_contact_label',
              dataPointKey: 'label:trust_contact_label:string',
              symphonyMapping: '',
              componentType: ComponentTypes.Label,
              rules: [
                {
                  next: 'question:designate_trusted_contact',
                },
              ],
            },
            {
              questionKey: 'question:designate_trusted_contact',
              dataPointKey: 'data_point:designate_trusted_contact:single_option',
              symphonyMapping: '',
              componentType: ComponentTypes.Radio,
              defaultValue: 'data_point:designate_trusted_contact:single_option_no',
              rules: [
                {
                  conditions: [
                    {
                      type: 'EQUAL',
                      dataPointKey: 'data_point:designate_trusted_contact:single_option',
                      value: 'data_point:designate_trusted_contact:single_option_yes',
                    },
                  ],
                  next: 'question:trusted_contact',
                },
                {
                  next: null,
                },
              ],
              validations: [
                {
                  name: 'required',
                  value: true,
                },
              ],
            },
            {
              questionKey: 'question:trusted_contact',
              dataPointKey: 'custom:trusted_contact:contacts',
              symphonyMapping: '',
              componentType: ComponentTypes.Contacts,
              rules: [
                {
                  next: null,
                },
              ],
              validations: [
                {
                  name: 'max',
                  value: 2,
                },
                {
                  name: 'min',
                  value: 1,
                },
              ],
            },
          ],
        },
      },
    },
  ],
});

export const paperworkData: PaperworkData = {
  pages: [
    {
      index: 0,
      sections: [
        {
          index: 0,
          number: 1,
          heading: 'about_you',
          questions: {
            order: {
              start: 'question:first_name',
              orderSteps: [
                {
                  questionKey: 'question:first_name',
                  dataPointKey: 'data_point:first_name:string',
                  symphonyMapping: 'party.partyPerson.givenName',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:middle_name',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'invalidCharactersRegex',
                      value: /[^a-zA-Z -]/g,
                    },
                    {
                      name: 'regex',
                      value: /^[A-Za-z -]+$/,
                    },
                    {
                      name: 'maxLength',
                      value: 25,
                    },
                  ],
                },
                {
                  questionKey: 'question:middle_name',
                  dataPointKey: 'data_point:middle_name:string',
                  symphonyMapping: 'party.partyPerson.middleName',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:last_name',
                    },
                  ],
                  validations: [
                    {
                      name: 'invalidCharactersRegex',
                      value: /[^a-zA-Z ]/g,
                    },
                    {
                      name: 'regex',
                      value: /^[A-Za-z ]+$/,
                    },
                    {
                      name: 'maxLength',
                      value: 10,
                    },
                  ],
                },
                {
                  questionKey: 'question:last_name',
                  dataPointKey: 'data_point:last_name:string',
                  symphonyMapping: 'party.partyPerson.familyName',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:email',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'invalidCharactersRegex',
                      value: /[^a-zA-Z -]/g,
                    },
                    {
                      name: 'regex',
                      value: /^[A-Za-z -]+$/,
                    },
                    {
                      name: 'maxLength',
                      value: 25,
                    },
                  ],
                },
                {
                  questionKey: 'question:email',
                  dataPointKey: 'data_point:email:email',
                  symphonyMapping: 'party.partyContacts.contact:email',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:confirm_email',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 80,
                    },
                  ],
                },
                {
                  questionKey: 'question:confirm_email',
                  dataPointKey: 'data_point:confirm_email:email',
                  symphonyMapping: 'party.partyContacts.contact:email',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:email_consent',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 80,
                    },
                  ],
                },
                {
                  questionKey: 'question:email_consent',
                  dataPointKey: 'info:email_consent:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Info,
                  rules: [
                    {
                      next: 'question:phone_number',
                    },
                  ],
                },
                {
                  questionKey: 'question:phone_number',
                  dataPointKey: 'data_point:phone_number:number',
                  symphonyMapping: 'party.partyContacts.contact:mobile',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:birth_date',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'length',
                      value: 10,
                    },
                    {
                      name: 'maxLength',
                      value: 10,
                    },
                  ],
                },
                {
                  questionKey: 'question:birth_date',
                  dataPointKey: 'data_point:birth_date:date',
                  symphonyMapping: 'party.partyPerson.birthDate',
                  componentType: ComponentTypes.Date,
                  adjacent: 'question:ssn',
                  rules: [
                    {
                      next: 'question:ssn',
                    },
                  ],
                  validations: [
                    {
                      name: 'future_disabled',
                      value: true,
                    },
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'minor_age',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:ssn',
                  dataPointKey: 'data_point:ssn:ssn',
                  symphonyMapping: 'party.identifiers.identifierValue:ssn',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:street_address',
                    },
                  ],
                  formats: [
                    {
                      name: 'masked',
                      value: {
                        charsToShow: 4,
                        maskChar: '*',
                      },
                    },
                  ],
                  validations: [
                    {
                      name: 'disabled',
                      value: true,
                    },
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'length',
                      value: 9,
                    },
                    {
                      name: 'maxLength',
                      value: 9,
                    },
                  ],
                },
                {
                  questionKey: 'question:street_address',
                  dataPointKey: 'data_point:street_address:string',
                  symphonyMapping: 'party.addresses.addressLine1:home',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:street_line_2',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 32,
                    },
                  ],
                },
                {
                  questionKey: 'question:street_line_2',
                  dataPointKey: 'data_point:street_line_2:string',
                  symphonyMapping: 'party.addresses.addressLine2:home',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:city',
                    },
                  ],
                  validations: [
                    {
                      name: 'maxLength',
                      value: 32,
                    },
                  ],
                },
                {
                  questionKey: 'question:city',
                  dataPointKey: 'data_point:city:string',
                  symphonyMapping: 'party.addresses.countrySecondarySubdivision:home',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:state',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 30,
                    },
                  ],
                },
                {
                  questionKey: 'question:state',
                  cmsKey: 'states',
                  dataPointKey: 'data_point:state:single_option',
                  symphonyMapping: 'party.addresses.countryPrimarySubdivision:home',
                  componentType: ComponentTypes.Dropdown,
                  adjacent: 'question:zip_code',
                  rules: [
                    {
                      next: 'question:zip_code',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:zip_code',
                  dataPointKey: 'data_point:zip_code:number',
                  symphonyMapping: 'party.addresses.postalCode:home',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:different_mailing_address',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'length',
                      value: 5,
                    },
                    {
                      name: 'maxLength',
                      value: 5,
                    },
                    {
                      name: 'validZipCode',
                      value: 'data_point:state:single_option',
                    },
                  ],
                },
                {
                  questionKey: 'question:different_mailing_address',
                  dataPointKey: 'data_point:different_mailing_address:boolean',
                  symphonyMapping: 'isMailingAddressSameAsHomeAddress',
                  componentType: ComponentTypes.Checkbox,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:different_mailing_address:boolean',
                          value: true,
                        },
                      ],
                      next: 'question:mailing_street_address',
                    },
                    {
                      next: 'question:marital_status',
                    },
                  ],
                },
                {
                  questionKey: 'question:mailing_street_address',
                  dataPointKey: 'data_point:mailing_street_address:string',
                  symphonyMapping: 'party.addresses.addressLine1:mailing',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:mailing_street_line_2',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 50,
                    },
                  ],
                },
                {
                  questionKey: 'question:mailing_street_line_2',
                  dataPointKey: 'data_point:mailing_street_line_2:string',
                  symphonyMapping: 'party.addresses.addressLine2:mailing',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:mailing_city',
                    },
                  ],
                  validations: [
                    {
                      name: 'maxLength',
                      value: 50,
                    },
                  ],
                },
                {
                  questionKey: 'question:mailing_city',
                  dataPointKey: 'data_point:mailing_city:string',
                  symphonyMapping: 'party.addresses.countrySecondarySubdivision:mailing',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:mailing_state',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 30,
                    },
                  ],
                },
                {
                  questionKey: 'question:mailing_state',
                  cmsKey: 'states',
                  dataPointKey: 'data_point:mailing_state:single_option',
                  symphonyMapping: 'party.addresses.countryPrimarySubdivision:mailing',
                  componentType: ComponentTypes.Dropdown,
                  adjacent: 'question:mailing_zip_code',
                  rules: [
                    {
                      next: 'question:mailing_zip_code',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:mailing_zip_code',
                  dataPointKey: 'data_point:mailing_zip_code:number',
                  symphonyMapping: 'party.addresses.postalCode:mailing',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'length',
                      value: 5,
                    },
                    {
                      name: 'maxLength',
                      value: 5,
                    },
                    {
                      name: 'validZipCode',
                      value: 'data_point:mailing_state:single_option',
                    },
                  ],
                },
                {
                  questionKey: 'question:marital_status',
                  dataPointKey: 'data_point:marital_status:single_option',
                  symphonyMapping: 'party.partyPerson.maritalStatus',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:number_of_dependents',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:number_of_dependents',
                  dataPointKey: 'data_point:number_of_dependents:number',
                  symphonyMapping: 'wealthInformation.numberOfDependents',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:resident_type',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:resident_type',
                  dataPointKey: 'data_point:resident_type:single_option',
                  symphonyMapping: 'party.partyPerson.residentType',
                  defaultValue: '1',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:resident_type:single_option',
                          value: '2',
                        },
                      ],
                      next: 'question:citizenship',
                    },
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:citizenship',
                  cmsKey: 'countries',
                  dataPointKey: 'data_point:citizenship:single_option',
                  symphonyMapping: 'party.partyPerson.citizenship',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          index: 1,
          number: 2,
          heading: 'id_employment',
          questions: {
            order: {
              start: 'question:identification',
              orderSteps: [
                {
                  questionKey: 'question:identification',
                  dataPointKey: 'label:identification:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Label,
                  rules: [
                    {
                      next: 'question:identification_type',
                    },
                  ],
                },
                {
                  questionKey: 'question:identification_type',
                  dataPointKey: 'data-point:identification_type:single_option',
                  symphonyMapping: 'party.identifiers.type',
                  componentType: ComponentTypes.Dropdown,
                  defaultValue: 'PASSPORT',
                  doNotApplyDefaultValue: true,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data-point:identification_type:single_option',
                          value: 'DRIVING_LICENSE',
                        },
                      ],
                      next: 'question:driving_license_state',
                    },
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data-point:identification_type:single_option',
                          value: 'PASSPORT',
                        },
                      ],
                      next: 'question:passport_country',
                    },
                    {
                      next: 'question:employment',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:driving_license_state',
                  cmsKey: 'states',
                  dataPointKey: 'data-point:driving_license_state:single_option',
                  symphonyMapping: 'party.identifiers.identifierJurisdiction:driving_license',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:driving_license_identification_number',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:driving_license_identification_number',
                  dataPointKey: 'data_point:driving_license_identification_number:string',
                  symphonyMapping: 'party.identifiers.identifierValue:driving_license',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:driving_license_issue_date',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'alphaNumericOnly',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 30,
                    },
                  ],
                  formats: [
                    {
                      name: 'capitalize',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:driving_license_issue_date',
                  dataPointKey: 'data_point:driving_license_issue_date:date',
                  symphonyMapping: 'party.identifiers.identifierIssuance:driving_license',
                  adjacent: 'question:driving_license_expiry_date',
                  componentType: ComponentTypes.Date,
                  rules: [
                    {
                      next: 'question:driving_license_expiry_date',
                    },
                  ],
                  validations: [
                    {
                      name: 'future_disabled',
                      value: true,
                    },
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:driving_license_expiry_date',
                  dataPointKey: 'data_point:driving_license_expiry_date:date',
                  symphonyMapping: 'party.identifiers.identifierExpiry:driving_license',
                  componentType: ComponentTypes.Date,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'past_disabled',
                      value: true,
                    },
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:passport_country',
                  cmsKey: 'countries',
                  dataPointKey: 'data-point:passport_country:single_option',
                  symphonyMapping: 'party.identifiers.identifierCountry:passport',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:passport_identification_number',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:passport_identification_number',
                  dataPointKey: 'data_point:passport_identification_number:string',
                  symphonyMapping: 'party.identifiers.identifierValue:passport',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:passport_issue_date',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'alphaNumericOnly',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 9,
                    },
                  ],
                  formats: [
                    {
                      name: 'capitalize',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:passport_issue_date',
                  dataPointKey: 'data_point:passport_issue_date:date',
                  symphonyMapping: 'party.identifiers.identifierIssuance:passport',
                  componentType: ComponentTypes.Date,
                  adjacent: 'question:passport_expiry_date',
                  rules: [
                    {
                      next: 'question:passport_expiry_date',
                    },
                  ],
                  validations: [
                    {
                      name: 'future_disabled',
                      value: true,
                    },
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:passport_expiry_date',
                  dataPointKey: 'data_point:passport_expiry_date:date',
                  symphonyMapping: 'party.identifiers.identifierExpiry:passport',
                  componentType: ComponentTypes.Date,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'past_disabled',
                      value: true,
                    },
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:employment',
                  dataPointKey: 'label:employment:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Label,
                  rules: [
                    {
                      next: 'question:employment_status',
                    },
                  ],
                },
                {
                  questionKey: 'question:employment_status',
                  dataPointKey: 'data_point:employment_status:single_option',
                  symphonyMapping: 'party.partyPerson.employmentStatus',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:employment_status:single_option',
                          value: 'EMPLOYED',
                        },
                      ],
                      next: 'question:company_name',
                    },
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:employment_status:single_option',
                          value: 'SELF_EMPLOYED',
                        },
                      ],
                      next: 'question:job_title_self_employed',
                    },
                    {
                      next: 'question:section_justification',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:company_name',
                  dataPointKey: 'data_point:company_name:string',
                  symphonyMapping: 'party.partyCompany.organizationName',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:job_title',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 32,
                    },
                  ],
                },
                {
                  questionKey: 'question:job_title',
                  dataPointKey: 'data_point:job_title:string',
                  symphonyMapping: 'party.partyCompany.designation',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:company_street_address',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 32,
                    },
                  ],
                },
                {
                  questionKey: 'question:company_street_address',
                  dataPointKey: 'data_point:company_street_address:string',
                  symphonyMapping: 'party.addresses.addressLine1:office',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:company_street_line_2',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 32,
                    },
                  ],
                },
                {
                  questionKey: 'question:company_street_line_2',
                  dataPointKey: 'data_point:company_street_line_2:string',
                  symphonyMapping: 'party.addresses.addressLine2:office',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:company_city',
                    },
                  ],
                  validations: [
                    {
                      name: 'maxLength',
                      value: 32,
                    },
                  ],
                },
                {
                  questionKey: 'question:company_city',
                  dataPointKey: 'data_point:company_city:string',
                  symphonyMapping: 'party.addresses.countrySecondarySubdivision:office',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:company_state',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 23,
                    },
                  ],
                },
                {
                  questionKey: 'question:company_state',
                  cmsKey: 'states',
                  dataPointKey: 'data_point:company_state:single_option',
                  symphonyMapping: 'party.addresses.countryPrimarySubdivision:office',
                  componentType: ComponentTypes.Dropdown,
                  adjacent: 'question:company_zip_code',
                  rules: [
                    {
                      next: 'question:company_zip_code',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:company_zip_code',
                  dataPointKey: 'data_point:company_zip_code:number',
                  symphonyMapping: 'party.addresses.postalCode:office',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'length',
                      value: 5,
                    },
                    {
                      name: 'maxLength',
                      value: 5,
                    },
                    {
                      name: 'validZipCode',
                      value: 'data_point:company_state:single_option',
                    },
                  ],
                },
                {
                  questionKey: 'question:job_title_self_employed',
                  dataPointKey: 'data_point:job_title_self_employed:string',
                  symphonyMapping: 'party.partyCompany.designation',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 32,
                    },
                  ],
                },
                {
                  questionKey: 'question:section_justification',
                  dataPointKey: 'info:section_justification:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Info,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          index: 2,
          number: 3,
          heading: 'beneficiaries_contacts',
          questions: {
            order: {
              start: 'question:designate_primary_beneficiary',
              orderSteps: [
                {
                  questionKey: 'question:designate_primary_beneficiary',
                  dataPointKey: 'data_point:designate_primary_beneficiary:single_option',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Radio,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:designate_primary_beneficiary:single_option',
                          value: 'data_point:designate_primary_beneficiary:single_option_yes',
                        },
                      ],
                      next: 'question:beneficiary_primary',
                    },
                    {
                      next: 'question:trust_contact_label',
                    },
                  ],
                },
                {
                  questionKey: 'question:beneficiary_primary',
                  dataPointKey: 'custom:primary_beneficiaries:beneficiaries',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Beneficiary,
                  rules: [
                    {
                      next: 'question:beneficiary',
                    },
                  ],
                  validations: [
                    {
                      name: 'max',
                      value: 4,
                    },
                    {
                      name: 'min',
                      value: 1,
                    },
                  ],
                },
                {
                  questionKey: 'question:beneficiary',
                  dataPointKey: 'custom:contingent_beneficiaries:beneficiaries',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Beneficiary,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'max',
                      value: 6,
                    },
                    {
                      name: 'min',
                      value: 0,
                    },
                  ],
                },
                {
                  questionKey: 'question:trust_contact_label',
                  dataPointKey: 'label:trust_contact_label:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Label,
                  rules: [
                    {
                      next: 'question:designate_trusted_contact',
                    },
                  ],
                },
                {
                  questionKey: 'question:designate_trusted_contact',
                  dataPointKey: 'data_point:designate_trusted_contact:single_option',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Radio,
                  defaultValue: 'data_point:designate_trusted_contact:single_option_no',
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:designate_trusted_contact:single_option',
                          value: 'data_point:designate_trusted_contact:single_option_yes',
                        },
                      ],
                      next: 'question:trusted_contact',
                    },
                    {
                      next: 'question:original_depositor',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:trusted_contact',
                  dataPointKey: 'custom:trusted_contact:contacts',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Contacts,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'max',
                      value: 2,
                    },
                    {
                      name: 'min',
                      value: 1,
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor',
                  dataPointKey: 'label:original_depositor:string',
                  symphonyMapping: '',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Label,
                  rules: [
                    {
                      next: 'question:original_depositor_text',
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor_text',
                  dataPointKey: 'text:original_depositor_text:string',
                  symphonyMapping: '',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Text,
                  rules: [
                    {
                      next: 'question:original_depositor_first_name',
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor_first_name',
                  dataPointKey: 'data_point:original_depositor_first_name:string',
                  symphonyMapping: 'relationships:decedent:party.partyPerson.givenName',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:original_depositor_middle_name',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'invalidCharactersRegex',
                      value: /[^a-zA-Z -]/g,
                    },
                    {
                      name: 'regex',
                      value: /^[A-Za-z -]+$/,
                    },
                    {
                      name: 'maxLength',
                      value: 25,
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor_middle_name',
                  dataPointKey: 'data_point:original_depositor_middle_name:string',
                  symphonyMapping: 'relationships:decedent:party.partyPerson.middleName',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:original_depositor_last_name',
                    },
                  ],
                  validations: [
                    {
                      name: 'invalidCharactersRegex',
                      value: /[^a-zA-Z ]/g,
                    },
                    {
                      name: 'regex',
                      value: /^[A-Za-z ]+$/,
                    },
                    {
                      name: 'maxLength',
                      value: 10,
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor_last_name',
                  dataPointKey: 'data_point:original_depositor_last_name:string',
                  symphonyMapping: 'relationships:decedent:party.partyPerson.familyName',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:original_depositor_relationship',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'invalidCharactersRegex',
                      value: /[^a-zA-Z -]/g,
                    },
                    {
                      name: 'regex',
                      value: /^[A-Za-z -]+$/,
                    },
                    {
                      name: 'maxLength',
                      value: 25,
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor_relationship',
                  dataPointKey: 'data_point:original_depositor_relationship:single_option',
                  symphonyMapping: 'relationships:decedent:relationshipType',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Dropdown,
                  adjacent: 'question:original_depositor_ssn',
                  rules: [
                    {
                      next: 'question:original_depositor_ssn',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor_ssn',
                  dataPointKey: 'data_point:original_depositor_ssn:ssn',
                  symphonyMapping: 'relationships:decedent:party.identifiers.identifierValue:ssn',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:original_depositor_birth_date',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'length',
                      value: 9,
                    },
                    {
                      name: 'maxLength',
                      value: 9,
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor_birth_date',
                  dataPointKey: 'data_point:original_depositor_birth_date:date',
                  symphonyMapping: 'relationships:decedent:party.partyPerson.birthDate',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Date,
                  adjacent: 'question:original_depositor_death_date',
                  rules: [
                    {
                      next: 'question:original_depositor_death_date',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'future_disabled',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor_death_date',
                  dataPointKey: 'data_point:original_depositor_death_date:date',
                  symphonyMapping: 'relationships:decedent:party.partyPerson.deathDate',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Date,
                  rules: [
                    {
                      next: 'question:original_depositor_resident_type',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'future_disabled',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor_resident_type',
                  dataPointKey: 'data_point:original_depositor_resident_type:single_option',
                  symphonyMapping: 'relationships:decedent:party.partyPerson.residentType',
                  defaultValue: '1',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:original_depositor_resident_type:single_option',
                          value: '2',
                        },
                      ],
                      next: 'question:original_depositor_citizenship',
                    },
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:original_depositor_citizenship',
                  dataPointKey: 'data_point:original_depositor_citizenship:string',
                  symphonyMapping: 'relationships:decedent:party.partyPerson.citizenship',
                  cmsKey: 'countries',
                  accountTypes: InheritedIRAAccountTypes,
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
    {
      index: 1,
      consent: {
        consentRequired: true,
        defaultChecked: true,
      },
      sections: [
        {
          index: 0,
          number: 4,
          heading: 'wealth_expenses',
          questions: {
            order: {
              start: 'question:tax_bracket',
              orderSteps: [
                {
                  questionKey: 'question:tax_bracket',
                  dataPointKey: 'data_point:tax_bracket:single_option',
                  symphonyMapping: 'wealthInformation.taxBracket',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:annual_household_income',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:annual_household_income',
                  dataPointKey: 'data_point:annual_household_income:float',
                  symphonyMapping: 'wealthInformation.annualIncome',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:annual_recurring_expenses',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:annual_recurring_expenses',
                  dataPointKey: 'data_point:annual_recurring_expenses:single_option',
                  symphonyMapping: 'wealthInformation.annualRecurringExpenses',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:non_recurring_expenses',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:non_recurring_expenses',
                  dataPointKey: 'data_point:non_recurring_expenses:float',
                  symphonyMapping: 'wealthInformation.specialExpenses',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:adequate_liquidity',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:adequate_liquidity',
                  dataPointKey: 'data_point:adequate_liquidity:boolean',
                  symphonyMapping: 'wealthInformation.hasEmergencyFunds',
                  componentType: ComponentTypes.Checkbox,
                  rules: [
                    {
                      next: 'question:primary_funding_source',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:primary_funding_source',
                  dataPointKey: 'data_point:primary_funding_source:single_option',
                  symphonyMapping: 'wealthInformation.sourceOfInitialFunds',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:cash',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:cash',
                  dataPointKey: 'data_point:cash:number',
                  symphonyMapping: 'wealthInformation.cash',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:tangible_assets',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:tangible_assets',
                  dataPointKey: 'data_point:tangible_assets:number',
                  symphonyMapping: 'wealthInformation.nonLiquidAssets',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:liabilities',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:liabilities',
                  dataPointKey: 'data_point:liabilities:number',
                  symphonyMapping: 'wealthInformation.liabilities',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:net_worth_calculation',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:net_worth_calculation',
                  dataPointKey: 'info:net_worth_calculation:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Info,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          index: 1,
          number: 5,
          heading: 'investments',
          questions: {
            order: {
              start: 'question:investment_goals_text',
              orderSteps: [
                {
                  questionKey: 'question:investment_goals_text',
                  dataPointKey: 'text:investment_goals_text:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Text,
                  rules: [
                    {
                      next: 'question:investment_objective',
                    },
                  ],
                },
                {
                  questionKey: 'question:investment_objective',
                  dataPointKey: 'data_point:investment_objective:single_option',
                  symphonyMapping: 'investment.investmentObjective',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:primary_investment_purpose',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:primary_investment_purpose',
                  dataPointKey: 'data_point:primary_investment_purpose:single_option',
                  symphonyMapping: 'investment.accountPurpose',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:investment_experience_text',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:investment_experience_text',
                  dataPointKey: 'text:investment_experience_text:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Text,
                  rules: [
                    {
                      next: 'question:overall_investment_experience',
                    },
                  ],
                },
                {
                  questionKey: 'question:overall_investment_experience',
                  dataPointKey: 'data_point:investment_experience:single_option',
                  symphonyMapping: 'investment.investmentExperience',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:stocks',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:stocks',
                  dataPointKey: 'data_point:stocks:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingStocks',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:stocks:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:stocks_non_qualified',
                    },
                    {
                      next: 'question:bonds',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:stocks_non_qualified',
                  dataPointKey: 'data_point:stocks_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.stocks.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:stocks_qualified',
                  rules: [
                    {
                      next: 'question:stocks_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:stocks_qualified',
                  dataPointKey: 'data_point:stocks_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.stocks.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:bonds',
                  dataPointKey: 'data_point:bonds:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingBonds',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:bonds:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:bonds_non_qualified',
                    },
                    {
                      next: 'question:short_term',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:bonds_non_qualified',
                  dataPointKey: 'data_point:bonds_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.bonds.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:bonds_qualified',
                  rules: [
                    {
                      next: 'question:bonds_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:bonds_qualified',
                  dataPointKey: 'data_point:bonds_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.bonds.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:short_term',
                  dataPointKey: 'data_point:short_term:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingOptions',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:short_term:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:short_term_non_qualified',
                    },
                    {
                      next: 'question:mutual_funds',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:short_term_non_qualified',
                  dataPointKey: 'data_point:short_term_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.options.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:short_term_qualified',
                  rules: [
                    {
                      next: 'question:short_term_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:short_term_qualified',
                  dataPointKey: 'data_point:short_term_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.options.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:mutual_funds',
                  dataPointKey: 'data_point:mutual_funds:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingMutualFunds',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:mutual_funds:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:mutual_funds_non_qualified',
                    },
                    {
                      next: 'question:variable_contracts',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:mutual_funds_non_qualified',
                  dataPointKey: 'data_point:mutual_funds_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.mutualFunds.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:mutual_funds_qualified',
                  rules: [
                    {
                      next: 'question:mutual_funds_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:mutual_funds_qualified',
                  dataPointKey: 'data_point:mutual_funds_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.mutualFunds.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:variable_contracts',
                  dataPointKey: 'data_point:variable_contracts:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingVariableContracts',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:variable_contracts:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:variable_contracts_non_qualified',
                    },
                    {
                      next: 'question:annuities',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:variable_contracts_non_qualified',
                  dataPointKey: 'data_point:variable_contracts_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.variableContracts.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:variable_contracts_qualified',
                  rules: [
                    {
                      next: 'question:variable_contracts_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:variable_contracts_qualified',
                  dataPointKey: 'data_point:variable_contracts_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.variableContracts.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:annuities',
                  dataPointKey: 'data_point:annuities:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingAnnuitiesAndLifeInsurance',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:annuities:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:annuities_non_qualified',
                    },
                    {
                      next: 'question:investment_experience_other',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:annuities_non_qualified',
                  dataPointKey: 'data_point:annuities_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.annuitiesAndLifeInsurance.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:annuities_qualified',
                  rules: [
                    {
                      next: 'question:annuities_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:annuities_qualified',
                  dataPointKey: 'data_point:annuities_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.annuitiesAndLifeInsurance.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:investment_experience_other',
                  dataPointKey: 'data_point:investment_experience_other:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingOther',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:investment_experience_other:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:investment_experience_other_non_qualified',
                    },
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:investment_experience_other_non_qualified',
                  dataPointKey: 'data_point:investment_experience_other_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.other.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:investment_experience_other_qualified',
                  rules: [
                    {
                      next: 'question:investment_experience_other_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:investment_experience_other_qualified',
                  dataPointKey: 'data_point:investment_experience_other_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.other.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                    {
                      name: 'validNetWorth',
                      value: true,
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          index: 2,
          number: 6,
          heading: 'regulatory',
          questions: {
            order: {
              start: 'question:regulatory',
              orderSteps: [
                {
                  questionKey: 'question:regulatory',
                  dataPointKey: 'custom:regulatory',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Regulatory,
                  rules: [
                    {
                      next: 'question:csi_label',
                    },
                  ],
                },
                {
                  questionKey: 'question:csi_label',
                  dataPointKey: 'label:csi_label:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Label,
                  rules: [
                    {
                      next: 'question:csi',
                    },
                  ],
                },
                {
                  questionKey: 'question:csi',
                  dataPointKey: 'data_point:csi:boolean',
                  symphonyMapping: 'regulatoryInformation.hasPartnerEmployeeConnection',
                  componentType: ComponentTypes.Checkbox,
                  defaultValue: false,
                  rules: [
                    {
                      next: 'question:investment_restrictions',
                    },
                  ],
                },
                {
                  questionKey: 'question:investment_restrictions',
                  dataPointKey: 'custom:investment_regulations',
                  symphonyMapping: '',
                  componentType: ComponentTypes.InvestmentRestrictions,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
    getAdditionalPaperwork(2, FinancialAccountType.JOINT, 'about_co_applicant', PaperworkType.SECONDARY),
    {
      index: 0,
      accountType: FinancialAccountType.TRUST,
      paperworkType: PaperworkType.SECONDARY,
      trustProfileType: TrustProfileType.TRUST,
      sections: [
        {
          index: 0,
          number: 1,
          heading: 'about_the_trust',
          questions: {
            order: {
              start: 'question:trust_name',
              orderSteps: [
                {
                  questionKey: 'question:trust_name',
                  dataPointKey: 'data_point:trust_name:string',
                  symphonyMapping: 'trustInformation.trustName',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:trust_date',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'invalidCharactersRegex',
                      value: /[^a-zA-Z\d -]/g,
                    },
                    {
                      name: 'regex',
                      value: /^[A-Za-z\d -]+$/,
                    },
                    {
                      name: 'maxLength',
                      value: 40,
                    },
                  ],
                },
                {
                  questionKey: 'question:trust_date',
                  dataPointKey: 'data_point:trust_date:date',
                  symphonyMapping: 'trustInformation.trustAgreementDate',
                  componentType: ComponentTypes.Date,
                  rules: [
                    {
                      next: 'question:type_of_trust',
                    },
                  ],
                  validations: [
                    {
                      name: 'future_disabled',
                      value: true,
                    },
                    {
                      name: 'today_disabled',
                      value: true,
                    },
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:type_of_trust',
                  dataPointKey: 'data_point:type_of_trust:single_option',
                  symphonyMapping: 'trustInformation.trustType',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:tax_reporting_type',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:tax_reporting_type',
                  dataPointKey: 'data_point:tax_reporting_type:single_option',
                  symphonyMapping: 'trustInformation.trustIdentificationType',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:tax_reporting_number',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:tax_reporting_number',
                  dataPointKey: 'data_point:tax_reporting_number:ssn',
                  symphonyMapping: 'trustInformation.trustIdentificationNumber',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:state_of_trust',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'length',
                      value: 9,
                    },
                    {
                      name: 'maxLength',
                      value: 9,
                    },
                  ],
                },
                {
                  questionKey: 'question:state_of_trust',
                  cmsKey: 'states',
                  dataPointKey: 'data_point:state_of_trust:single_option',
                  symphonyMapping: 'trustInformation.trustGoverningState',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:number_of_trustees',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:number_of_trustees',
                  dataPointKey: 'data_point:number_of_trustees:single_option',
                  symphonyMapping: 'trustInformation.numberOfTrustees',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:type_of_trust:single_option',
                          value: 'REVOCABLE',
                        },
                      ],
                      next: 'question:revocable_primary_trustee',
                    },
                    {
                      next: 'question:street_address',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:revocable_primary_trustee',
                  dataPointKey: 'label:revocable_primary_trustee:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Label,
                  rules: [
                    {
                      next: 'question:first_name',
                    },
                  ],
                },
                {
                  questionKey: 'question:first_name',
                  dataPointKey: 'data_point:revoker_first_name:string',
                  symphonyMapping: 'trustInformation.revokerFirstName',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:middle_name',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'invalidCharactersRegex',
                      value: /[^a-zA-Z -]/g,
                    },
                    {
                      name: 'regex',
                      value: /^[A-Za-z -]+$/,
                    },
                    {
                      name: 'maxLength',
                      value: 25,
                    },
                  ],
                },
                {
                  questionKey: 'question:middle_name',
                  dataPointKey: 'data_point:revoker_middle_name:string',
                  symphonyMapping: 'trustInformation.revokerMiddleName',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:last_name',
                    },
                  ],
                  validations: [
                    {
                      name: 'invalidCharactersRegex',
                      value: /[^a-zA-Z ]/g,
                    },
                    {
                      name: 'regex',
                      value: /^[A-Za-z ]+$/,
                    },
                    {
                      name: 'maxLength',
                      value: 10,
                    },
                  ],
                },
                {
                  questionKey: 'question:last_name',
                  dataPointKey: 'data_point:revoker_last_name:string',
                  symphonyMapping: 'trustInformation.revokerLastName',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'invalidCharactersRegex',
                      value: /[^a-zA-Z -]/g,
                    },
                    {
                      name: 'regex',
                      value: /^[A-Za-z -]+$/,
                    },
                    {
                      name: 'maxLength',
                      value: 25,
                    },
                  ],
                },
                {
                  questionKey: 'question:street_address',
                  dataPointKey: 'data_point:street_address:string',
                  symphonyMapping: 'party.addresses.addressLine1:home',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:street_line_2',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 32,
                    },
                  ],
                },
                {
                  questionKey: 'question:street_line_2',
                  dataPointKey: 'data_point:street_line_2:string',
                  symphonyMapping: 'party.addresses.addressLine2:home',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:city',
                    },
                  ],
                  validations: [
                    {
                      name: 'maxLength',
                      value: 32,
                    },
                  ],
                },
                {
                  questionKey: 'question:city',
                  dataPointKey: 'data_point:city:string',
                  symphonyMapping: 'party.addresses.countrySecondarySubdivision:home',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:state',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 30,
                    },
                  ],
                },
                {
                  questionKey: 'question:state',
                  cmsKey: 'states',
                  dataPointKey: 'data_point:state:single_option',
                  symphonyMapping: 'party.addresses.countryPrimarySubdivision:home',
                  componentType: ComponentTypes.Dropdown,
                  adjacent: 'question:zip_code',
                  rules: [
                    {
                      next: 'question:zip_code',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:zip_code',
                  dataPointKey: 'data_point:zip_code:number',
                  symphonyMapping: 'party.addresses.postalCode:home',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'length',
                      value: 5,
                    },
                    {
                      name: 'maxLength',
                      value: 5,
                    },
                    {
                      name: 'validZipCode',
                      value: 'data_point:state:single_option',
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          index: 1,
          number: 2,
          heading: 'investments',
          questions: {
            order: {
              start: 'question:investment_goals_text',
              orderSteps: [
                {
                  questionKey: 'question:investment_goals_text',
                  dataPointKey: 'text:investment_goals_text:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Text,
                  rules: [
                    {
                      next: 'question:investment_objective',
                    },
                  ],
                },
                {
                  questionKey: 'question:investment_objective',
                  dataPointKey: 'data_point:investment_objective:single_option',
                  symphonyMapping: 'investment.investmentObjective',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:primary_investment_purpose',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:primary_investment_purpose',
                  dataPointKey: 'data_point:primary_investment_purpose:single_option',
                  symphonyMapping: 'investment.accountPurpose',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:has_experience_buying_selling_instruments',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:has_experience_buying_selling_instruments',
                  dataPointKey: 'data_point:has_experience_buying_selling_instruments:boolean',
                  symphonyMapping: 'investment.hasExperienceBuyingOrSellingInstruments',
                  componentType: ComponentTypes.Checkbox,
                  rules: [
                    {
                      next: 'question:has_experience_buying_selling_instruments_text',
                    },
                  ],
                },
                {
                  questionKey: 'question:has_experience_buying_selling_instruments_text',
                  dataPointKey: 'info:has_experience_buying_selling_instruments_text:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Info,
                  rules: [
                    {
                      next: 'question:overall_investment_experience',
                    },
                  ],
                },
                {
                  questionKey: 'question:overall_investment_experience',
                  dataPointKey: 'data_point:investment_experience:single_option',
                  symphonyMapping: 'investment.investmentExperience',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:stocks',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:stocks',
                  dataPointKey: 'data_point:stocks:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingStocks',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:stocks:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:stocks_non_qualified',
                    },
                    {
                      next: 'question:bonds',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:stocks_non_qualified',
                  dataPointKey: 'data_point:stocks_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.stocks.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:stocks_qualified',
                  rules: [
                    {
                      next: 'question:stocks_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:stocks_qualified',
                  dataPointKey: 'data_point:stocks_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.stocks.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:bonds',
                  dataPointKey: 'data_point:bonds:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingBonds',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:bonds:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:bonds_non_qualified',
                    },
                    {
                      next: 'question:short_term',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:bonds_non_qualified',
                  dataPointKey: 'data_point:bonds_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.bonds.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:bonds_qualified',
                  rules: [
                    {
                      next: 'question:bonds_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:bonds_qualified',
                  dataPointKey: 'data_point:bonds_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.bonds.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:short_term',
                  dataPointKey: 'data_point:short_term:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingOptions',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:short_term:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:short_term_non_qualified',
                    },
                    {
                      next: 'question:mutual_funds',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:short_term_non_qualified',
                  dataPointKey: 'data_point:short_term_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.options.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:short_term_qualified',
                  rules: [
                    {
                      next: 'question:short_term_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:short_term_qualified',
                  dataPointKey: 'data_point:short_term_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.options.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:mutual_funds',
                  dataPointKey: 'data_point:mutual_funds:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingMutualFunds',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:mutual_funds:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:mutual_funds_non_qualified',
                    },
                    {
                      next: 'question:variable_contracts',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:mutual_funds_non_qualified',
                  dataPointKey: 'data_point:mutual_funds_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.mutualFunds.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:mutual_funds_qualified',
                  rules: [
                    {
                      next: 'question:mutual_funds_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:mutual_funds_qualified',
                  dataPointKey: 'data_point:mutual_funds_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.mutualFunds.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:variable_contracts',
                  dataPointKey: 'data_point:variable_contracts:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingVariableContracts',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:variable_contracts:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:variable_contracts_non_qualified',
                    },
                    {
                      next: 'question:annuities',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:variable_contracts_non_qualified',
                  dataPointKey: 'data_point:variable_contracts_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.variableContracts.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:variable_contracts_qualified',
                  rules: [
                    {
                      next: 'question:variable_contracts_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:variable_contracts_qualified',
                  dataPointKey: 'data_point:variable_contracts_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.variableContracts.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:annuities',
                  dataPointKey: 'data_point:annuities:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingAnnuitiesAndLifeInsurance',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:annuities:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:annuities_non_qualified',
                    },
                    {
                      next: 'question:investment_experience_other',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:annuities_non_qualified',
                  dataPointKey: 'data_point:annuities_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.annuitiesAndLifeInsurance.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:annuities_qualified',
                  rules: [
                    {
                      next: 'question:annuities_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:annuities_qualified',
                  dataPointKey: 'data_point:annuities_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.annuitiesAndLifeInsurance.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:investment_experience_other',
                  dataPointKey: 'data_point:investment_experience_other:single_option',
                  symphonyMapping: 'investment.experienceBuyingOrSellingOther',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      conditions: [
                        {
                          type: 'EQUAL',
                          dataPointKey: 'data_point:investment_experience_other:single_option',
                          value: 'L,G,E',
                        },
                      ],
                      next: 'question:investment_experience_other_non_qualified',
                    },
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:investment_experience_other_non_qualified',
                  dataPointKey: 'data_point:investment_experience_other_non_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.other.nonQualified',
                  componentType: ComponentTypes.Input,
                  adjacent: 'question:investment_experience_other_qualified',
                  rules: [
                    {
                      next: 'question:investment_experience_other_qualified',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:investment_experience_other_qualified',
                  dataPointKey: 'data_point:investment_experience_other_qualified:number',
                  symphonyMapping: 'investment.experienceDetail.other.qualified',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          index: 2,
          number: 3,
          heading: 'wealth_expenses',
          questions: {
            order: {
              start: 'question:tax_bracket',
              orderSteps: [
                {
                  questionKey: 'question:tax_bracket',
                  dataPointKey: 'data_point:tax_bracket:single_option',
                  symphonyMapping: 'wealthInformation.taxBracket',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:annual_household_income',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:annual_household_income',
                  dataPointKey: 'data_point:annual_household_income:float',
                  symphonyMapping: 'wealthInformation.annualIncome',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:annual_recurring_expenses',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:annual_recurring_expenses',
                  dataPointKey: 'data_point:annual_recurring_expenses:single_option',
                  symphonyMapping: 'wealthInformation.annualRecurringExpenses',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:non_recurring_expenses',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:non_recurring_expenses',
                  dataPointKey: 'data_point:non_recurring_expenses:float',
                  symphonyMapping: 'wealthInformation.specialExpenses',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:adequate_liquidity',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:adequate_liquidity',
                  dataPointKey: 'data_point:adequate_liquidity:boolean',
                  symphonyMapping: 'wealthInformation.hasEmergencyFunds',
                  componentType: ComponentTypes.Checkbox,
                  rules: [
                    {
                      next: 'question:primary_funding_source',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:primary_funding_source',
                  dataPointKey: 'data_point:primary_funding_source:single_option',
                  symphonyMapping: 'wealthInformation.sourceOfInitialFunds',
                  componentType: ComponentTypes.Dropdown,
                  rules: [
                    {
                      next: 'question:cash',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                  ],
                },
                {
                  questionKey: 'question:cash',
                  dataPointKey: 'data_point:cash:number',
                  symphonyMapping: 'wealthInformation.cash',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:tangible_assets',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:tangible_assets',
                  dataPointKey: 'data_point:tangible_assets:number',
                  symphonyMapping: 'wealthInformation.nonLiquidAssets',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:liabilities',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:liabilities',
                  dataPointKey: 'data_point:liabilities:number',
                  symphonyMapping: 'wealthInformation.liabilities',
                  componentType: ComponentTypes.Input,
                  rules: [
                    {
                      next: 'question:net_worth_calculation',
                    },
                  ],
                  validations: [
                    {
                      name: 'required',
                      value: true,
                    },
                    {
                      name: 'maxLength',
                      value: 15,
                    },
                  ],
                },
                {
                  questionKey: 'question:net_worth_calculation',
                  dataPointKey: 'info:net_worth_calculation:string',
                  symphonyMapping: '',
                  componentType: ComponentTypes.Info,
                  rules: [
                    {
                      next: null,
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
    getAdditionalPaperwork(1, FinancialAccountType.TRUST, 'about_the_trustee', PaperworkType.PRIMARY),
    getAdditionalPaperwork(2, FinancialAccountType.TRUST, 'about_the_trustee', PaperworkType.SECONDARY),
    getAdditionalPaperwork(3, FinancialAccountType.TRUST, 'about_the_trustee', PaperworkType.SECONDARY),
    {
      index: 4,
      customComponent: InvestmentRationale,
    },
  ],
};
