import { Document, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import {
  AllocationDetailsColumns,
  AssetClassTier,
  ContentOptions,
  DownloadableProposalFactSheets as FactSheets,
  PdfComponentStyle as Style,
  RiskBand,
  StagedModelPortfolioData,
} from '@sigfig/digital-wealth-core';

import { FeesAndDisclosures, FeesAndDisclosuresContent } from './FeesAndDisclosures';
import { FeeData } from './hooks/useGetFeeData';
import { Appendix, AppendixContent } from './pages/Appendix';
import { Cover, CoverContent } from './pages/Cover';
import { DetailedInvestmentBreakdown, DetailedInvestmentBreakdownContent } from './pages/DetailedInvestmentBreakdown';
import {
  RiskPlaybackAndInvestmentRecommendation,
  RiskPlaybackAndInvestmentRecommendationContent,
} from './pages/RiskPlaybackAndInvestmentRecommendation';
import { TableOfContents, TableOfContentsContent } from './pages/TableOfContents';
import { WhyDigitalAdvicePro, WhyDigitalAdviceProContent } from './pages/WhyDigitalAdvicePro';

export interface Content {
  appendix: AppendixContent;
  cover: CoverContent;
  detailedInvestmentBreakdown: DetailedInvestmentBreakdownContent;
  feesAndDisclosures: FeesAndDisclosuresContent;
  riskPlaybackAndInvestmentRecommendation: RiskPlaybackAndInvestmentRecommendationContent;
  tableOfContents: TableOfContentsContent;
  whyDigitalAdvicePro: WhyDigitalAdviceProContent;
}

export interface Props {
  allocationDetailsColumns: AllocationDetailsColumns[];
  assetClassTier?: AssetClassTier;
  content: Content;
  contentOptions: ContentOptions;
  dataQa?: string;
  displayAllocationBar: boolean;
  factSheets: Buffer[];
  feeData: FeeData[];
  modelPortfolioData: StagedModelPortfolioData;
  partnerStyles: Style;
  riskBandColors: string[];
  riskBandNew: number;
  riskBands: RiskBand[];
  stockBondLabel: string;
}

export const DownloadableProposal: React.FC<Props> = ({
  allocationDetailsColumns,
  assetClassTier,
  content,
  contentOptions,
  dataQa,
  displayAllocationBar,
  factSheets,
  modelPortfolioData,
  feeData,
  partnerStyles,
  riskBandColors,
  riskBandNew,
  riskBands,
  stockBondLabel,
}) => {
  const pdfStyles = StyleSheet.create({ ...partnerStyles });

  const {
    cover,
    tableOfContents,
    whyDigitalAdvicePro,
    feesAndDisclosures,
    riskPlaybackAndInvestmentRecommendation,
    detailedInvestmentBreakdown,
    appendix,
  } = content;

  const modelPortfolioName =
    modelPortfolioData.modelPortfolios.length > 1
      ? detailedInvestmentBreakdown.compositeModelPortfolioName
      : modelPortfolioData.modelPortfolios[0].name;

  const factSheetPages = factSheets.length;

  return (
    <Document data-qa={dataQa}>
      <Cover content={cover} pdfStyles={pdfStyles} />
      <TableOfContents content={tableOfContents} factSheetPages={factSheetPages} pdfStyles={pdfStyles} />
      <WhyDigitalAdvicePro content={whyDigitalAdvicePro} pdfStyles={pdfStyles} />
      <RiskPlaybackAndInvestmentRecommendation
        content={riskPlaybackAndInvestmentRecommendation}
        contentOptions={contentOptions}
        modelPortfolioData={modelPortfolioData}
        pdfStyles={pdfStyles}
        riskBandColors={riskBandColors}
        riskBandNew={riskBandNew}
        riskBands={riskBands}
        stockBondLabel={stockBondLabel}
      />
      <DetailedInvestmentBreakdown
        allocationDetailsColumns={allocationDetailsColumns}
        allocationDetailsData={{
          assetAllocation: modelPortfolioData?.assetAllocation,
          centerSubText: stockBondLabel,
          centerText: modelPortfolioData?.stockBondRatio,
          modelPortfolioName,
        }}
        assetClassTier={assetClassTier}
        content={detailedInvestmentBreakdown}
        contentOptions={contentOptions}
        displayAllocationBar={displayAllocationBar}
        pdfStyles={pdfStyles}
      />
      <FeesAndDisclosures content={feesAndDisclosures} feeData={feeData} pdfStyles={pdfStyles} />
      <Appendix content={appendix} pdfStyles={pdfStyles} />
      <FactSheets factSheetImages={factSheets} />
    </Document>
  );
};
