import { useEffect, useState } from 'react';

import { ComponentHookResult } from '@sigfig/digital-wealth-core';

import { BillingRateTargetType } from '../../../../__generated__/symphonyTypes';
import { useGetManagedProductBillingData } from '../symphony';
import { getBalanceDisplayValue } from '../utils';

export interface FeeData {
  lowerBound: string | null;
  rate: string;
  upperBound: string | null;
}

export const useGetFeeData = (managedProductId: string, skip: boolean): ComponentHookResult<FeeData[]> => {
  const [state, setState] = useState<ComponentHookResult<FeeData[]>>({
    loading: true,
    error: undefined,
    data: undefined,
  });

  const { data, loading, error } = useGetManagedProductBillingData({ variables: { managedProductId }, skip });

  useEffect(() => {
    const billingData =
      data?.managedProduct?.billingData?.effectiveRates?.filter(value => {
        return value.rateTarget === BillingRateTargetType.ADVISOR;
      }) ?? [];
    const feeData = billingData.length === 1 ? billingData[0].tiers : [];

    const otherFeesData =
      data?.managedProduct?.billingData?.effectiveRates?.filter(value => {
        return value.rateTarget !== BillingRateTargetType.ADVISOR;
      }) ?? [];
    const otherFees = otherFeesData.reduce((acc, curr) => {
      return acc + (curr.tiers?.length === 1 ? parseFloat(curr.tiers[0].rate) : 0);
    }, 0);

    setState({
      data: feeData
        ? feeData.map(fee => {
            return {
              lowerBound: getBalanceDisplayValue(fee.lowerBound ?? 0),
              rate: fee.rate ? `${((parseFloat(fee.rate) + otherFees) * 100).toFixed(2)}%` : '',
              upperBound: fee.upperBound ? getBalanceDisplayValue(fee.upperBound) : null,
            };
          })
        : [],
      loading,
      error,
    });
  }, [data, loading, error]);

  return state;
};
