import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  AccountPerformance,
  AssetClassTier,
  MeasurementName,
  PerformanceMethodTypes,
} from '@sigfig/digital-wealth-core';

import { useApp } from '../../contexts/App';
import { partnerStyle } from '../../theme/PdfStyle';

interface AccountPerformanceProps extends RouteComponentProps<{ managedProductId: string; partyId: string }> {
  partyIdFA: string;
}

const AccountPerformanceWrapper: React.FC<AccountPerformanceProps> = ({
  match: {
    params: { managedProductId, partyId },
  },
  partyIdFA,
}) => {
  const { contentOptions } = useApp();

  const downloadableQPRProps = {
    partyIdFA,
    partnerStyles: partnerStyle,
  };

  return (
    <AccountPerformance
      assetClassTier={managedProductType => AssetClassTier.MODEL}
      contentOptions={contentOptions}
      downloadableQPRProps={downloadableQPRProps}
      featureFlags={{
        defaultContributionAmount: '100',
        ignoreInsufficientFunds: true,
        syncExternalBankAccounts: true,
      }}
      initialTimePeriod={MeasurementName.QTD}
      managedProductId={managedProductId}
      partyId={partyId}
      performanceMethod={PerformanceMethodTypes.FROM_END_OF_DAY_VALUES}
    />
  );
};

export default AccountPerformanceWrapper;
