import React from 'react';

import {
  AssetClassTier,
  ManagedProductType,
  PortfolioSelection,
  PortfolioSelectionProps,
} from '@sigfig/digital-wealth-core';

import { FlowType } from '../../__generated__/symphonyTypes';

import { useGetPortfolioSelection } from './hooks/useGetPortfolioSelection';

export interface PortfolioSelectionWrapperProps extends PortfolioSelectionProps {
  flowType?: FlowType;
}

export const PortfolioSelectionWrapper: React.FC<PortfolioSelectionWrapperProps> = ({
  actorPartyId,
  contentOptions,
  flowType,
  managedProductId,
  onBack,
  onNext,
  partyId,
  productType,
  isRce,
  onKeepCurrentPortfolio,
}) => {
  const managedProductType = ManagedProductType.DIGITAL_ADVICE_PRO;
  const brokerageAlias = 'citizensInvestments';

  const portfolioSelectionData = useGetPortfolioSelection({
    brokerageAlias,
    contentOptions,
    flowType,
    onNext,
    managedProductId,
    managedProductType,
    partyId,
  });
  return (
    <PortfolioSelection
      actorPartyId={actorPartyId}
      assetClassTier={AssetClassTier.ENCLOSING}
      brokerageAlias={brokerageAlias}
      contentOptions={contentOptions}
      isRce={isRce}
      managedProductId={managedProductId}
      onBack={onBack}
      onKeepCurrentPortfolio={onKeepCurrentPortfolio}
      onNext={onNext}
      partyId={partyId}
      portfolioSelectionData={portfolioSelectionData}
      productType={productType}
    />
  );
};
