import { useEffect, useState } from 'react';

import { ComponentHookResult, ContentOptions, DropdownItem } from '@sigfig/digital-wealth-core';

import { useApp } from '../../../contexts/App';
import { AccountSetupModalProps } from '..';
import { useGetAccountSetupModalContent } from '../contentstack';

export interface GetAccountSetupModalDataResponse {
  content: AccountSetupModalProps['content'];
  data: AccountSetupModalProps['data'];
}

export const useGetAccountSetupModalData = (
  contentOptions: ContentOptions,
): ComponentHookResult<GetAccountSetupModalDataResponse> => {
  const [state, setState] = useState<ComponentHookResult<GetAccountSetupModalDataResponse>>({
    loading: true,
    error: undefined,
  });

  const {
    data: accountSetupModalContentData,
    error: accountSetupModalContentError,
    loading: accountSetupModalContentLoading,
  } = useGetAccountSetupModalContent({
    variables: contentOptions,
  });

  const { accountPrefix } = useApp();

  useEffect(() => {
    if (accountSetupModalContentLoading) {
      return;
    }

    if (accountSetupModalContentError) {
      setState({ error: accountSetupModalContentError, loading: false });
    }

    const modalContent = accountSetupModalContentData?.all_initial_account_setup_modal?.items?.[0];
    const branchCodes =
      accountSetupModalContentData?.all_branches?.items?.[0]?.branches?.reduce<DropdownItem<string>[]>(
        (prevBranches, currBranch) => {
          if (currBranch?.key && currBranch?.label) {
            prevBranches.push({
              value: currBranch.key,
              label: currBranch.label,
            });
          }
          return prevBranches;
        },
        [],
      ) ?? [];

    const data = {
      content: {
        accountPrefixLabel: modalContent?.account_prefix?.label ?? '',
        branchCode: {
          label: modalContent?.branch_code?.label ?? '',
          placeholder: modalContent?.branch_code?.placeholder ?? '',
        },
        disclaimer: modalContent?.ctas?.disclaimer ?? '',
        primaryButtonCta: modalContent?.ctas?.primary ?? '',
        repCodeLabel: modalContent?.rep_code?.label ?? '',
        secondaryButtonCta: modalContent?.ctas?.secondary ?? '',
        title: modalContent?.modal_title ?? '',
      },
      data: {
        accountPrefix: {
          primaryLabel: modalContent?.account_prefix?.prefix ?? accountPrefix,
          secondaryLabel: modalContent?.account_prefix?.helper_text ?? '',
        },
        branchCodes,
      },
    };

    setState({ data, loading: false });
  }, [
    accountPrefix,
    accountSetupModalContentData?.all_branches?.items,
    accountSetupModalContentData?.all_initial_account_setup_modal?.items,
    accountSetupModalContentError,
    accountSetupModalContentLoading,
  ]);

  return state;
};
