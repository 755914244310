import {
  AccountDetailsTabsEnum,
  ActiveClientColumns,
  ActiveClientFilters,
  AllocationDetailsColumns,
  AssetAllocationTableColumns,
  CoreConfig,
  defaultCoreConfig,
  FADashboardTabs,
} from '@sigfig/digital-wealth-core';

export const defaultPartnerConfigs: CoreConfig = {
  ...defaultCoreConfig,
  featureFlags: {
    ...defaultCoreConfig.featureFlags,
    msiEnabled: true,
    isSuspendedAccountStateSupported: false,
    repCodeIdentifierName: 'NFS_REP_CODE',
  },
  components: {
    ...defaultCoreConfig.components,
    sfAccountDetails: {
      ...defaultCoreConfig.components.sfAccountDetails,
      showClosedAccountsInDropdown: true,
    },
    sfAccountDetailsV2: {
      ...defaultCoreConfig.components.sfAccountDetailsV2,
      ignoreInsufficientFunds: true,
      showPhoneNumber: true,
      tabs: [
        AccountDetailsTabsEnum.overview,
        AccountDetailsTabsEnum.restrictions,
        AccountDetailsTabsEnum.activity,
        AccountDetailsTabsEnum.documents,
      ],
    },
    sfAccountSummary: {
      ...defaultCoreConfig.components.sfAccountSummary,
      fallBackToStagedModelPortfolio: true,
    },
    sfWithdrawFunds: {
      ...defaultCoreConfig.components.sfWithdrawFunds,
      soonestPossibleDays: 2,
    },
    sfComparison: {
      showInvestmentObjectiveQuestion: true,
      showRecommendationRationaleQuestion: true,
    },
    sfDownloadProposal: {
      allocationDetailsColumns: [
        AllocationDetailsColumns.ASSET_CLASS_LABEL,
        AllocationDetailsColumns.ALLOCATION_PERCENTAGE,
      ],
      displayAllocationBar: true,
      showModelPortfolioDescription: false,
    },
    sfDownloadQPR: {
      ...defaultCoreConfig.components.sfDownloadQPR,
      allocationDetailsColumns: [
        AssetAllocationTableColumns.AssetClass,
        AssetAllocationTableColumns.TargetAllocation,
        AssetAllocationTableColumns.ActualAllocation,
        AssetAllocationTableColumns.Value,
      ],
      displayAllocationBar: true,
    },
    sfInvestmentRestrictions: {
      maximumRestrictions: 5,
    },
    sfLinkBankAccount: {
      ...defaultCoreConfig.components.sfLinkBankAccount,
      accountNumberLength: 17,
      bankAccountNameLength: 25,
      allowedBanksWithRoutingNumbers: {
        'CITIZENS BANK': [
          '211170114',
          '031101143',
          '241070417',
          '211070175',
          '011401533',
          '036076150',
          '021313103',
          '011500120',
          '222371054',
          '221370030',
        ],
      },
      showNameOnAccountField: true,
    },
    sfPaperwork: {
      ageOfTerminationAttributeName: 'AGE_OF_TERMINATION',
      drivingLicenseIdentifier: 'DRIVING_LICENSE',
      isReceivingEmailsControlledByEDeliveryEnrollment: true,
      isAffiliationLetterFieldEnabled: false,
      registeredStatesSeparator: ',',
      statesWithUGMA: ['GU', 'PR', 'VI'],
      fetchRelatedContactsData: false,
      checkMaritalStatus: false,
      calculateNetWorth: true,
      registeredStatesAttributeName: 'REGISTEREDRASTATES',
      hiddenOptions: [
        {
          fieldName: 'data_point:citizenship:single_option',
          values: ['999'],
          dependentOn: {
            contextVariable: 'isResidentAlien',
            fieldName: 'data_point:resident_type:single_option',
            value: '2',
            comparisonType: 'EQUAL',
          },
        },
        {
          fieldName: 'data-point:identification_type:single_option',
          values: ['DRIVING_LICENSE'],
          dependentOn: {
            contextVariable: 'isResidentAlien',
            fieldName: 'data_point:resident_type:single_option',
            value: '2',
            comparisonType: 'EQUAL',
          },
        },
        {
          fieldName: 'data-point:passport_country:single_option',
          values: ['999'],
          dependentOn: {
            contextVariable: 'isResidentAlien',
            fieldName: 'data_point:resident_type:single_option',
            value: '2',
            comparisonType: 'EQUAL',
          },
        },
      ],
      isPerStirpesEnabled: false,
      isTrustTypeFieldsEnabled: true,
      isCustomRegulatorySection: true,
      defaultCountryCode: '999',
      passportIdentifier: 'PASSPORT',
      prefillAddressCountryCode: true,
      prefillIdentifierCountryForDrivingLicense: true,
      trustedContact: {
        allowSpecialCharacters: true,
        optionalCity: true,
        optionalState: true,
        optionalStreetAddress: true,
        optionalZipCode: true,
      },
      onlyPassportIdentificationTypeForResidentAliens: true,
    },
    sfPlan: {
      ...defaultCoreConfig.components.sfPlan,
      showPortfolioCompare: false,
    },
    sfPlayback: {
      trustedContactSectionPrimary: 'beneficiary',
      trustedContactSectionSecondary: 'regulatory',
      showEDisclosure: false,
    },
    sfPortfolioDetails: {
      assetAllocationTableColumns: [AssetAllocationTableColumns.AssetClass, AssetAllocationTableColumns.Allocation],
    },
    sfPortfolioSelection: {
      assetAllocationTableColumns: [AssetAllocationTableColumns.AssetClass, AssetAllocationTableColumns.Allocation],
      hideFactSheet: true,
      showInvestmentAmount: true,
      showMinimumInvestmentAmountColumn: true,
      supportedTags: ['IS_TAX_EFFICIENT', 'IS_ESG'],
      enableEditInitialInvestment: true,
      filterPortfoliosByMadlibsTaxStatus: {
        dataPointKey: 'data-point:std-da-pro-account-type:single-option',
        taxShelteredOptions: ['data-point-option:std-da-pro-account-type:retirement'],
      },
    },
    sfFADashboard: {
      ...defaultCoreConfig.components.sfFADashboard,
      hiddenActiveClientTableColumns: [ActiveClientColumns.PENDING_RMD_STATUS],
      hiddenActiveClientTableFilters: [ActiveClientFilters.PENDING_RMD_STATUS],
      tabs: [FADashboardTabs.ActiveClientsTab, FADashboardTabs.ApplicationInProgressTab],
    },
    sfFunding: {
      ...defaultCoreConfig.components.sfFunding,
      showCustodianCashField: true,
      filterAccountTypesBasedOnMadlibsFieldDataPoint: 'data-point:std-da-pro-account-type:single-option',
      retirementAccountDataPoint: 'data-point-option:std-da-pro-account-type:retirement',
    },
  },
};

const isIqa1 = window.location.origin === 'https://dw-pro-iqa1-citizens.qa.sigfig.com';
const isSqa1 =
  window.location.origin === 'https://dw-pro-sqa1-citizens.qa.sigfig.com' ||
  window.location.origin === 'https://advisory-connect-fa-dashboard.qacitizensbank.com';
const isSqa2 =
  window.location.origin === 'https://advisory-connect-fa-dashboard-2.qacitizensbank.com' ||
  window.location.origin === 'https://dw-pro-sqa2-citizens.qa.sigfig.com';
const isStg = window.location.origin === 'https://advisory-connect-fa-dashboard-stg.qacitizensbank.com';
const isProd = window.location.origin === 'https://advisory-connect-fa-dashboard.citizensbank.com';
const getConfigValue = ({
  iqa1,
  sqa1,
  sqa2,
  stg,
  prod,
  _,
}: {
  _: string;
  iqa1?: string;
  prod?: string;
  sqa1?: string;
  sqa2?: string;
  stg?: string;
}) => {
  if (isIqa1 && iqa1) {
    return iqa1;
  }

  if (isSqa1 && sqa1) {
    return sqa1;
  }

  if (isSqa2 && sqa2) {
    return sqa2;
  }
  if (isStg && stg) {
    return stg;
  }
  if (isProd && prod) {
    return prod;
  }
  return _;
};

export default {
  // TODO: update CS configs
  contentstack: {
    environment: getConfigValue({
      iqa1: 'dev',
      sqa1: 'citizens_sqa1',
      sqa2: 'citizens_prod', // This value may change
      stg: 'citizens_prod', // Same as prod
      prod: 'citizens_prod',
      _: 'dev',
    }),
    deliveryToken: getConfigValue({
      iqa1: 'csaffbef3139b4b24aee2cc24c',
      sqa1: 'csbeebf22381d29cbf99aad72c',
      // This will change same as Prod for now
      sqa2: 'cs6ed37d7a109fb4c8bada2dd6',
      stg: 'cs6ed37d7a109fb4c8bada2dd6', // Same as prod
      prod: 'cs6ed37d7a109fb4c8bada2dd6',
      _: 'csaffbef3139b4b24aee2cc24c',
    }),
  },
  auth0: {
    clientId: getConfigValue({
      iqa1: 'VYDOYQ68JCc8EBmZb0ndsN8ILNljdxsF',
      sqa1: 'uOX8b8x18HBd2ptl2yKUkBcBnQbxjoie',
      sqa2: 'SCLj5YO1KuKMGs4Cys2A0cs1MiYSy4vj',
      stg: '7NRmHx7CTVuNMFel1Y7IvYxi0bIDJ3Xw',
      prod: 'DZt8o1avbVmcFekEqXdO1QQtj1uPxlcd',
      _: 'IZJ6ez1leyTdhM7cxdsc9PX5rlc2tQGo',
    }),
    domain: getConfigValue({
      iqa1: 'citizens-iqa.us.auth0.com',
      sqa1: 'citizens-sqa1.us.auth0.com',
      sqa2: 'citizens-sqa2.us.auth0.com',
      stg: 'citizens-staging.us.auth0.com',
      prod: 'citizens-prod.us.auth0.com',
      _: 'citizens-iqa.us.auth0.com',
    }),
    logoutUrl: 'http://localhost:3000',
  },
  sentry: {
    dsn: getConfigValue({
      iqa1: 'https://ece2115333d9445ea5234bbeadf4fa41@o14749.ingest.sentry.io/6380773',
      sqa1: 'https://a19d4171621648349cbf99a3fd4b73c8@o14749.ingest.sentry.io/6394444',
      // This will change same as SQA1 for now
      sqa2: 'https://a19d4171621648349cbf99a3fd4b73c8@o14749.ingest.sentry.io/6394444',
      stg: 'https://dea54802006d4fa19647b2bb63f25b5d@o14749.ingest.sentry.io/6526250',
      prod: 'https://81b0bd5a14014924908f2a7c5286d9c7@o14749.ingest.sentry.io/6526251',
      _: 'https://f03d680f68a44524a531560c909911df@o14749.ingest.sentry.io/6380774',
    }),
  },
  posthog: {
    token: getConfigValue({
      prod: 'phc_wAAwlh02MecmZZxrg4fJ7BbhXCYv4HqoQxhcArpGYAO',
      _: 'phc_EpiD1VgtpRhk7u2wq303Isj8ckaylpXEFfJJOJN8BOR',
    }),
  },
};
