import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import {
  GetAccountSetupModalContent,
  GetAccountSetupModalContentVariables,
} from './__generated__/GetAccountSetupModalContent';
const getAccountSetupModalContentQuery = loader('./GetAccountSetupModalContent.gql');

export const useGetAccountSetupModalContent = (
  options?: QueryHookOptions<GetAccountSetupModalContent, GetAccountSetupModalContentVariables>,
): QueryResult<GetAccountSetupModalContent, GetAccountSetupModalContentVariables> => {
  return useContentstackQuery(getAccountSetupModalContentQuery, options);
};
