import {
  FinancialAccountType,
  PaperworkType,
  PaperworkV2Exports as CorePaperworkV2Exports,
} from '@sigfig/digital-wealth-core';

export const getPaperworkV2Config = async (
  financialAccountType: FinancialAccountType,
): Promise<CorePaperworkV2Exports.PaperworkV2PageConfig> => {
  switch (financialAccountType) {
    case FinancialAccountType.JOINT:
    case FinancialAccountType.JOINT_TENANTS_IN_COMMON:
      return import('./joint').then(() => {
        // Mocking blank data first
        return Promise.resolve({
          pages: [],
          paperworkFreeFormIdToProfileType: {},
          paperworkFreeFormIdToTrustProfileType: {},
        });
      });
    default:
      return import('./individual').then(individual => {
        return individual.paperworkV2Data(financialAccountType);
      });
  }
};

export const individualPaperworkFreeFormIdToProfileType: Record<string, PaperworkType> = {
  individual: PaperworkType.PRIMARY,
};
