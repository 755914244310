import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useSymphonyQuery } from '@sigfig/digital-wealth-core';

import {
  GetManagedProductBillingData,
  GetManagedProductBillingDataVariables,
} from './__generated__/GetManagedProductBillingData';

const getManagedProductBillingDataQuery = loader('./GetManagedProductBillingData.gql');

export const useGetManagedProductBillingData = (
  options?: QueryHookOptions<GetManagedProductBillingData, GetManagedProductBillingDataVariables>,
): QueryResult<GetManagedProductBillingData, GetManagedProductBillingDataVariables> =>
  useSymphonyQuery(getManagedProductBillingDataQuery, options);
