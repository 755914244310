import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';

import { FaDashboardParams, FADashboardTabs } from '@sigfig/digital-wealth-core';

import { routes } from '../../routes';

const isValidTab = (tab: string | undefined): FADashboardTabs | undefined =>
  Object.values(FADashboardTabs).find(value => value === tab);

export const useFADashboardParams = (partyId: string) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const params = useMemo((): FaDashboardParams => {
    return {
      state: searchParams.get('state') ?? undefined,
      tab: isValidTab(searchParams.get('tab') ?? undefined),
    };
  }, [searchParams]);

  const onParamsUpdate = useCallback(
    (updatedParams: FaDashboardParams, isReplace?: boolean) => {
      navigate(routes.faDashboard(partyId, updatedParams), { replace: !!isReplace });
    },
    [navigate, partyId],
  );

  return {
    onParamsUpdate,
    params,
  };
};
