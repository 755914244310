import { loader } from 'graphql.macro';

import {
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult,
  useSymphonyMutation,
  useSymphonyQuery,
} from '@sigfig/digital-wealth-core';

import {
  GetPortfolioSelectionData,
  GetPortfolioSelectionData_managedProduct_calculatedRecommendations,
  GetPortfolioSelectionData_managedProduct_modelPortfolios_tags,
  GetPortfolioSelectionData_managedProduct_modelPortfolios_tags_BooleanAttribute,
  GetPortfolioSelectionData_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedSingleOption,
  GetPortfolioSelectionDataVariables,
} from './__generated__/GetPortfolioSelectionData';
import {
  GetPortfolioSelectionModelPortfolios,
  GetPortfolioSelectionModelPortfoliosVariables,
} from './__generated__/GetPortfolioSelectionModelPortfolios';
import { SaveProductSelection, SaveProductSelectionVariables } from './__generated__/SaveProductSelection';

const queries = loader('./query.gql');
const mutations = loader('./mutation.gql');
const getPortfolioSelectionModelPortfoliosQuery = loader('./getPortfolioSelectionModelPortfolios.gql');

export type CalculatedRecommendations = GetPortfolioSelectionData_managedProduct_calculatedRecommendations;
export type Tag = GetPortfolioSelectionData_managedProduct_modelPortfolios_tags;
export type BooleanAttributeTag = GetPortfolioSelectionData_managedProduct_modelPortfolios_tags_BooleanAttribute;
export type QuestionnaireCollectedDataSingleOption = GetPortfolioSelectionData_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedSingleOption;

export const isBooleanAttribute = (tag: Tag): tag is BooleanAttributeTag => tag.__typename === 'BooleanAttribute';

export const useSaveProductSelection = (
  options?: MutationHookOptions<SaveProductSelection, SaveProductSelectionVariables>,
): MutationTuple<SaveProductSelection, SaveProductSelectionVariables> => useSymphonyMutation(mutations, options);

export const useGetPortfolioSelectionData = (
  options?: QueryHookOptions<GetPortfolioSelectionData, GetPortfolioSelectionDataVariables>,
): QueryResult<GetPortfolioSelectionData, GetPortfolioSelectionDataVariables> => useSymphonyQuery(queries, options);

export const useGetPortfolioSelectionModelPortfolios = (
  options?: QueryHookOptions<GetPortfolioSelectionModelPortfolios, GetPortfolioSelectionModelPortfoliosVariables>,
): QueryResult<GetPortfolioSelectionModelPortfolios, GetPortfolioSelectionModelPortfoliosVariables> =>
  useSymphonyQuery(getPortfolioSelectionModelPortfoliosQuery, options);
