import React, { FC, ReactElement } from 'react';
import { Controller, FieldError, UseFormMethods } from 'react-hook-form';

import { Box, CurrencyTextField, Grid, TextField, Typography } from '@sigfig/digital-wealth-core';

import { AssetsDetails, AssetsFieldName, Validations } from '../hooks/useGetInvestmentRationale';
import { AssetsPrefillData } from '../symphony';

export interface FormData {
  [dataPointKey: string]: any;
}
export interface Props {
  assetUniqueId: number;
  assetsContent?: AssetsDetails;
  contentOptions: any;
  currencySymbol: string;
  dataQa?: string;
  fieldNames: AssetsFieldName;
  formHooks: UseFormMethods<FormData>;
  index: number;
  prefillData?: AssetsPrefillData;
  validationMessages?: Validations;
}

export const getInputError = (error?: FieldError, validationMessage?: string): ReactElement | false => {
  return (
    !!error && (
      <Box data-qa={`error-${error.ref?.name}`} sx={{ mr: 1 }}>
        <Typography role="alert" sx={{ color: 'error.main' }} variant="caption">
          {validationMessage}
        </Typography>
      </Box>
    )
  );
};

export const Assets: FC<Props> = ({
  dataQa = 'assets',
  assetsContent,
  assetUniqueId,
  contentOptions,
  currencySymbol,
  index,
  formHooks: {
    errors: fieldErrors,
    control,
    formState: { isSubmitted: formIsSubmitted },
    setValue,
  },
  fieldNames: { productName, amount, charge, incomeBenefitBase, deathBenefitBase },
  prefillData,
  validationMessages,
}) => {
  const AssetProductNameId = `${productName}-${assetUniqueId}`;
  const AssetChargeId = `${charge}-${assetUniqueId}`;
  const AssetAmountId = `${amount}-${assetUniqueId}`;
  const AssetDeathBenefitBaseId = `${deathBenefitBase}-${assetUniqueId}`;
  const AssetIncomeBenefitBaseId = `${incomeBenefitBase}-${assetUniqueId}`;

  const getController = (
    componentName: string,
    label?: string,
    isRequired?: boolean,
    type?: string,
    defaultvalue?: string,
    isLastField?: boolean,
  ) => {
    if (type === 'text') {
      return (
        <Box sx={{ mr: 2 }}>
          <Controller
            control={control}
            defaultValue={defaultvalue ?? ''}
            name={componentName}
            render={({ onChange, value, name, ref }) => (
              <TextField
                dataQa={`textField-${componentName}`}
                error={formIsSubmitted ? fieldErrors[componentName] : false}
                fullWidth
                id={componentName}
                inputRef={ref}
                label={label}
                name={name}
                onChange={onChange}
                type="text"
                value={value}
              />
            )}
            rules={isRequired ? { required: true } : {}}
          />
        </Box>
      );
    } else {
      return (
        <Box sx={{ mr: isLastField ? 0 : 2 }}>
          <Controller
            control={control}
            defaultValue={defaultvalue ?? ''}
            name={componentName}
            render={({ ref, value }) => (
              <CurrencyTextField
                contentOptions={contentOptions}
                currencySymbol={currencySymbol}
                data-qa={`${dataQa}-${componentName}`}
                error={formIsSubmitted ? fieldErrors[componentName] : false}
                fullWidth
                id={componentName}
                inputRef={ref}
                label={label}
                name={componentName}
                onValueChange={({ value: newValue }) =>
                  setValue(componentName, newValue, { shouldDirty: true, shouldValidate: true })
                }
                placeholder="$"
                value={parseFloat(value)}
              />
            )}
            rules={isRequired ? { required: true } : {}}
          />
        </Box>
      );
    }
  };

  return (
    <Box data-qa={dataQa} sx={{ mt: index ? 3 : 0, display: 'flex', flexDirection: 'row' }}>
      <Grid container>
        <Grid item md xs={12}>
          {getController(AssetProductNameId, assetsContent?.productName, true, 'text', prefillData?.name ?? '')}
          {formIsSubmitted && getInputError(fieldErrors[AssetProductNameId], validationMessages?.productName)}
        </Grid>
        <Grid item md xs={12}>
          {getController(AssetAmountId, assetsContent?.amount, true, 'number', prefillData?.amount ?? '')}
          {formIsSubmitted && getInputError(fieldErrors[AssetAmountId], validationMessages?.amount)}
        </Grid>
        <Grid item md mt={-0.5} xs={12}>
          {getController(AssetChargeId, assetsContent?.charge, true, 'number', prefillData?.salesCharge ?? '')}
          {formIsSubmitted && getInputError(fieldErrors[AssetChargeId], validationMessages?.charge)}
        </Grid>
        <Grid item md mt={-2.75} xs={12}>
          {getController(
            AssetDeathBenefitBaseId,
            assetsContent?.deathBenefitBase,
            false,
            'number',
            prefillData?.annuityDeathBenefitBase ?? '',
          )}
        </Grid>
        <Grid item md mt={-2.75} xs={12}>
          {getController(
            AssetIncomeBenefitBaseId,
            assetsContent?.incomeBenefitBase,
            false,
            'number',
            prefillData?.annuityIncomeBenefitBase ?? '',
            true,
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
