import React, { ComponentProps, FC, lazy, useCallback, useMemo } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';

import {
  AssetClassTier,
  FeePeriod,
  FinancialAccountType,
  FlowType,
  FundingSources,
  OnboardingStates,
  OnboardingWrapperHelper,
  Paperwork as CorePaperwork,
  PaperworkV2Exports as CorePaperworkV2Exports,
  PortfolioSelectionProps,
  PricingSummaryModalProps,
  QuestionnaireType,
} from '@sigfig/digital-wealth-core';

import { useDownloadProposal } from '../../components/pdfs/DownloadableProposal/hooks/useDownloadProposal';
import { paperworkData } from '../../config/paperwork';
import { getPaperworkV2Config } from '../../config/paperworkV2';
import { PortfolioSelectionWrapper } from '../../containers/PortfolioSelection';
import { useApp } from '../../contexts/App';
import { partnerStyle } from '../../theme/PdfStyle';
import { useCurrentPage } from '../../utils/route';
import { PageRoute, routes } from '../routes';
import { onboardingStatesOrder, questionnaireProps } from '../utils';

interface OnboardingProps extends RouteComponentProps<{ onboardingStage?: string; partyId: string }> {
  partyIdFA: string;
}

const Onboarding = lazy(() => import('../../components/OnboardingWrapper'));

export const OnboardingWrapper: FC<OnboardingProps> = ({
  history,
  match: {
    params: { partyId, onboardingStage },
  },
  partyIdFA,
}) => {
  const PortfolioSelectionComponent = useCallback(
    (flowType: FlowType): React.FC<PortfolioSelectionProps> => props => (
      <PortfolioSelectionWrapper flowType={flowType} {...props} actorPartyId={partyIdFA} />
    ),
    [partyIdFA],
  );
  const { contentOptions, program } = useApp();
  const navigate = useNavigate();
  const locationData = useLocation<{ data?: any }>().state?.data;
  const branchCode = useLocation<{ branchCode?: string }>().state?.branchCode;
  const isOnboardingDev = useCurrentPage(PageRoute) === PageRoute.OnboardingDev;

  const onboardingWrapperHelper = useMemo(() => {
    return new OnboardingWrapperHelper(
      (newOnboardingStage, state, replace) => {
        if (isOnboardingDev) {
          navigate(routes.onboardingDev(partyId ?? '', newOnboardingStage ?? null), { state, replace });
        } else {
          navigate(routes.onboarding(partyId ?? '', newOnboardingStage ?? null), { state, replace });
        }
      },
      undefined,
      false,
    );
  }, [navigate, partyId]);

  const defaultFeePeriod = FeePeriod.MONTHLY;
  const pricingSummaryModalProps: PricingSummaryModalProps = {
    defaultDiscountValue: 0,
    discountTiers: [0, 10, 20, 65],
    feePeriod: defaultFeePeriod,
    isAdvancedPricingEnabled: true,
    isTieredBasisPointAdvancedPricing: true,
    ratePrecision: 2,
    showFeeTiers: true,
  };

  const PaperworkV2Component = (props: ComponentProps<typeof CorePaperwork>) => {
    const PaperworkV2 = CorePaperworkV2Exports.PaperworkV2;
    return <PaperworkV2 {...props} paperworkConfigGetter={getPaperworkV2Config} />;
  };

  const overrideOnboardingComponents = useMemo(
    () => ({
      [OnboardingStates.PAPERWORK]: isOnboardingDev ? PaperworkV2Component : undefined,
      [OnboardingStates.PORTFOLIO_SELECTION]: PortfolioSelectionComponent(FlowType.ONBOARDING),
    }),
    [PortfolioSelectionComponent, isOnboardingDev],
  );

  return (
    <Onboarding
      assetClassTier={AssetClassTier.ENCLOSING}
      branchCode={branchCode}
      brokerageAlias="citizensInvestments"
      contentOptions={contentOptions}
      fundingProps={{
        ignoreInsufficientFunds: true, // TODO: ignoreInsufficientFunds should be removed from here after SAN can pass bank account information
        allowManualBrokerageEntry: true,
        featureFlags: {
          displayBankSourceByRoutingNumber: false,
          displayBrokerageWithManualCustodianEntry: true,
          fundingSourceRestrictions: {
            [FinancialAccountType.INDIVIDUAL]: [FundingSources.employerSponsoredPlan],
            [FinancialAccountType.JOINT]: [FundingSources.employerSponsoredPlan],
            [FinancialAccountType.ROLLOVER_IRA]: [
              FundingSources.bankAccount,
              FundingSources.otherBrokerageAccount,
              FundingSources.annuity,
              FundingSources.exchange,
            ],
            [FinancialAccountType.INHERITED_TRADITIONAL_IRA]: [
              FundingSources.bankAccount,
              FundingSources.employerSponsoredPlan,
              FundingSources.annuity,
            ],
            [FinancialAccountType.INHERITED_ROTH_IRA]: [
              FundingSources.bankAccount,
              FundingSources.employerSponsoredPlan,
              FundingSources.annuity,
            ],
            [FinancialAccountType.TRUST]: [FundingSources.employerSponsoredPlan],
          },
          isDCAEnabled: false,
          isFundingSourceEnabled: true,
          showInvestmentAmountOnFundLater: true,
          showRecurringDepositSection: false,
        },
        pricingSummaryModalProps,
        useTaxStatusBasedPortfolioDetails: false,
      }}
      locationData={locationData}
      madlibsProps={{ combineSoftBlocks: true, questionnaireType: QuestionnaireType.ONBOARDING_V2 }}
      onLastStage={managedProductId => history.push(`/docusign/${partyId}/${managedProductId}`)}
      onboardingStage={onboardingStage}
      onboardingStatesOrder={onboardingStatesOrder} // TODO: Should come from config
      onboardingWrapperHelper={onboardingWrapperHelper}
      overrideOnboardingComponents={overrideOnboardingComponents}
      paperworkData={paperworkData}
      paperworkProps={{
        partyIdFA,
        prefillWealthDataFromQuestionnaire: true,
        pricingSummaryModalProps,
        showSaveButton: true,
      }}
      partyId={partyId}
      partyIdFA={partyIdFA}
      planProps={{
        defaultFeePeriod,
        downloadProposalProps: {
          partyIdFA,
          partnerStyles: partnerStyle,
          getFactSheetURL: (series: string, internalId: string, page: number, providerName) => {
            return `https://co-pilot-docs.s3-us-west-2.amazonaws.com/materials/${providerName}/${series}/Factsheets/${internalId}${page}.png`;
          },
          useDownloadProposalOverride: useDownloadProposal,
        },
        pricingSummaryModalProps,
        useTaxStatusBasedPortfolioDetails: false,
        showFeeCalculator: true,
        showGenerateProposalLink: true,
      }}
      playbackProps={{
        pricingSummaryProps: pricingSummaryModalProps,
        isConfirmationModalRequired: true,
      }}
      productType={program}
      questionnaireProps={{ ...questionnaireProps }}
    />
  );
};
