import { Canvas, Image, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { PdfComponentStyle as Style, PdfFooter as Footer } from '@sigfig/digital-wealth-core';

export interface CoverContent {
  email: string;
  emailImage: string;
  footerText: string;
  logo?: string;
  phone: string;
  phoneImage: string;
  subtitle: string;
  title: string;
  titleSuperscript: string;
}

interface Props {
  content: CoverContent;
  pdfStyles: Style;
}

export const Cover: React.FC<Props> = ({ content, pdfStyles }) => {
  const commonTextStyle = {
    color: pdfStyles.textPrimary.color,
    fontSize: 10,
    marginTop: '10px',
  };

  return (
    <>
      <Page size="LETTER" wrap={false}>
        <View
          style={{
            width: '100%',
            textAlign: 'left',
            marginTop: '200px',
            marginLeft: '60px',
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
            <Text
              style={{
                color: pdfStyles.primaryMainColor.color,
                fontFamily: 'Citi Sans',
                fontSize: 18,
              }}
            >
              {content.title}
            </Text>
            {content.titleSuperscript && (
              <Text
                style={{
                  color: pdfStyles.primaryMainColor.color,
                  fontFamily: 'Citi Sans',
                  fontSize: 8,
                  marginBottom: '18px',
                }}
              >
                {content.titleSuperscript}
              </Text>
            )}
          </View>
          <Text
            style={{
              color: pdfStyles.textPrimary.color,
              fontFamily: 'Citi Sans',
              marginTop: '12px',
              fontSize: 14,
            }}
          >
            {content.subtitle}
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row', marginTop: '32px' }}>
            <Image
              src={content.emailImage}
              style={{ maxHeight: '16px', maxWidth: '16px', marginRight: '4px', marginTop: '8px' }}
            />
            <Text style={commonTextStyle}>{content.email}</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Image
              src={content.phoneImage}
              style={{ maxHeight: '16px', maxWidth: '16px', marginRight: '4px', marginTop: '8px' }}
            />
            <Text style={commonTextStyle}>{content.phone}</Text>
          </View>
        </View>
        <View style={{ position: 'absolute', bottom: 4, alignSelf: 'flex-start', width: '100%' }}>
          <Footer pdfStyles={pdfStyles} text={content.footerText} />
          <View
            fixed
            style={{
              display: 'flex',
              alignSelf: 'flex-start',
              width: '100%',
              justifyContent: 'center',
              position: 'absolute',
              bottom: 4,
            }}
          >
            <Canvas
              paint={(painter: any) => painter.rect(0, 3, 500, 12.5).fill(pdfStyles.primaryMainColor.color)}
              style={{
                height: '22px',
                left: 0,
                bottom: 2,
              }}
            />
            <Image
              src={content.logo}
              style={{ position: 'absolute', right: 10, bottom: 2, alignSelf: 'flex-start', height: '20px' }}
            />
          </View>
        </View>
      </Page>
    </>
  );
};
