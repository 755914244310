import { loader } from 'graphql.macro';

import {
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult,
  useSymphonyMutation,
  useSymphonyQuery,
} from '@sigfig/digital-wealth-core';

import {
  getInvestmentProductSwitch,
  getInvestmentProductSwitch_managedProduct_investmentProductSwitch_assetsToSell,
  getInvestmentProductSwitchVariables,
} from './__generated__/getInvestmentProductSwitch';
import { getPlanDetails, getPlanDetailsVariables } from './__generated__/GetPlanDetails';
import {
  saveInvestmentProductSwitch,
  saveInvestmentProductSwitchVariables,
} from './__generated__/saveInvestmentProductSwitch';
import { updatePlanUpdateWorkflow, updatePlanUpdateWorkflowVariables } from './__generated__/updatePlanUpdateWorkflow';

const getPlanDetailsQuery = loader('./getPlanDetails.gql');
const getInvestmentProductSwitchQuery = loader('./getInvestmentProductSwitch.gql');
const saveInvestmentProductSwitchMutation = loader('./saveInvestmentProductSwitch.gql');
const updatePlanUpdateWorkflowMutation = loader('./updatePlanUpdateWorkflow.gql');

export type AssetsPrefillData = getInvestmentProductSwitch_managedProduct_investmentProductSwitch_assetsToSell | null;

export const useSaveInvestmentProductSwitch = (
  options?: MutationHookOptions<saveInvestmentProductSwitch, saveInvestmentProductSwitchVariables>,
): MutationTuple<saveInvestmentProductSwitch, saveInvestmentProductSwitchVariables> =>
  useSymphonyMutation(saveInvestmentProductSwitchMutation, options);

export const useUpdatePlanUpdateWorkflow = (
  options?: MutationHookOptions<updatePlanUpdateWorkflow, updatePlanUpdateWorkflowVariables>,
): MutationTuple<updatePlanUpdateWorkflow, updatePlanUpdateWorkflowVariables> =>
  useSymphonyMutation(updatePlanUpdateWorkflowMutation, options);

export const useGetPlanDetails = (
  options?: QueryHookOptions<getPlanDetails, getPlanDetailsVariables>,
): QueryResult<getPlanDetails, getPlanDetailsVariables> => useSymphonyQuery(getPlanDetailsQuery, options);

export const useGetInvestmentProductDetails = (
  options?: QueryHookOptions<getInvestmentProductSwitch, getInvestmentProductSwitchVariables>,
): QueryResult<getInvestmentProductSwitch, getInvestmentProductSwitchVariables> =>
  useSymphonyQuery(getInvestmentProductSwitchQuery, options);
