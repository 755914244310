import { FaDashboardParams, RetakeRTQStates } from '@sigfig/digital-wealth-core';

import { generateAbsolutePath, getSearchParams } from '../utils/route';

export enum PageRoute {
  AccountDetails = 'account-summary/:partyId/details/:managedProductId',
  AccountPerformance = 'account-summary/:partyId/performance/:managedProductId',
  AccountProfile = 'account-profile/:partyId/:managedProductId',
  AccountProfileDocusignComplete = 'docusignComplete/:partyId/account-profile/:managedProductId',
  AccountSummary = 'account-summary/:partyId',
  AssetClassesDefinitions = 'asset-classes-definitions',
  BrokerageTransfer = 'brokerage-transfer/:partyId/:managedProductId',
  CreateGoals = 'goals/:partyId/create',
  DigitalWealth = 'account-summary/:partyId/*',
  DigitalWealthAccountDetails = 'details/:managedProductId',
  DigitalWealthAccountPerformance = 'performance/:managedProductId',
  Docusign = 'docusign/:partyId',
  DocusignComplete = 'docusignComplete/:partyId/:managedProductId?',
  DocusignCompleteAuth = 'docusignCompleteAuth/:partyId/:managedProductId',
  DocusignWithManagedProduct = 'docusign/:partyId/:managedProductId',
  FADashboard = 'fa-dashboard/:partyId',
  Onboarding = 'onboarding/:partyId/:onboardingStage?',
  // used for testing paperworkV2 changes, can be used for other under-development onboarding changes
  OnboardingDev = 'onboarding-dev/:partyId/:onboardingStage?',
  Questionnaire = 'rce/:partyId/questionnaire/:managedProductId',
  RceDocusign = '/bank-account-docusign/:partyId/:managedProductId/:entityId',
  RceDocusignCompleted = 'docusignComplete/:partyId/:managedProductId/:bankAccountAssociationId',
  RceDocusignCompletedAuth = 'docusignCompleteAuth/:partyId/:managedProductId/:bankAccountAssociationId',
  RetakeRTQDocusignCompleted = 'docusignComplete/:partyId/questionnaire/:managedProductId',
  RetakeRTQDocusignCompletedAuth = 'docusignCompleteAuth/:partyId/questionnaire/:managedProductId',
}

export const routes = {
  docusign: (partyId: string, managedProductId: string): string =>
    generateAbsolutePath(PageRoute.DocusignWithManagedProduct, { managedProductId, partyId }),
  faDashboard: (partyId: string, params?: FaDashboardParams) =>
    generateAbsolutePath(PageRoute.FADashboard, { partyId }) +
    getSearchParams({ state: params?.state, tab: params?.tab }),
  onboarding: (partyId: string, onboardingStage: string | null) =>
    generateAbsolutePath(PageRoute.Onboarding, { partyId, onboardingStage }),
  onboardingDev: (partyId: string, onboardingStage: string | null) =>
    generateAbsolutePath(PageRoute.OnboardingDev, { partyId, onboardingStage }),
  questionnaire: (partyId: string, managedProductId: string, state?: RetakeRTQStates) =>
    generateAbsolutePath(PageRoute.Questionnaire, { partyId, managedProductId }) + getSearchParams({ state }),
};
