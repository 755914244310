import { Page, Text, View } from '@react-pdf/renderer';
import React from 'react';

import {
  PdfComponentStyle as Style,
  PdfDivider as Divider,
  PdfFooter as Footer,
  PdfHeader as Header,
} from '@sigfig/digital-wealth-core';

import { FooterGraphics } from '../../components/FooterGraphics';

export interface List {
  index: string;
  page: string;
}

export interface TableOfContentsContent {
  footerLogo: string;
  footerText: string;
  logo?: string;
  pageName: string;
  pages: List[];
  title: string;
}

export const TableOfContents: React.FC<{
  content: TableOfContentsContent;
  factSheetPages: number;
  pdfStyles: Style;
}> = ({ content, factSheetPages, pdfStyles }) => {
  const commonTextStyle = {
    color: pdfStyles.textPrimary.color,
    fontSize: 10,
    marginTop: '10px',
    marginBottom: '10px',
  };

  const calculatePageNumber = (index: number, currentPageNumber: number, totalPages: number): number => {
    const totalSections = content.pages.length;
    switch (index) {
      case totalSections - 1:
        return totalPages - factSheetPages;
      case totalSections - 2:
        return totalPages - factSheetPages - 1;
      case totalSections - 3:
        return totalPages - factSheetPages - 2;
      default:
        return index + currentPageNumber + 1;
    }
  };

  return (
    <>
      <Page size="LETTER" wrap={false}>
        <Header logoUrl={content.logo} pageName={content.pageName} pdfStyles={pdfStyles} />
        <View
          style={{
            width: '100%',
            textAlign: 'left',
            paddingTop: '48px',
            paddingLeft: '80px',
            paddingRight: '80px',
          }}
        >
          <Text
            style={{
              color: pdfStyles.primaryMainColor.color,
              fontSize: 16,
              marginBottom: '12px',
            }}
          >
            {content.title}
          </Text>
          {content.pages.map((item, index) => {
            return (
              <View key={index}>
                <View
                  style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', margin: '0 12px' }}
                >
                  <Text style={commonTextStyle}>{item.page}</Text>
                  <Text
                    render={({ pageNumber, totalPages }) => calculatePageNumber(index, pageNumber, totalPages)}
                    style={commonTextStyle}
                  />
                </View>
                <Divider pdfStyles={pdfStyles} />
              </View>
            );
          })}
        </View>
        <Footer pdfStyles={pdfStyles} text={content.footerText} />
        <FooterGraphics logo={content.footerLogo} pdfStyles={pdfStyles} />
      </Page>
    </>
  );
};
