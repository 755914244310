import { Canvas, Image, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { PdfComponentStyle as Style } from '@sigfig/digital-wealth-core';

interface Props {
  logo?: string;
  pdfStyles: Style;
}

export const FooterGraphics: React.FC<Props> = ({ logo, pdfStyles }) => {
  return (
    <View
      fixed
      style={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 40,
      }}
    >
      <Canvas
        paint={(painter: any) => painter.rect(550, 3, 70, 25).fill(pdfStyles.primaryMainColor.color)}
        style={{
          width: '100%',
          height: 200,
          right: 0,
        }}
      />
      <View
        style={{
          fontSize: 7,
          color: 'white',
          position: 'absolute',
          bottom: 14,
          right: 7,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {logo ? (
          <Image
            src={logo}
            style={{
              position: 'absolute',
              bottom: 1,
              right: 25,
              width: 20,
              maxHeight: '18px',
              maxWidth: '18px',
            }}
          />
        ) : null}
        <Text
          style={{
            position: 'absolute',
            bottom: 4,
            right: 15,
            fontSize: 12,
          }}
        >
          |
        </Text>
        <Text
          fixed
          render={({ pageNumber }) => `${pageNumber}`}
          style={{
            fontSize: 14,
            bottom: 2,
          }}
        />
      </View>
    </View>
  );
};
