import { Page, View } from '@react-pdf/renderer';
import React from 'react';

import {
  DownloadableProposalDisclosure as Disclosure,
  DownloadableProposalDisclosureContent as DisclosureContent,
  PdfComponentStyle as PdfStyle,
  PdfDivider as Divider,
  PdfFooter as Footer,
  PdfHeader as Header,
} from '@sigfig/digital-wealth-core';

import { FooterGraphics } from '../../components/FooterGraphics';
import { FeeData } from '../hooks/useGetFeeData';

import { FeeContent, Fees } from './Fees';

export interface FeesAndDisclosuresContent {
  disclosure: DisclosureContent;
  feeContent: FeeContent;
  footerLogo: string;
  footerText: string;
  logo?: string;
  pageName: string;
}

interface Props {
  content: FeesAndDisclosuresContent;
  feeData: FeeData[];
  pdfStyles: PdfStyle;
}

export const FeesAndDisclosures: React.FC<Props> = ({ content, feeData, pdfStyles }) => {
  const { disclosure, feeContent, logo, footerText } = content;

  return (
    <>
      <Page size="LETTER" wrap={false}>
        <Header logoUrl={logo} pageName={content.pageName} pdfStyles={pdfStyles} />
        <View
          style={{
            width: '100%',
            textAlign: 'left',
            paddingTop: '48px',
            paddingLeft: '80px',
            paddingRight: '80px',
          }}
        >
          <Fees content={feeContent} data={feeData} pdfStyles={pdfStyles} />
          <Divider pdfStyles={pdfStyles} />
          <Disclosure content={disclosure} pdfStyles={pdfStyles} />
        </View>
        <Footer pdfStyles={pdfStyles} text={footerText} />
        <FooterGraphics logo={content.footerLogo} pdfStyles={pdfStyles} />
      </Page>
    </>
  );
};
