import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import {
  GetInvestmentRationaleContent,
  GetInvestmentRationaleContent_all_citizens_investment_rationale_items_investment_switch_radio_options,
  GetInvestmentRationaleContent_all_citizens_investment_rationale_items_recommendation_relate_switch_radio_options,
  GetInvestmentRationaleContentVariables,
} from './__generated__/GetInvestmentRationaleContent';
const queries = loader('./query.gql');

export type InvestmentSwitchRadioOptions = GetInvestmentRationaleContent_all_citizens_investment_rationale_items_investment_switch_radio_options | null;
export type RecommendationRelateSwitchRadioOptions = GetInvestmentRationaleContent_all_citizens_investment_rationale_items_recommendation_relate_switch_radio_options | null;

export const useGetInvestmentRationaleContent = (
  options?: QueryHookOptions<GetInvestmentRationaleContent, GetInvestmentRationaleContentVariables>,
): QueryResult<GetInvestmentRationaleContent, GetInvestmentRationaleContentVariables> => {
  return useContentstackQuery(queries, options);
};
