import { History } from 'history';
import React from 'react';

import { HomeOfficeDashboard } from '@sigfig/digital-wealth-core';

import { useApp } from '../../contexts/App';

interface Props {
  history: History;
  partyIdFA: string;
}

export const HomeOfficeDashboardWrapper: React.FC<Props> = ({ partyIdFA, history }) => {
  const { contentOptions } = useApp();

  const onAdvisorClick = (_partyIdFA: string) => {
    history.replace(`/fa-dashboard/${_partyIdFA}`);
  };

  return (
    <HomeOfficeDashboard
      contentOptions={contentOptions}
      onAdvisorClick={onAdvisorClick}
      viewerPartyId={partyIdFA ?? ''}
    />
  );
};

export default HomeOfficeDashboard;
