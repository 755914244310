import { pdf } from '@react-pdf/renderer';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';

import {
  AllocationDetailsColumns,
  AssetClassTier,
  ContentOptions,
  getClientNameToDisplay,
  getEmail,
  getFactSheetImages,
  getPhone,
  LazyComponentHookResult,
  PdfComponentStyle as PdfStyle,
  PortfolioDetailsData,
  StagedModelPortfolioData,
  useCoreConfig,
  useGetClientInfo,
  useTheme,
} from '@sigfig/digital-wealth-core';

import { useApp } from '../../../../contexts/App';
import { Content, DownloadableProposal } from '..';
import { useGetDownloadableProposalContent } from '../contentstack';
import { getDownloadableProposalContent } from '../utils';

import { FeeData, useGetFeeData } from './useGetFeeData';

export interface DownloadableProposalFileResult {
  filename: string;
  pdfBlob?: Blob;
}

export interface UseDownloadProposalVariables {
  managedProductId?: string;
  partyId: string;
  partyIdFA: string;
}

export type UseDownloadProposalData = (
  getFactSheetURL: (series: string, internalId: string, page: number) => string,
  modelPortfolioData: StagedModelPortfolioData,
  partnerStyles: PdfStyle,
  portfolioDetailsData: PortfolioDetailsData,
  assetClassTier?: AssetClassTier,
) => Promise<DownloadableProposalFileResult>;

export const useDownloadProposal = ({
  managedProductId,
  partyId,
  partyIdFA,
}: UseDownloadProposalVariables): LazyComponentHookResult<UseDownloadProposalData> => {
  const { contentOptions } = useApp();
  const [skip, setSkip] = useState<boolean>(true);

  const loadData = () => {
    setSkip(false);
  };

  const [state, setState] = useState<LazyComponentHookResult<UseDownloadProposalData>>({
    load: loadData,
    result: { loading: false },
  });

  const {
    sfRiskSpectrumPdf: { styles },
  } = useTheme();

  const { data: clientInfo, error: clientInfoError, loading: clientInfoLoading } = useGetClientInfo({
    variables: { partyId },
    skip,
  });

  const {
    data: financialAdvisorInfo,
    error: financialAdvisorInfoError,
    loading: financialAdvisorInfoLoading,
  } = useGetClientInfo({
    variables: { partyId: partyIdFA },
    skip,
  });

  const { data: contentData, error: contentError, loading: contentLoading } = useGetDownloadableProposalContent({
    variables: contentOptions,
    skip,
  });

  const { data: feeData, error: feeDataError, loading: feeDataLoading } = useGetFeeData(
    managedProductId ?? '',
    skip || !managedProductId,
  );

  const { allocationDetailsColumns, displayAllocationBar } = useCoreConfig().components.sfDownloadProposal;

  const clientName = getClientNameToDisplay(clientInfo?.client?.party?.partyPerson, contentOptions.product) ?? '';
  const financialAdvisorParty = financialAdvisorInfo?.client?.party;

  const financialAdvisor = useMemo(() => {
    return {
      advisorName: getClientNameToDisplay(financialAdvisorParty?.partyPerson, contentOptions.product) ?? '',
      email: getEmail(financialAdvisorParty),
      phoneNumber: getPhone(financialAdvisorParty),
    };
  }, [contentOptions.product, financialAdvisorParty]);

  const content = useMemo(
    () =>
      getDownloadableProposalContent(
        clientName,
        financialAdvisor,
        contentData?.all_downloadableproposal?.items?.[0] ?? undefined,
      ),
    [contentData, clientName, financialAdvisor],
  );

  const loadingStates = contentLoading || financialAdvisorInfoLoading || clientInfoLoading || feeDataLoading;

  const error = clientInfoError || financialAdvisorInfoError || contentError || feeDataError;

  useEffect(() => {
    if (skip) {
      return;
    }

    if (loadingStates) {
      setState({
        load: loadData,
        result: {
          loading: true,
        },
      });
    } else if (error) {
      setState({
        load: loadData,
        result: {
          loading: false,
          error,
        },
      });
    } else {
      setState({
        load: loadData,
        result: {
          data: (
            getFactSheetURL: (series: string, internalId: string, page: number) => string,
            modelPortfolioData: StagedModelPortfolioData,
            partnerStyles: PdfStyle,
            portfolioDetailsData: PortfolioDetailsData,
            assetClassTier?: AssetClassTier,
          ) =>
            downloadProposal(
              allocationDetailsColumns,
              clientName,
              content,
              contentOptions,
              displayAllocationBar,
              feeData ?? [],
              getFactSheetURL,
              modelPortfolioData,
              partnerStyles,
              portfolioDetailsData,
              assetClassTier,
              styles.riskSpectrumColors,
            ),
          loading: false,
        },
      });
    }
  }, [
    allocationDetailsColumns,
    clientName,
    content,
    contentOptions,
    displayAllocationBar,
    feeData,
    error,
    loadingStates,
    styles.riskSpectrumColors,
    skip,
  ]);

  return state;
};

const downloadProposal = async (
  allocationDetailsColumns: AllocationDetailsColumns[],
  clientName: string,
  content: Content,
  contentOptions: ContentOptions,
  displayAllocationBar: boolean,
  feeData: FeeData[],
  getFactSheetURL: (series: string, internalId: string, page: number) => string,
  modelPortfolioData: StagedModelPortfolioData,
  partnerStyles: PdfStyle,
  portfolioDetailsData: PortfolioDetailsData,
  assetClassTier?: AssetClassTier,
  riskSpectrumColors?: string[],
): Promise<DownloadableProposalFileResult> => {
  if (portfolioDetailsData.data) {
    const factSheets = await getFactSheetImages(getFactSheetURL, modelPortfolioData);
    try {
      const proposal = (
        <DownloadableProposal
          allocationDetailsColumns={allocationDetailsColumns}
          assetClassTier={assetClassTier}
          content={content}
          contentOptions={contentOptions}
          displayAllocationBar={displayAllocationBar}
          factSheets={factSheets}
          feeData={feeData}
          modelPortfolioData={modelPortfolioData}
          partnerStyles={partnerStyles}
          riskBandColors={riskSpectrumColors ?? []}
          {...portfolioDetailsData.data}
        />
      );
      const pdfBlob = await pdf(proposal).toBlob();
      const filename = `${clientName}-Proposal-${format(new Date(), 'MM-dd-yyyy')}.pdf`;
      return {
        pdfBlob,
        filename,
      };
    } catch (err) {
      console.error(err);
    }
  }
  return {
    filename: '',
  };
};
