import React, { FC, lazy } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AccountDetailsTabsEnum, useModalState } from '@sigfig/digital-wealth-core';

import { useApp } from '../../contexts/App';
import { partnerStyle } from '../../theme/PdfStyle';
import { useAccountActions } from '../../utils/account';
import { AccountActionRoutes } from '..';

const AccountDetails = lazy(() => import('../../containers/AccountDetails'));

interface AccountDetailsProps extends RouteComponentProps<{ managedProductId: string; partyId: string }> {
  partyIdFA?: string;
}

export const AccountDetailsWrapper: FC<AccountDetailsProps> = ({
  history,
  match: {
    params: { managedProductId, partyId },
  },
}) => {
  const { contentOptions } = useApp();

  const { open, openModal: openAccountSetupModal, onClose } = useModalState();
  return (
    <AccountDetails
      actions={useAccountActions(AccountActionRoutes.ACCOUNT_DETAILS, { openModal: openAccountSetupModal })}
      contentOptions={contentOptions}
      initialState={{ initialTab: AccountDetailsTabsEnum.overview }}
      managedProductId={managedProductId ?? ''}
      onRetakeQuestionnaire={manageProductId =>
        history.push(`/rce/${partyId}/questionnaire/${manageProductId}?source=${AccountActionRoutes.ACCOUNT_DETAILS}`)
      }
      onViewPerformanceReport={() => false}
      partyId={partyId}
    />
  );
};
