import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useSymphonyQuery } from '@sigfig/digital-wealth-core';

import {
  GetClientExternalIdentifiers,
  GetClientExternalIdentifiersVariables,
} from './__generated__/GetClientExternalIdentifiers';
const queries = loader('./query.gql');

export const useGetClientExternalIdentifiers = (
  options?: QueryHookOptions<GetClientExternalIdentifiers, GetClientExternalIdentifiersVariables>,
): QueryResult<GetClientExternalIdentifiers, GetClientExternalIdentifiersVariables> => {
  return useSymphonyQuery(queries, options);
};
