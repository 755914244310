/* eslint-disable @typescript-eslint/no-shadow */
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import queryString from 'query-string';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';

import {
  ApolloProvider,
  Box,
  cacheTypePolicies as symphonyCacheTypePolicies,
  CircularProgress,
  ClientEmailReviewAction,
  Container,
  CoreConfigProvider,
  createTheme,
  DisclosurePage,
  DocusignDAPro,
  DocusignDAProCompleted,
  from,
  getApolloClient,
  getContentstackHttpLinkConfig,
  Grid,
  HttpLink,
  InMemoryCache,
  LegalDocumentStatus,
  Locale,
  Partner,
  Product,
  RetakeRTQCompleted,
  setContext,
  sfPosthog,
  ThemeProvider,
  usePostHog,
} from '@sigfig/digital-wealth-core';

import { AnalyticsWindow } from '../analytics';
import { KeepAlive } from '../auth/KeepAlive';
import { Header } from '../components/Header';
import config, { defaultPartnerConfigs } from '../config';
import { AppProvider } from '../contexts/App';
import { defaultTheme } from '../theme';

import { AccountDetailsWrapper } from './AccountDetails';
import AccountPerformanceWrapper from './AccountPerformance';
import { AccountSummaryWrapper } from './AccountSummary';
import { FADashboardWrapper } from './FADashboard';
import { HomeOfficeDashboardWrapper } from './HomeOfficeDashboard';
import { OnboardingWrapper } from './OnboardingWrapper';
import { RetakeRTQWrapper } from './RetakeRTQWrapper';
import { WidgetsWrapper } from './WidgetsWrapper';
export enum AccountActionRoutes {
  ACCOUNT_DETAILS = 'AccountDetails',
  ACCOUNT_SUMMARY = 'AccountSummary',
  WIDGETS = 'Widgets',
}

export enum DocusignUserType {
  Client = 'Client',
  FA = 'FA',
}

const Dashboard = lazy(() => import('../containers/Dashboard'));
const Disclosure = lazy(() => import('../components/Disclosure'));

export const getBaseUrl = (location: Location = window.location): string => location.origin;
export const isInIframe = (): boolean => window.self !== window.top;

const ProtectedRoute = lazy(() => import('../auth').then(({ ProtectedRoute }) => ({ default: ProtectedRoute })));
const ProtectedCompatRoute = lazy(() =>
  import('../auth').then(({ ProtectedCompatRoute }) => ({ default: ProtectedCompatRoute })),
);

declare let window: AnalyticsWindow;

function App(): unknown {
  const { isAuthenticated, isLoading, error, user, logout } = useAuth0();
  const [jwt, setJwt] = useState('');
  const [faPartyId, setFaPartyId] = useState('');
  const posthog = usePostHog();
  sfPosthog.useCaptureSpaPageViews(useLocation());

  useEffect(() => {
    if (user) {
      setJwt(user['https://fc.sigfig.com:frontEndJwt']); // TODO: Get namespace (fc.sigfig.com) from a config https://jira.sigfig.com/browse/DA2-818
    }
  }, [user]);

  useEffect(() => {
    const advisorPartyId = user?.['https://fc.sigfig.com:partyId'];
    if (advisorPartyId && faPartyId !== advisorPartyId) {
      setFaPartyId(advisorPartyId);
    }
    posthog?.identify(advisorPartyId);
  }, [user, faPartyId, posthog]);

  // To pass the jwt token to axios, needed while downloading client documents
  useEffect(() => {
    axios.defaults.headers.common.Authorization = jwt ? `Bearer ${jwt}` : '';
  }, [jwt]);

  if (isLoading || (isAuthenticated && jwt === '')) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error.message}</div>; // TODO: Perhaps the error should be thrown since auth would have failed.
  }

  const symphonyAuthLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    };
  });

  const errorLink = onError(({ networkError }) => {
    if (
      (networkError as any)?.result?.status === 403 &&
      (networkError as any)?.result?.code === 'AUTHENTICATION_INVALID_UNAUTHORIZED'
    ) {
      posthog?.reset();
      logout({ returnTo: config.auth0.logoutUrl });
    }
  });

  // TODO: Fix apollo client parameters
  // https://jira.sigfig.com/browse/DA2-696
  const getSymphonyHttpLinkConfig = (uri: string) => ({
    name: 'symphony',
    link: from([
      errorLink,
      symphonyAuthLink.concat(
        new HttpLink({
          uri,
          fetch,
          headers:
            process.env.NODE_ENV === 'development'
              ? {
                  connection: 'keep-alive',
                }
              : undefined,
        }),
      ),
    ]),
  });
  const symphonyUrl = process.env.NODE_ENV === 'development' ? '/ui/graphql' : '/symphony/ui/graphql';

  const client = getApolloClient({
    links: [
      getContentstackHttpLinkConfig(
        config.contentstack.environment as string,
        config.contentstack.deliveryToken as string,
      ),
      getSymphonyHttpLinkConfig(symphonyUrl),
    ],
    cache: new InMemoryCache({
      typePolicies: {
        ...symphonyCacheTypePolicies,
      },
    }),
    connectToDevTools: process.env.NODE_ENV === 'development',
  });

  // TODO: This can be removed once all the page level containers have been migrated to the partner repo.
  const props = {
    contentOptions: {
      locale: Locale.en_us,
      partner: Partner.Citizens,
      product: Product.DigitalWealthPro,
    },
  };
  const docusignBaseUrl = `${getBaseUrl()}/am/master`;
  const getDocusignReturnToUrl = (partyId: string) => `${getBaseUrl()}/docusignComplete/${partyId}`;

  const FADashboardComponent = ({ history }: RouteComponentProps) => {
    const showSearchComponent = window.location.href.includes('fa-dashboard/search');
    const { redirectTo } = queryString.parse(window.location.search);
    return (
      <>
        <Header
          {...props}
          history={history}
          onBack={() => history.replace(`/fa-dashboard/${faPartyId}?tab=${redirectTo ?? ''}`)}
          partyIdFA={faPartyId}
          showBackToViewClientList={showSearchComponent}
          showSearchButton
        />
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FADashboardWrapper history={history} partyIdFA={faPartyId} showSearchComponent={showSearchComponent} />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };

  const DashboardComponent = (routeComponentProps: RouteComponentProps<{ partyId: string }>) => {
    const {
      match: {
        params: { partyId },
      },
    } = routeComponentProps;
    return (
      <Dashboard
        {...props}
        {...routeComponentProps}
        accountSummaryComponent={
          <AccountSummaryWrapper {...routeComponentProps} partyId={partyId} partyIdFA={faPartyId} />
        }
      />
    );
  };

  const DisclosureComponent = () => {
    let page: DisclosurePage;
    const { pathname } = useLocation();

    if (pathname.includes('/details')) {
      page = 'RCE';
    } else {
      return null;
    }

    return (
      <Grid container justifyContent="center" sx={{ backgroundColor: 'background.default', mt: 7, mb: 2 }}>
        <Grid item xs={10}>
          <Disclosure {...props} page={page} />
        </Grid>
      </Grid>
    );
  };

  const DocusignComponent = ({
    history: routerHistory,
    match: {
      params: { partyId, managedProductId },
    },
  }: RouteComponentProps<{ managedProductId: string; partyId: string }>) => {
    window.onpopstate = function () {
      // prevent user from navigating away from this page using the back button
      // FA must use CTAs on the page to prevent data caching issues: https://sigfig.atlassian.net/browse/DA2-5657
      history.go(0);
    };
    return (
      <>
        <Header
          {...props}
          onBack={() => routerHistory.push(`/account-summary/${partyId}`)}
          partyIdClient={partyId}
          showSaveAndExit
          showSubHeader
        />
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DocusignDAPro
                {...props}
                baseUrl={docusignBaseUrl}
                managedProductId={managedProductId}
                onClientDecline={() =>
                  routerHistory.push(
                    `/docusignComplete/${partyId}?status=${LegalDocumentStatus.DECLINED}&userType=${DocusignUserType.FA}&declinedBy=${DocusignUserType.Client}`,
                  )
                }
                onFADecline={() =>
                  routerHistory.push(
                    `/docusignComplete/${partyId}?status=${LegalDocumentStatus.DECLINED}&userType=${DocusignUserType.FA}&declinedBy=${DocusignUserType.FA}`,
                  )
                }
                onFASign={() =>
                  routerHistory.push(
                    `/docusignComplete/${partyId}?status=${LegalDocumentStatus.SUCCEEDED}&userType=${DocusignUserType.FA}`,
                  )
                }
                onIframeReady={() => {
                  // post a message every minute for 20 mins so that keep alive call is made periodically
                  // users usually take longer to read documents, so this is needed when Docusign iFrame is shown
                  const iFrameMessageInterval = setInterval(() => {
                    window.postMessage(`iFrameMessage`, getBaseUrl());
                  }, 60000);
                  setTimeout(() => clearInterval(iFrameMessageInterval), 1200000);
                }}
                onSaveExit={() => routerHistory.push(`/account-summary/${partyId}`)}
                partyId={partyId}
                partyIdFA={faPartyId}
                returnToUrl={getDocusignReturnToUrl(partyId)}
              />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };

  const ClientEmailAnnualReviewAction = ({
    match: {
      params: { partyId },
    },
  }: RouteComponentProps<{ partyId: string }>) => {
    const { action, hmac } = queryString.parse(location.search);

    // Ensures only 'completed' or 'requested' account types are processed, with no extra strings appended.
    const actionType = (Array.isArray(action) ? action[0] : action)?.split('?')[0] || '';

    if (!partyId || !hmac || !actionType || Array.isArray(hmac) || Array.isArray(actionType)) {
      return <></>;
    } else {
      return (
        <>
          <Header {...props} userType="Client" />
          <ClientEmailReviewAction
            {...props}
            clientReviewInvitationURI={
              process.env.NODE_ENV === 'development' ? '/clientreviewinvitations' : '/symphony/clientreviewinvitations'
            }
            dataQa="clientEmailReviewAction"
            emailReviewStatus={actionType === 'completed' ? 'completed' : 'requested'}
            hmac={hmac}
            partner="Citizens"
          />
          <DisclosureComponent />
        </>
      );
    }
  };

  const DocusignCompletedComponent = ({
    history,
    location,
    match: {
      params: { partyId },
    },
  }: RouteComponentProps<{ partyId: string }>) => {
    if (isInIframe() && window.top) {
      window.top.location.href = window.location.href;
      return null;
    }

    const { userType, status, declinedBy } = queryString.parse(location.search);

    return (
      <>
        <Header {...props} userType={userType?.toString()} />
        <DocusignDAProCompleted
          {...props}
          declinedBy={(declinedBy ?? '') as DocusignUserType}
          needsPrincipalApprovalPostFASigning
          onFASignedPrimaryCtaCallback={faPartyId ? () => history.push(`/account-summary/${partyId}`) : undefined}
          onFASignedSecondaryCtaCallback={faPartyId ? () => history.push(`/onboarding/${partyId}`) : undefined}
          partyId={partyId}
          showCtas={!!faPartyId && userType?.toString().toUpperCase() === DocusignUserType.FA.toString().toUpperCase()}
          signingStatus={(status ?? '') as LegalDocumentStatus}
          userType={(userType ?? '') as DocusignUserType}
        />
        <DisclosureComponent />
      </>
    );
  };

  const RetakeRTQDocusignCompletedComponent = ({
    history,
    location,
    match: {
      params: { partyId, managedProductId },
    },
  }: RouteComponentProps<{ managedProductId: string; partyId: string }>) => {
    const { userType, status } = queryString.parse(location.search);

    // Send only logged in FA users to protected route
    if (faPartyId && userType?.toString().toUpperCase() === DocusignUserType.FA.toString().toUpperCase()) {
      history.push(`/rce/${partyId}/questionnaire/${managedProductId}${location.search}`);
      return null;
    }

    return (
      <>
        <Header {...props} userType={userType?.toString()} />
        <RetakeRTQCompleted
          {...props}
          isClientDocusign={userType?.toString().toUpperCase() === DocusignUserType.Client.toString().toUpperCase()}
          isDocusignFlow
          isPortfolioUpdated
          isUserLoggedIn={false}
          signingStatus={(status ?? '') as LegalDocumentStatus}
        />
        <DisclosureComponent />
      </>
    );
  };

  const DigitalWealthWidgets = (routeComponentProps: RouteComponentProps<{ partyId: string }>) => (
    <WidgetsWrapper {...routeComponentProps} />
  );

  const HomeOfficeDashboard = (
    routeComponentProps: RouteComponentProps<{ managedProductId: string; partyId: string }>,
  ) => {
    const { history } = routeComponentProps;
    return (
      <>
        <Header {...props} onBack={() => history.replace(`/fa-dashboard/${faPartyId}`)} />
        <HomeOfficeDashboardWrapper {...routeComponentProps} partyIdFA={faPartyId} />
        <DisclosureComponent />
      </>
    );
  };

  const AccountPerformanceComponent = (
    routeComponentProps: RouteComponentProps<{ managedProductId: string; partyId: string }>,
  ) => {
    const {
      history,
      match: {
        params: { partyId, managedProductId },
      },
    } = routeComponentProps;
    return (
      <>
        <Header
          {...props}
          onBack={() => history.replace(`/account-summary/${partyId}/details/${managedProductId}`)}
          showBackToAccountDetails
        />
        <AccountPerformanceWrapper {...routeComponentProps} partyIdFA={faPartyId} />
        <DisclosureComponent />
      </>
    );
  };

  const ManagedAccounts = ({ match: { path } }: RouteComponentProps<{ partyId: string }>) => {
    return (
      <Switch>
        <ProtectedRoute
          component={(routeComponentProps: RouteComponentProps<{ managedProductId: string; partyId: string }>) => (
            <AccountDetailsWrapper {...routeComponentProps} partyIdFA={faPartyId} />
          )}
          path={`${path}/details/:managedProductId`}
        />
        <ProtectedRoute component={DashboardComponent} path={`${path}`} />
      </Switch>
    );
  };

  const DigitalWealth = ({
    history,
    match: {
      params: { partyId },
    },
  }: RouteComponentProps<{ managedProductId: string; partyId: string }>) => {
    const showBackToAccountSummary = window.location.href.includes('/details');
    const showSaveAndExit = window.location.href.includes('/onboarding');
    const showViewClientList = !window.location.href.includes('/onboarding');
    return (
      <>
        <Header
          {...props}
          onBack={() => history.push(`/account-summary/${partyId}`)}
          partyIdClient={partyId}
          partyIdFA={faPartyId}
          showBackToAccountSummary={showBackToAccountSummary}
          showSaveAndExit={showSaveAndExit}
          showSubHeader={!window.location.href.includes('account-summary')}
          showViewClientList={showViewClientList}
        />
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Switch>
                <ProtectedRoute component={ManagedAccounts} path="/account-summary/:partyId" />
                <ProtectedCompatRoute
                  component={(routeComponentProps: RouteComponentProps<{ partyId: string }>) => (
                    <OnboardingWrapper {...routeComponentProps} partyIdFA={faPartyId} />
                  )}
                  path="/onboarding/:partyId/:onboardingStage?"
                />
                <ProtectedCompatRoute
                  component={(routeComponentProps: RouteComponentProps<{ partyId: string }>) => (
                    <OnboardingWrapper {...routeComponentProps} partyIdFA={faPartyId} />
                  )}
                  path="/onboarding-dev/:partyId/:onboardingStage?"
                />
              </Switch>
            </Grid>
          </Grid>
        </Container>
        <DisclosureComponent />
      </>
    );
  };

  return (
    <AppProvider>
      <ThemeProvider theme={createTheme(defaultTheme)}>
        <CoreConfigProvider value={defaultPartnerConfigs}>
          <ApolloProvider client={client}>
            <KeepAlive {...props}>
              <Suspense
                fallback={
                  <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress disableShrink />
                  </Box>
                }
              >
                <Switch>
                  <ProtectedRoute
                    component={AccountPerformanceComponent}
                    path="/account-summary/:partyId/performance/:managedProductId"
                  />
                  <ProtectedRoute component={FADashboardComponent} path="/fa-dashboard/:partyId" />
                  <ProtectedRoute component={FADashboardComponent} path="/fa-dashboard/search" />
                  <ProtectedRoute component={DigitalWealth} path="/account-summary/:partyId" />
                  <ProtectedRoute component={DigitalWealth} path="/onboarding/:partyId" />
                  <ProtectedRoute component={DigitalWealth} path="/onboarding-dev/:partyId" />
                  <ProtectedRoute component={DocusignComponent} path="/docusign/:partyId/:managedProductId" />
                  <ProtectedRoute component={DigitalWealthWidgets} path="/widgets/:partyId" />
                  <ProtectedRoute component={DigitalWealthWidgets} path="/widgets" />
                  <ProtectedRoute
                    component={(
                      routeComponentProps: RouteComponentProps<{ managedProductId: string; partyId: string }>,
                    ) => <RetakeRTQWrapper {...routeComponentProps} faPartyId={faPartyId} />}
                    path="/rce/:partyId/questionnaire/:managedProductId"
                  />
                  <ProtectedRoute component={HomeOfficeDashboard} path="/home-office" />
                  <Route component={ClientEmailAnnualReviewAction} path="/annualReviewAction/:partyId" />
                  {/* This route is unauthenticated for Docusign to be able to redirect back without any issues */}
                  {/* Since this component doesn't contain any PII or user specific details, there are no issues */}
                  <Route
                    component={RetakeRTQDocusignCompletedComponent}
                    path="/docusignComplete/:partyId/questionnaire/:managedProductId"
                  />
                  <Route component={DocusignCompletedComponent} path="/docusignComplete/:partyId" />
                  {/* TODO: Add page not found component */}
                  <ProtectedRoute path="/" />
                </Switch>
              </Suspense>
            </KeepAlive>
          </ApolloProvider>
        </CoreConfigProvider>
      </ThemeProvider>
    </AppProvider>
  );
}
export default App;
