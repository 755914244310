import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import {
  GetPortfolioSelectionContent,
  GetPortfolioSelectionContentVariables,
} from './__generated__/GetPortfolioSelectionContent';

const portfolioSelectionQuery = loader('./GetPortfolioSelectionContent.gql');
export const useGetPortfolioSelectionContent = (
  options?: QueryHookOptions<GetPortfolioSelectionContent, GetPortfolioSelectionContentVariables>,
): QueryResult<GetPortfolioSelectionContent, GetPortfolioSelectionContentVariables> =>
  useContentstackQuery(portfolioSelectionQuery, options);
