import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { PdfComponentStyle as Style, PdfDivider as Divider } from '@sigfig/digital-wealth-core';

import { FeeData } from '../hooks/useGetFeeData';

export interface FeeContent {
  balanceRangeLabel: string;
  disclaimer: string;
  feeLabel: string;
  sectionTitle: string;
  title: string;
}

interface Props {
  content: FeeContent;
  data: FeeData[];
  pdfStyles: Style;
}

export const Fees: React.FC<Props> = ({ content, data, pdfStyles }) => {
  const commonTextStyle = {
    color: pdfStyles.textPrimary.color,
    fontSize: 10,
    marginTop: '8px',
    marginBottom: '8px',
  };

  const feeTextStyle = {
    ...commonTextStyle,
    fontSize: 9,
  };

  return (
    <>
      <Text
        style={{
          color: pdfStyles.primaryMainColor.color,
          fontSize: 16,
          marginBottom: '12px',
        }}
      >
        {content.sectionTitle}
      </Text>
      <View style={{ ...pdfStyles.border, width: '50%', padding: '8px 16px', marginTop: '12px', marginBottom: '48px' }}>
        <Text style={{ ...commonTextStyle, textAlign: 'center' }}>{content.title}</Text>
        <Divider pdfStyles={pdfStyles} />
        <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Text style={feeTextStyle}>{content.balanceRangeLabel}</Text>
          <Text style={feeTextStyle}>{content.feeLabel}</Text>
        </View>
        <Divider pdfStyles={pdfStyles} style={{ marginBottom: '2px' }} />
        <Divider pdfStyles={pdfStyles} />
        {data.map(item => (
          <View key={item.rate} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            {item.upperBound ? (
              <Text style={feeTextStyle}>{`${item.lowerBound} - ${item.upperBound}`}</Text>
            ) : (
              <Text style={feeTextStyle}>{`Above ${item.lowerBound}`}</Text>
            )}
            <Text style={feeTextStyle}>{`${item.rate}`}</Text>
          </View>
        ))}
        <Divider pdfStyles={pdfStyles} />
        <Text style={{ ...commonTextStyle, fontSize: 7 }}>{content.disclaimer}</Text>
      </View>
    </>
  );
};
