import { Page } from '@react-pdf/renderer';
import React from 'react';

import {
  AllocationDetailsColumns,
  AssetClassTier,
  ContentOptions,
  PdfAllocationDetails as AllocationDetails,
  PdfAllocationDetailsContent as AllocationDetailsContent,
  PdfAllocationDetailsData as AllocationDetailsData,
  PdfComponentStyle as Style,
  PdfFooter as Footer,
  PdfHeader as Header,
} from '@sigfig/digital-wealth-core';

import { FooterGraphics } from '../../components/FooterGraphics';

export interface DetailedInvestmentBreakdownContent {
  allocationDetails: AllocationDetailsContent;
  compositeModelPortfolioName: string;
  footerLogo: string;
  footerText: string;
  logo?: string;
  pageName: string;
}

interface Props {
  allocationDetailsColumns: AllocationDetailsColumns[];
  allocationDetailsData: AllocationDetailsData;
  assetClassTier?: AssetClassTier;
  content: DetailedInvestmentBreakdownContent;
  contentOptions: ContentOptions;
  displayAllocationBar: boolean;
  pdfStyles: Style;
}

export const DetailedInvestmentBreakdown: React.FC<Props> = ({
  allocationDetailsColumns,
  allocationDetailsData,
  assetClassTier,
  content,
  contentOptions,
  displayAllocationBar,
  pdfStyles,
}) => {
  return (
    <>
      <Page size="LETTER" wrap={false}>
        <Header logoUrl={content.logo} pageName={content.pageName} pdfStyles={pdfStyles} />
        <AllocationDetails
          allocation={allocationDetailsData.assetAllocation}
          assetClassTier={assetClassTier}
          centerSubText={allocationDetailsData.centerSubText}
          centerText={allocationDetailsData.centerText}
          columns={allocationDetailsColumns}
          content={content.allocationDetails}
          contentOptions={contentOptions}
          displayAllocationBar={displayAllocationBar}
          modelPortfolioName={allocationDetailsData.modelPortfolioName}
          paddingTop="48px"
          pdfStyles={pdfStyles}
        />
        <Footer pdfStyles={pdfStyles} text={content.footerText} />
        <FooterGraphics logo={content.footerLogo} pdfStyles={pdfStyles} />
      </Page>
    </>
  );
};
